import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import CheckRequest from './components/CheckRequest/CheckRequest';
import { Home } from './components/Home';
import { Help } from './components/Help/Help';

import './custom.css';

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/trip/:id?' component={Home} />
            <Route path='/help' component={Help} />
            <Route exact path='/checkrequest' component={CheckRequest} />
        </Switch>
    </Layout>
);
