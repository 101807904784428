import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import * as React from 'react';
import { ITrip } from '../../index';

interface ITripSelectState {
    selectedTripId?: number;
    message: string;
}

interface ITripSelectProps extends WithStyles<typeof styles> {
    trips: ITrip[];
    selectedTrip: ITrip;
    getTrip: (e: any) => void;
    isAuditorView: boolean;
    isManagerView: boolean;
}

class TripSelect extends React.Component<ITripSelectProps, ITripSelectState>{

    constructor(props?: (ITripSelectProps)) {
        super(props as any);

        this.state = {
            selectedTripId: 0,
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.setState({
            selectedTripId: 0
        });
    }
    handleChange(event: any) {

        let id = event.target.value;

        if (event.target.value > 0) {
            this.setState({ selectedTripId: id });
            this.props.getTrip(id);
        }
    }

    render() {

        const { classes } = this.props as any;

        const menuItemTripList = this.props.trips.map((trip: ITrip) => {
            return <MenuItem value={trip.id} key={trip.name}>

                <Typography variant='subtitle1' noWrap style={{ 'display': trip.approvalStatusName !== 'Processed' ? 'inherit' : 'none' }}>
                    <span style={{ 'color': 'black' }}>
                        {trip.name}  <span className={classes.approvalStatus}> {trip.approvalStatusName}</span>
                    </span>
                </Typography>

                <Typography variant='subtitle1' noWrap style={{ 'display': trip.approvalStatusName === 'Processed' ? 'inherit' : 'none' }}>
                    <span style={{ 'color': 'grey' }}>
                        {trip.name}  <span className={classes.approvalStatus}> {trip.approvalStatusName}</span>
                    </span>
                    <Typography variant='caption' noWrap style={{
                        'display': 'inline-block', 'marginLeft': '5px', 'padding': '5px',
                    }}>
                        <MonetizationOn className={classes.monetization} />
                    </Typography>
                </Typography>

                <Typography variant='caption'>&nbsp;&nbsp;({trip.employeeName})</Typography>
            </MenuItem>;
        });

        let message = 'Your Trips:';
        if (this.props.isManagerView) { message = 'Trips needing your approval:' };
        if (this.props.isAuditorView) { message = 'Trips needing your Auditor approval:' };
        let value = this.props.selectedTrip && this.props.selectedTrip.id ? this.props.selectedTrip.id : 0;
        return <div>
            <Grid container justifyContent='center'>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <FormControl className={classes.formControl}>
                            <InputLabel className={classes.formControl} shrink={true}>{message}</InputLabel>
                            <Select className={classes.select}
                                value={value}
                                onChange={this.handleChange}>
                                {menuItemTripList}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>

            </Grid>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    monetization: {
        color: 'green',
        fontSize: '16px'
    },
    approvalStatus: {
        'fontSize': '12px',
        'marginLeft': '5px',
        color: 'grey'
    },
    formControl: {
        width: '100%',
        padding: 20
    },
    select: {
        width: '100%'
    },
    media: {
        height: 0,
        minHeight: 200,
        paddingTop: '36.25%', // 16:9
    },
});

export default withStyles(styles)(TripSelect as any);