import * as React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  
    palette: {
        primary: {
            main: '#006fbb'    
        },
        // type: 'dark', // Switching the dark mode on is a single property value change.
    },
});

function withRoot(Component: React.ComponentType) {
    function WithRoot(props: object) {
        // MuiThemeProvider makes the theme available downthe React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />

                {/* End footer */}
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
