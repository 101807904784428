import * as React from 'react';
import Typography from '@material-ui/core/Typography';

interface IDailySummaryHeaderProps {
    expensedDate: string;
    total: number;
}

const dailySummaryHeader = (props: IDailySummaryHeaderProps) => {

    return <div>
        <Typography variant="body2" style={{ "color": "#fff" }} gutterBottom>{props.expensedDate}</Typography>  
    </div>;
}

export default dailySummaryHeader;
