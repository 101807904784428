import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../../store/index';
import * as TripStore from '../../store/trips/tripReducer';
import * as SummaryStore from '../../store/summary/summaryReducer';
import * as PdfStore from '../../store/pdfs/pdfReducer';
import { ITripState } from '../../store/trips/tripState';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { Api } from '../../api/Api';
import { IReceiptMetadata } from '../index';
import CategorySummaryHeader from '../Summary/Category/CategorySummaryHeader';
import CategorySummaryTable from '../Summary/Category/CategorySummaryTable';
import DailySummaryHeader from '../Summary/Daily/DailySummaryHeader';
import DailySummaryTable from '../Summary/Daily/DailySummaryTable';
import moment from 'moment';
import { BLOB_URL } from '../../constants/constants';

const api = new Api();

interface ISummaryTabLocalProps {
    calculateForex: any;
    receiptMetadata: IReceiptMetadata[];
}
type ISummaryTabProps =
    SummaryStore.ISummaryState
    & PdfStore.IPdfState
    & ITripState
& typeof PdfStore.actionCreators
    & typeof SummaryStore.actionCreators
    & ISummaryTabLocalProps
    & typeof TripStore.actionCreators;

export class SummaryTab extends React.Component<ISummaryTabProps, {}> {

    constructor(props: ISummaryTabProps) {
        super(props);

        this.handlePDFReportPreview = this.handlePDFReportPreview.bind(this);
    }

    async handlePDFReportPreview() {

        let key = `${encodeURIComponent(this.props.selectedTrip.userEmail)}/${encodeURIComponent(this.props.selectedTrip.name)}_${encodeURIComponent(this.props.selectedTrip.id)}/report.pdf`;
      
        // Open a blank window first
        const newWindow = window.open('', '_blank');

        await api.getBlobSasToken(key).then((sasToken) => {
            const sasTokenLink = key + sasToken;
            const url = `${BLOB_URL}/${sasTokenLink}`;

            if (newWindow) {
                newWindow.location.href = url;
            }
        });
    }

    render() {

        return <div style={{ 'textAlign': 'center', 'margin': '20px' }}>

            <Grid container justifyContent='center' style={{ 'display': this.props.selectedTrip.receiptMetadata.length > 0 ? 'block' : 'none' }}>
                <Grid item xs={12}>

                    <div>
                        <Typography variant='h6'>Expense Overview
                        </Typography>
                        <Typography variant='subtitle1' color='inherit' style={{ marginBottom: '7px' }}>
                            Preview your expenses in PDF format. <br/> This will open a new window. 
                        </Typography>
                       
                        <Button disabled={this.props.pdfDirty} variant='outlined' onClick={this.handlePDFReportPreview}>
                            {this.props.pdfDirty ? 'Preview being created...' : 'View PDF'}
                        </Button>

                    </div>

                    {/* Daily Totals */}
                    <div style={{ 'marginTop': '80px' }}>

                        <Typography variant='h6' color='inherit'>Expenses by Day</Typography>
                        <Typography variant='subtitle1' color='inherit'>
                            The following are your expenses by day. Expand the tab under each header to see individual categories.
                        </Typography>
                        <br />
                        {this.props.selectedTripDailyTotalsSummary.map((summary: any, $index) =>
                            <div key={$index}>
                                <Accordion>
                                    <AccordionSummary style={{ 'backgroundColor': '#006fbb', 'color': '#fff' }} expandIcon={<ExpandMoreIcon />}>
                                        <DailySummaryHeader expensedDate={moment(summary.expensedDate).format('dddd, MMMM Do YYYY')} total={summary.total} />
                                    </AccordionSummary>
                                    <Grid container justifyContent='center'>
                                        <AccordionActions>
                                            <DailySummaryTable summary={summary} calculateForex={this.props.calculateForex} loadingImageKey={this.props.loadingImageKey} isThumbnailLoadComplete={this.props.isThumbnailLoadComplete} />
                                        </AccordionActions>
                                    </Grid>
                                </Accordion>
                            </div>
                        )}
                    </div>

                    {/* Total Expenses by Category */}
                    <div style={{ 'marginTop': '80px' }}>
                        <Typography variant='h6' color='inherit'>Expenses by Category</Typography>
                        <Typography variant='subtitle1' color='inherit'>
                            The following are your expenses by category. Expand the tab under each header to see individual expenses.
                        </Typography>
                        <br />
                        {this.props.selectedTripSummary.map((summary: any, $index) =>
                            <div key={$index}>
                                <Accordion>
                                    <AccordionSummary style={{ 'backgroundColor': '#006fbb', 'color': '#fff' }} expandIcon={<ExpandMoreIcon />} >
                                        <CategorySummaryHeader count={summary.dailyAmounts?.length} materialIconName={summary.materialIconName} categoryName={summary.categoryName} total={summary.expensedTotal} />
                                    </AccordionSummary>
                                    <Grid container justifyContent='center'>
                                        <AccordionActions>
                                            <CategorySummaryTable summary={summary} calculateForex={this.props.calculateForex} loadingImageKey={this.props.loadingImageKey} isThumbnailLoadComplete={this.props.isThumbnailLoadComplete} />
                                        </AccordionActions>
                                    </Grid>
                                </Accordion>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    appBar: {
        backgroundColor: theme.palette.primary.main
    },
    root: {
        fontSize: 10,
        width: '100%'
    },
    tabs: {
        flexGrow: 1,
        background: theme.palette.primary.main,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white'
    },
    pad: {
        paddingTop: '50px'
    },
    rightToolbar: {
        float: 'right',
        color: '#fff',
        marginLeft: '100px'
    },
    success: {
        background: 'green'
    },

});

export default compose(connect(
    (state: ApplicationState) => state.summary,
    SummaryStore.actionCreators
), connect(
    (state: ApplicationState) => state.pdf,
    PdfStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(SummaryTab) as any;