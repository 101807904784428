import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { ApplicationState } from '../../store/index';
import * as StatusTabStore from '../../store/status/statusReducer';
import * as TripStore from '../../store/trips/tripReducer';
import * as AuthStore from '../../store/auth/authReducer';
import * as MileageRateStore from '../../store/mileageRate/mileageRateReducer';
import ESignatureText from './PolicyInfo/ESignatureText';
import MileageRateText from './PolicyInfo/MileageRateText';
import { ITripState } from '../../store/trips/tripState';
import UserAutocomplete from '../UserAutocomplete';
import { filterUsers } from '../../store/utils/userUtils';

interface IStatusTabLocalProps {
}

type IStatusTabProps = ITripState
    & StatusTabStore.IStatusTabState
    & MileageRateStore.IMileageRateState
    & AuthStore.IAuthState
    & ITripState & RouteComponentProps<{}>
    & IStatusTabLocalProps
    & typeof TripStore.actionCreators
    & typeof AuthStore.actionCreators
    & typeof MileageRateStore.actionCreators
    & typeof StatusTabStore.actionCreators;

class StatusTab extends React.Component<IStatusTabProps, {}> {

    constructor(props: IStatusTabProps) {
        super(props);

        this.isDraft = this.isDraft.bind(this);
        this.isSubmitted = this.isSubmitted.bind(this);
        this.isApproved = this.isApproved.bind(this);
        this.submitForApproval = this.submitForApproval.bind(this);
        this.recallReport = this.recallReport.bind(this);
        this.reject = this.reject.bind(this);
    }

    componentDidMount() {
        // hide the IOS keyboard.
        document.addEventListener('keyup', this.handleKeyUp);

        // trigger the default manager primary to load.    
        if (this.props.currentUser) {
            this.props.handlePrimaryChange({ value: this.props.currentUser.primaryManagerEmail });
        }
    }
    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            // Find the focused input element and blur it
            const focusedInput = document.activeElement;
            if (focusedInput instanceof HTMLInputElement) {
                focusedInput.blur();
            }
        }
    };
    componentWillUnmount() {
        // Remove the event listener when the component unmounts to avoid memory leaks
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    submitForApproval() {
        this.props.submitForApproval();
    }
    recallReport() {

        let tripId = this.props.selectedTrip.id;
        let userId = this.props.currentUser.id;

        this.props.recallReport({ tripId: tripId, userId: userId });
    }
    isDraft(): boolean {
        return this.props.selectedTrip.approvalStatusName === 'Draft';
    }
    isSubmitted(): boolean {
        return this.props.selectedTrip.approvalStatusName === 'Submitted';
    }
    isApproved(): boolean {
        return this.props.selectedTrip.approvalStatusName === 'Approved';
    }

    reject(e) {
        if (this.props.isAuditorView) {
            this.props.auditorReject(e);
        } else {
            this.props.managerReject(e);
        }
    }

    render() {

        const { classes } = this.props as any;

        let { selectedTrip } = this.props;

        const loadUsers = (inputValue, callback) => {
            this.props.loadUserList(inputValue, null);
            callback(filterUsers(this.props.users, inputValue));
        };

        return this.props.currentUser ? <div>
            <div style={{ 'textAlign': 'center', 'margin': '20px' }}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>

                        <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom>
                            This Expense Report is in the following state:
                        </Typography>
                        <Typography style={{ 'visibility': this.props.submitting ? 'visible' : 'hidden' }} variant='h4' align='center' color='primary' gutterBottom>
                            <div style={{ 'textAlign': 'center' }}>
                                <CircularProgress style={{ 'display': this.props.submitting ? 'inline-block' : 'none' }} /></div>
                        </Typography>

                        <Typography style={{ 'visibility': this.props.submitting || !selectedTrip ? 'hidden' : 'visible' }} variant='h4' align='center' color='primary' gutterBottom>
                            {selectedTrip.approvalStatusName}
                        </Typography>
                        {this.isApproved() && <div>
                            <br />
                            <Typography>The Auditor will review your report soon. </Typography>
                            <Typography variant='subtitle2' align='center' color='textPrimary' gutterBottom>
                                Received on: {this.props.selectedTrip.lastUpdatedDate}
                            </Typography>
                        </div>
                        }
                    </Grid>

                    <Divider />

                    <div style={{ 'textAlign': 'center', 'marginBottom': '20px' }}>

                        {/* Auditor Approval */}
                        <Grid item xs={12} style={{ 'display': this.props.currentUser.isAuditor && this.isApproved() ? 'block' : 'none' }}>
                            <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom>
                                Please select one of the following options:
                            </Typography>

                            <Grid container spacing={2} style={{ 'marginTop': '20px' }}>
                                <Grid item xs={6}>
                                    <Button onClick={this.props.auditorApprove} color='primary' variant='contained'
                                        disabled={this.props.submitting || selectedTrip.receiptMetadata.some(x => !x.isAuditorApproved)}>
                                        {this.props.submitting ? 'Submitting...' : 'Submit for Payment'}
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button onClick={this.props.openRejectForm} color='default' variant='contained'
                                        disabled={this.props.submitting}>
                                        Reject <br />Report
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Manager Approval */}
                        <Grid item xs={12} style={{ 'marginTop': '20px', 'display': this.props.selectedTrip.managerEmailSubmittedTo && this.props.currentUser.email.toLowerCase() === this.props.selectedTrip.managerEmailSubmittedTo.toLowerCase() && this.isSubmitted() && !this.props.isEmployeeView ? 'block' : 'none' }}>
                            <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom>
                                Please select one of the following options:
                            </Typography>

                            <Grid container style={{ 'marginTop': '20px', 'textAlign': 'center' }}>
                                <Grid item xs={6}>
                                    <Button onClick={this.props.managerApprove} color='primary' variant='contained'
                                        disabled={this.props.submitting}>
                                        {this.props.submitting && this.props.isManagerApproved ? 'Approving...' : 'Approve'}
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>

                                    <Button onClick={this.props.openRejectForm} color='default' variant='contained'
                                        disabled={this.props.submitting}>
                                        Reject
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* Employee */}
                        <div>
                            <Grid item xs={12} style={{ 'display': this.isSubmitted() && !this.props.isManagerApproved && this.props.isEmployeeView ? 'block' : 'none' }}>
                                <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom>
                                    <br />
                                    You can recall your report if you need to resubmit it.
                                </Typography>
                                <br />
                                {this.props.selectedTrip.managerEmailSubmittedTo && <div>
                                    <Typography>Your report is currently being reviewed by: </Typography>
                                    <Typography variant='subtitle2' align='center' color='textPrimary' gutterBottom><br /> {this.props.selectedTrip.managerEmailSubmittedTo} <br />
                                        as of {this.props.selectedTrip.lastUpdatedDate}
                                    </Typography>
                                </div>
                                }
                                <br />
                                <Button onClick={this.recallReport} color='default' variant='contained'
                                    disabled={this.props.submitting}>
                                    {this.props.submitting ? 'Recalling...' : 'Recall This Report'}
                                </Button>
                                <br />  <br />  <br />

                            </Grid>
                            <Grid item xs={12} style={{ 'display': this.isDraft() && this.props.isEmployeeView ? 'block' : 'none' }}>
                                <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom>
                                    When you are ready to submit your report, you can notify your approver by clicking the button below.
                                </Typography>

                                <div className={classes.pad} style={{ 'textAlign': 'left' }}>
                                    <Typography variant='body1'>Manager/Approver (US Employees only):</Typography>

                                    <br />
                                    <Typography variant='body2'>{this.props.selectedTrip.managerEmailSubmittedTo || this.props.currentUser.primaryManagerEmail}</Typography>
                                    <UserAutocomplete loadUsers={loadUsers} changeUser={this.props.handlePrimaryChange} />
                                </div>

                                <br />
                                <br />
                                <br />
                                <Button onClick={this.submitForApproval} color='primary' variant='contained'
                                    disabled={this.props.submitting || !this.props.currentUser.primaryManagerEmail}>
                                    {this.props.submitting ? 'Submitting...' : 'Submit for Approval'}
                                </Button>
                                <br />  <br />  <br />
                                <ESignatureText />
                                <br />
                                <Divider />
                                <br />
                                <MileageRateText mileageRate={this.props.mileageRate} />
                            </Grid>
                        </div>
                    </div>
                </Grid>

                <Dialog fullScreen open={this.props.showRejectForm} onClose={this.props.handleCloseRejectForm}>
                    <AppBar position='static'>
                        <Toolbar>
                            <IconButton className={classes.menuButton} onClick={this.props.handleCloseRejectForm} aria-label='Menu' color='inherit'>
                                <CloseIcon />
                            </IconButton>

                            <Typography variant='h6' color='inherit' >
                                {selectedTrip.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>


                    <DialogContent>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12} md={5}>

                                { /* Reason for Rejection */}
                                <div className={classes.pad}>
                                    <TextField
                                        fullWidth
                                        id='rejectionReason'
                                        label='Reason for Rejection:'
                                        value={this.props.rejectionReason}
                                        onChange={(e: any) => this.props.handleRejectionChange(e)}
                                        margin='normal'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                { /* Save */}
                                <Grid container spacing={2} style={{ 'marginTop': '20px' }}>
                                    <Grid item style={{ 'marginTop': '20px' }}>
                                        <Button onClick={(e: any) => this.reject(e)} color='secondary' variant='contained'
                                            disabled={this.props.submitting || !this.props.rejectionReason}>
                                            {this.props.submitting && !this.props.isManagerApproved ? 'Rejecting...' : 'Reject'}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ 'marginTop': '20px' }}>
                                        <Button variant='outlined' onClick={this.props.handleCloseRejectForm}>
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>


            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.props.showStatusMessage || this.props.showStatusValidationError}
                autoHideDuration={8000}
                onClose={this.props.handleStatusMessageClose}
            >
                <SnackbarContent
                    aria-describedby='client-snackbar'
                    message={
                        <span id='client-snackbar'>
                            {this.props.statusMessage}
                        </span>
                    }
                />
            </Snackbar>
        </div> : null;
    }
}


const styles = (theme: Theme) => createStyles({
    paper: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    pad: {
        paddingTop: '50px'
    },
    textfield: {
        width: 200,
    },
});

// Wire up the React component to the Redux store
export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.status,
    StatusTabStore.actionCreators
), connect(
    (state: ApplicationState) => state.mileageRate,
    MileageRateStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(StatusTab) as any;
