import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import {
    MileageRateAction
} from './mileageRateActions';
import { Api } from '../../api/Api';
import { AppThunkAction } from '../../store/index';
import { IMileageRate, IReceiptMetadata, IPersonalCarMileage } from '../../components/index';
import { defaultPersonalCarMileage } from '../../constants/TripDefaults';
const api = new Api();

type KnownAction = MileageRateAction;

export interface IMileageRateState {
    mileageRate: IMileageRate;
    personalCarMileage: IPersonalCarMileage;
    calculatedMileageReimbursement: number;
    isPersonalCarMileageDirty: boolean;
};

export const unloadedState: IMileageRateState = {
    mileageRate: { effectiveDate: '', amount: 0 },
    personalCarMileage: { ...defaultPersonalCarMileage },
    isPersonalCarMileageDirty: false,
    calculatedMileageReimbursement: 0, 
};

export const actionCreators = {
 
    getMileageRate: (endDate: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getMileageRate(endDate).then((mileageRate: any) => {
            dispatch({ type: 'GET_MILEAGERATE_SUCCESS', mileageRate: mileageRate });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_MILEAGERATE' });
    },
    getMileageRateSuccess: () => <KnownAction>{ type: 'GET_MILEAGERATE_SUCCESS' },
    clearPersonalCarMileage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PERSONAL_CAR_MILEAGE',          
            personalCarMileage: {
                ...defaultPersonalCarMileage
            },     
            isPersonalCarMileageDirty: false,
        });
    },
    setPersonalCarMileage: (personalCarMileage: IPersonalCarMileage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PERSONAL_CAR_MILEAGE',
            personalCarMileage: personalCarMileage,
            isPersonalCarMileageDirty: false,
        });
    },
    setCalculatedMileageReimbursement: (calculatedMileageReimbursement: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CALCULATE_MILEAGEREIMBURSEMENT',
            calculatedMileageReimbursement: calculatedMileageReimbursement,         
        });
    },
    calculateMileageReimbursement: (mileage: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let amount = parseFloat((mileage * getState().mileageRate.mileageRate.amount).toFixed(2));
        dispatch({ type: 'CALCULATE_MILEAGEREIMBURSEMENT', calculatedMileageReimbursement: amount });
    },
    handlePersonalCarMileageChange: (e: any, metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (e.target.name === undefined) {
            return;
        }

        let amount = 0;

        if (e.target.name === 'destination') {

            getState().mileageRate.personalCarMileage.destination = e.target.value;

            dispatch({
                type: 'HANDLE_PERSONALCARMILEAGE_CHANGE_SUCCESS',
                showPersonalCarMileage: true,
                personalCarMileage: getState().mileageRate.personalCarMileage,
                isPersonalCarMileageDirty: true
            });
        }
        else {
            // mileage.    
            let parsedIntMileage = parseInt(e.target.value);

            if (!parsedIntMileage) {
                parsedIntMileage = 0;
            } else {
                parsedIntMileage = Math.round(parsedIntMileage);
            }

            getState().mileageRate.personalCarMileage[e.target.name] = parsedIntMileage;

            amount = parseFloat((parsedIntMileage * getState().mileageRate.mileageRate.amount).toFixed(2));

            getState().trip.selectedTrip.receiptMetadata.forEach((element: any, index: any) => {
                if (element.receiptMetadataId === metadata.receiptMetadataId) {
                    getState().trip.selectedTrip.receiptMetadata[index].expensedTotal = amount;
                }
            });

            dispatch({
                type: 'HANDLE_PERSONALCARMILEAGE_CHANGE_SUCCESS',
                showPersonalCarMileage: true,
                personalCarMileage: getState().mileageRate.personalCarMileage,
                selectedTrip: getState().trip.selectedTrip,
                calculatedMileageReimbursement: amount,
                isPersonalCarMileageDirty: true
            });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IMileageRateState> = (state: IMileageRateState, action: KnownAction) => {

    switch (action.type) {
        case 'GET_MILEAGERATE':
            return { ...state, mileageRate: state.mileageRate };
        case 'GET_MILEAGERATE_SUCCESS':
            return { ...state, mileageRate: action.mileageRate };
        case 'UPDATE_PERSONAL_CAR_MILEAGE':
            return {
                ...state,
                personalCarMileage: action.personalCarMileage,
                isPersonalCarMileageDirty: action.isPersonalCarMileageDirty
            };
        case 'HANDLE_PERSONALCARMILEAGE_CHANGE_SUCCESS':
            return {
                ...state,
                personalCarMileage: action.personalCarMileage,
                selectedTrip: action.selectedTrip,
                calculatedMileageReimbursement: action.calculatedMileageReimbursement || state.calculatedMileageReimbursement,
                isPersonalCarMileageDirty: action.isPersonalCarMileageDirty,
                showPersonalCarMileage: action.showPersonalCarMileage
            };
        case 'CALCULATE_MILEAGEREIMBURSEMENT':
            return {
                ...state, calculatedMileageReimbursement: action.calculatedMileageReimbursement
            }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};

