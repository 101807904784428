import * as React from 'react';
import { IDailySummary, IDailyCategoryAmount } from "../../index";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Icon } from '@material-ui/core';
import Thumbnail from '../../Expenses/Thumbnail/Thumbnail';
import Typography from '@material-ui/core/Typography';
import { default as NumberFormat } from 'react-number-format';
import {
    createStyles,
    withStyles,
    Theme,
    WithStyles
} from '@material-ui/core/styles';

interface IDailySummaryTableProps extends WithStyles<typeof styles> {
    calculateForex: any;
    summary: IDailySummary;
    loadingImageKey: string;
    isThumbnailLoadComplete: boolean;
}

class DailySummaryTable extends React.Component<IDailySummaryTableProps, {}>{

    constructor(props?: (IDailySummaryTableProps)) {
        super(props as any);
    }

    render() {

        const { classes } = this.props as any;

        return <div>

            <Table style={{
                "flex": "0 0 100%", "flexGrow": 1
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Expensed</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.props.summary.dailyAmounts && this.props.summary.dailyAmounts.map((daily: IDailyCategoryAmount, $index) =>
                        <TableRow key={$index}>
                            <TableCell>
                                <div> <Icon>{daily.materialIconName}</Icon>
                                    <br />{daily.categoryName}
                                    <br />
                                    <Typography variant="caption">    <br />{daily.expensedDate}</Typography>
                                </div>
                            </TableCell>
                            <TableCell style={{ "padding": 0 }}>
                                <Thumbnail
                                    showLightbox={true}
                                    isLoadingPDF={false}
                                    selectedReceiptMetadata={null}
                                    isThumbnailLoadComplete={this.props.isThumbnailLoadComplete}
                                    loadingImageKey={this.props.loadingImageKey}
                                    metadata={daily.receiptMetadata} />

                                <div style={{
                                    "padding": "20px", "textAlign": "center"
                                }}>

                                    <Typography gutterBottom variant="subtitle1" component="h5" className={classes.money}>
                                        <NumberFormat value={daily.expensedDailyAmount !== undefined ? daily.expensedDailyAmount.toFixed(2) : "0.00"} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                                        <Typography gutterBottom variant="body1" color="textSecondary" style={{
                                            "visibility": daily.receiptMetadata.amountTotalForeignCurrency ? "visible" : "hidden"
                                        }}>
                                            <span>{daily.receiptMetadata.currencyIcon} {daily.receiptMetadata.amountTotalForeignCurrency} (FX: {daily.receiptMetadata.fxRate})</span>
                                        </Typography>

                                    </Typography>



                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>;
    }
}
const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    formControl: {
        width: "100%",
        padding: 20
    },
    select: {
        width: '100%'
    },
    money: {
        color: 'green'
    }
});

export default withStyles(styles)(DailySummaryTable);
