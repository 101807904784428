import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as React from 'react';
import Select from 'react-select';
import { ICategory, IFilterSelect, ITrip } from '../../index';

// Returns an array of dates between the two dates
const getDatesBetween = (startDate: any, endDate: any) => {
    const dates: any = [];

    // Strip hours minutes seconds etc.
    let currentDate: any = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
    );

    while (currentDate <= endDate) {
        dates.push(currentDate);

        currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 1, // Will increase month if over range
        );
    }

    return dates;
};


interface IFilterState {
    stayOpen: boolean;
    removeSelected: boolean;
}
interface IFilterProps extends WithStyles<typeof styles> {
    selectedTrip: ITrip | any;
    categories: ICategory[];
    handleCategoryFilterChange: any;
    handleDateFilterChange: any;
    categoryValue: IFilterSelect | null
    dateValue: IFilterSelect | null;
}


class Filter extends React.Component<IFilterProps, IFilterState> {
    constructor(props?: (IFilterProps)) {
        super(props as any);

        this.state = {
            stayOpen: true,
            removeSelected: true
        }
    }
    componentDidMount() {
        // hide the IOS keyboard.
        document.addEventListener('keyup', this.handleKeyUp);
    }
    componentWillUnmount() {
        // Remove the event listener when the component unmounts to avoid memory leaks
        document.removeEventListener('keyup', this.handleKeyUp);
    }
    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            // Find the focused input element and blur it
            const focusedInput = document.activeElement;
            if (focusedInput instanceof HTMLInputElement) {
                focusedInput.blur();
            }
        }
    };
    render() {

        const { classes } = this.props as any;

        const tripDates = getDatesBetween(new Date(this.props.selectedTrip.startDate),
            new Date(this.props.selectedTrip.endDate));

        let dates: IFilterSelect[] = [];
        let categories: IFilterSelect[] = [];

        // transpose values to filter interface.
        for (let d of tripDates) {
            dates.push({
                label: moment(d).format('dddd, MMMM Do'),
                value: d
            });
        }
        for (let c of this.props.categories) {
            categories.push({
                label: c.name,
                value: c.categoryId.toString()
            });
        }
        return <div>
            <div style={{ 'display': this.props.selectedTrip.receiptMetadata.length > 0 ? 'block' : 'none' }}>
                { /* Date Filter */}
                <div className={classes.root}>
                    <Select
                        style={{ 'fontSize': '17px' }}
                        closeOnSelect={false}
                        isClearable={true}
                        disabled={false}
                        onChange={(e: any) => this.props.handleDateFilterChange(e)}
                        options={dates}
                        placeholder='Filter by: Date'
                        removeSelected={true}
                        value={this.props.dateValue} />
                </div>
                <br />
                { /* Category Filter */}
                <div className={classes.root}>
                    <Select
                        style={{ 'fontSize': '17px' }}
                        closeOnSelect={false}
                        isClearable={true}
                        disabled={false}
                        onChange={this.props.handleCategoryFilterChange}
                        options={categories}
                        placeholder='Filter by: Category'
                        removeSelected={true}
                        value={this.props.categoryValue} />
                </div>
            </div>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        flexWrap: 'wrap',
        fontSize: '17px'
    },
});

export default withStyles(styles)(Filter);