// default model definitions.

import { ITrip } from '../components/index';

export const defaultSelectedTrip: ITrip = {
    id: 0,
    name: '',
    userId: '',
    userEmail: '',
    comments: '',
    receiptMetadata: [],
    filteredReceiptMetadata: [],
    receiptBucket: '',
    employeeName: '',
    description: '',
    startDate: null,
    endDate: null,
    expensedTotal: '0.00',
    travelAdvanceAmount: '',
    costCenter: '',
    vendorNumber: '',
    approvalStatusName: 'Draft',
    submittedOnBehalfOfName: '',
    submittedOnBehalfOfEmail: '',
    submittedOnBehalfOfVendorNumber: '',
    submittedOnBehalfOfCostCenter: '0',
    managerEmailSubmittedTo: '',
    isSurrogateTrip: false,
    lastUpdatedDate: '',
};

export const defaultCategory =
{
    name: '',
    shortName: '',
    categoryId: 0,
    categoryTypeName: '',
    materialIconName: '',
    expensedAmount: 0,
    expensedAmountForeignCurrency: 0,
    isSelected: false,
    replacementCategoryName: '',
    receiptCategoryId: 0,
    account: '',
    businessEntertainmentDto: {
        businessEntertainmentId: 0,
        personsEntertained: '',
        company: '',
        location: '',
        purpose: '',
        isDeductible: true,
        receiptCategoryId: 0
    },
    miscExpenseDto: {
        miscExpenseId: 0,
        expenseItem: '',
        vendor: '',
        categoryId: 0,
        receiptCategoryId: 0,
        account: '',
        defaultMiscAccount: '',
        name: ''
    },
    personalCarMileageDto: {
        personalCarMileageId: 0,
        mileage: 0,
        destination: '',
        receiptCategoryId: 0
    }
};

export const defaultPersonalCarMileage = {
    personalCarMileageId: 0,
    mileage: 0,
    destination: '',
    receiptCategoryId: 0
}
export const defaultBusinessEntertainmentExpense = {
    personsEntertained: '',
    company: '',
    location: '',
    purpose: '',
    isDeductible: true,
    receiptCategoryId: 0
};
export const defaultMiscExpense = {
    miscExpenseId: 0,
    expenseItem: '',
    vendor: '',
    categoryId: 0,
    receiptCategoryId: 0,
    account: '',
    defaultMiscAccount: '',
    name: ''
};
export const defaultSelectedReceiptMetadata = {
    receiptMetadataId: 0,
    key: '',
    categories: [{ ...defaultCategory }],
    tripId: 0,
    amountTotal: 0,
    amountTotalForeignCurrency: 0,
    pdfKey: '',
    currencyName: 'USD',
    currencyId: 1,
    lastUpdatedDate: '',
    dateOfReceipt: '',
    isAuditorApproved: false,
    isPossibleDuplicateInPreviousTrip: false,
    isPossibleDuplicateInThisTrip: false,
    possibleDuplicateIsOk: false
};