import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { default as NumberFormat } from 'react-number-format';

interface IExpensedTotalHeadingProps {
    expensedTotal: string;
}

const ExpensedTotalHeading = (props: IExpensedTotalHeadingProps) => {

    return <div>  <br />  
        <Typography variant='h6' align='center'>
            Expensed Total:
            <br />
        </Typography>
        <Typography style={{ 'color': 'green', 'fontWeight': 700 }} variant='h2' align='center' color='textPrimary'>
            <NumberFormat value={props.expensedTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} />           
        </Typography>
    </div>;
}

export default ExpensedTotalHeading;