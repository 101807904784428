import * as React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
    createStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';

interface ITripFormButtonsProps {
    isEditingTrip: boolean;
    selectedTrip: any;
    updateTrip: any;
    saveTrip: any;
    cancelUpdate: any;
    deleteTrip: any;
}

class TripFormButtons extends React.Component<ITripFormButtonsProps, {}> {

    constructor(props?: (ITripFormButtonsProps)) {
        super(props as any);
    }

    render() {

        const { classes } = this.props as any;

        return <div>
            <Grid container spacing={2} style={{ 'marginTop': '20px' }}>
                <Grid item style={{ 'display': this.props.isEditingTrip ? 'block' : 'none', 'marginTop': '20px' }}>
                    <Button variant='contained' color='primary' onClick={() => this.props.updateTrip(this.props.selectedTrip)}>
                        Save Updates
                    </Button>
                </Grid>
                <Grid item style={{ 'display': this.props.isEditingTrip ? 'none' : 'block', 'marginTop': '20px' }}>
                    <Button variant='contained' color='primary' onClick={() => this.props.saveTrip(this.props.selectedTrip)}>
                        Create Trip
                    </Button>
                </Grid>
                <Grid item style={{ 'marginTop': '20px' }}>
                    <Button variant='outlined' onClick={this.props.cancelUpdate}>
                        Close
                    </Button>
                </Grid>
            </Grid>
            <Grid item style={{ 'display': this.props.isEditingTrip ? 'block' : 'none', 'marginTop': '100px' }}>
                <Button color='secondary' variant='outlined' onClick={this.props.deleteTrip}>
                    <DeleteIcon className={classes.rightIcon} />  Delete Trip
                </Button>
            </Grid>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({

});
export default withStyles(styles)(TripFormButtons as any);