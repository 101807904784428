import CssBaseline from '@material-ui/core/CssBaseline';
import * as React from 'react';
import Footer from './Footer';
import withRoot from './withRoot';

interface ILayoutProps {
    classes: any,
    children: Element;
    history: any;
}

class Layout extends React.Component<ILayoutProps, {}> {

    constructor(props: ILayoutProps) {
        super(props as any);

        this.state = {
            drawerOpen: false
        }
    }
    
    public render() {
        return (
            <div id='main'>
                <CssBaseline />
                <div>   
                    {this.props.children}       
                    <br /> <br /> <br /> <br />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default withRoot(Layout) as any;
