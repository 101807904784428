import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { GetTrip, GetTripsAction } from '../trips/tripActions';
import { Api } from '../../api/Api';
import { IWorkflowDto } from '../../components';
import { IFilterSelect } from '../../components/index';
import { defaultSelectedTrip } from '../../constants/TripDefaults';
import { MISSING_PRIMARYEMAIL, USER_AS_APPROVER_WARNING } from '../../constants/constants';
import { AppThunkAction } from '../../store/index';
import * as tripActions from '../trips/tripReducer';
import { StatusAction } from './statusActions';

const api = new Api();

export interface IStatusTabState {
    users: any[];
    showStatusValidationError: boolean;
    primaryManagerEmailSubmittedTo: string;
    primaryManagerNameSubmittedTo: string;
    primaryManagerSubmittedTo: IFilterSelect | null;
    submitting: boolean;
    isManagerApproved: boolean;
    isAuditorApproved: boolean;
    statusMessage: string;
    showStatusMessage: boolean;
    showRejectForm: boolean;
    rejectionReason: string;
}
export const unloadedState: IStatusTabState = {
    users: [],
    showStatusValidationError: false,
    primaryManagerEmailSubmittedTo: '',
    primaryManagerNameSubmittedTo: '',
    primaryManagerSubmittedTo: null,
    submitting: false,
    isManagerApproved: false,
    isAuditorApproved: false,
    statusMessage: '',
    showStatusMessage: false,
    showRejectForm: false,
    rejectionReason: '',
};
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = StatusAction | GetTrip | GetTripsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {

    recallReport: (workflow: IWorkflowDto): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'RECALL_REPORT',
            submitting: true,
            statusMessage: '',
            showStatusMessage: false,
            showStatusValidationError: false
        });

        let fetchTask = api.recallReport(workflow)
            .then((result: any) => {

                let statusMessage = 'Your Expense Report has been recalled successfully!';

                dispatch({
                    type: 'SUBMIT_FOR_APPROVAL_SUCCESS',
                    submitting: false,
                    showStatusMessage: true,
                    statusMessage: statusMessage,
                    showRejectForm: false,
                    showStatusValidationError: false
                });
            });

        let trip = getState().trip.selectedTrip;
        trip.approvalStatusName = 'Draft';

        dispatch({ type: 'GET_TRIP', selectedTrip: trip, foreignCurrencyAmountIsValid: true, showMetadataForm: false, isTripRefreshing: true });

        addTask(fetchTask);
    },

    handleStatusMessageClose: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'HANDLE_MESSAGE_CLOSE', showStatusMessage: false,
            showStatusValidationError: false
        });
    },
    managerApprove: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MANAGER_APPROVE', isManagerApproved: true, isAuditorApproved: false });
        (actionCreators.submitForApproval())(dispatch, getState);
    },
    managerReject: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'MANAGER_REJECT', showRejectForm: false, isManagerApproved: false, isAuditorApproved: false });
        (actionCreators.submitForApproval())(dispatch, getState);
    },
    auditorApprove: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'AUDITOR_APPROVE', isManagerApproved: true, isAuditorApproved: true });
        (actionCreators.submitForApproval())(dispatch, getState);
    },
    auditorReject: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'AUDITOR_REJECT', showRejectForm: false, isManagerApproved: true, isAuditorApproved: false });
        (actionCreators.submitForApproval())(dispatch, getState);
    },

    handleCloseRejectForm: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_CLOSE_REJECTFORM', showRejectForm: false });
    },

    openRejectForm: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'OPEN_REJECTFORM', showRejectForm: true });
    },
    handleRejectionChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_REJECTION_CHANGE', rejectionReason: e.target.value })
    },
    handlePrimaryChange: (primary: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // only change the primary if the primary has a value.
        if (primary) {
            dispatch({
                type: 'HANDLE_TEMP_PRIMARY_CHANGE',
                primaryManagerSubmittedTo: primary,
                primaryManagerNameSubmittedTo: primary.label,
                primaryManagerEmailSubmittedTo: primary
            });
        }
    },

    submitForApproval: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({
            type: 'SUBMIT_FOR_APPROVAL',
            submitting: true,
            statusMessage: '',
            showStatusMessage: false,
            showRejectForm: false,
            showStatusValidationError: false
        });

        let primaryManagerEmailSubmittedTo = getState().status.primaryManagerEmailSubmittedTo;
        let currentUser = getState().auth.currentUser;

        if (!primaryManagerEmailSubmittedTo) {       
            primaryManagerEmailSubmittedTo = currentUser.primaryManagerEmail;
        }

        // if primaryManagerEmail has a 'value' property, then it's an object from the dropdown.
        if (getState().status.primaryManagerSubmittedTo.value) {
            primaryManagerEmailSubmittedTo = getState().status.primaryManagerSubmittedTo.value;
        }


        // validation: not blank, doesn't equal current user's email. 
        if (getState().trip.selectedTrip.approvalStatusName.toLowerCase() === 'draft') {
            if (!primaryManagerEmailSubmittedTo) {
                dispatch({ type: 'SHOW_VALIDATION_ERROR', statusMessage: MISSING_PRIMARYEMAIL, showStatusValidationError: true, submitting: false, });
                return;
            }

            // safety check: if the user is submitting on behalf of themselves, don't allow it. 
            if (!currentUser.isAuditor) {
                if (primaryManagerEmailSubmittedTo.toLowerCase() === currentUser.email.toLowerCase()) {
                    dispatch({ type: 'SHOW_VALIDATION_ERROR', statusMessage: USER_AS_APPROVER_WARNING, showStatusValidationError: true, submitting: false, });
                    return;
                }
            }

            // safety check: if the user is submitting on behalf of themselves, don't allow it.
            if (primaryManagerEmailSubmittedTo === getState().trip.selectedTrip.submittedOnBehalfOfEmail) {              
                dispatch({ type: 'SHOW_VALIDATION_ERROR', statusMessage: USER_AS_APPROVER_WARNING, showStatusValidationError: true, submitting: false, });
                return;
            }
        }

        let workflow: IWorkflowDto = {
            userId: getState().auth.currentUser.id,
            tripId: getState().trip.selectedTrip.id,
            isManagerApproved: getState().status.isManagerApproved,
            isAuditorApproved: getState().status.isAuditorApproved,
            emailTo: primaryManagerEmailSubmittedTo,
            rejectionReason: getState().status.rejectionReason
        };

        let isAuditor = false;
        let isManager = false;

        let statusMessage = '';
        switch (getState().trip.selectedTrip.approvalStatusName) {
            case 'Draft':
                statusMessage = 'Your report has been submitted for manager approval.';
                break;
            case 'Submitted':
                isManager = true;
                statusMessage = getState().status.isManagerApproved ? 'Success. This report has been: APPROVED.' : 'This report has been REJECTED.';
                break;
            case 'Approved':
                isAuditor = true;
                statusMessage = getState().status.isAuditorApproved ? 'Success. This report has been: PROCESSED.' : 'This report has been REJECTED.';
                break;
            default:
        }

        let selectedTrip = getState().trip.selectedTrip;
        let payablesErrorMessage = 'An error has occurred when submitting to payables. Please download the report PDF and email manually, or refresh and try again.';
       
        await api.routeForward(workflow)
            .then(async (result: any) => {

                let trip = selectedTrip;
                trip.approvalStatusName = result.approvalStatusName;

                let showStatusValidationError = false;

                if (result.emailTooLarge) {
                    statusMessage = 'Email too large to send. You will have to scan manually.';
                    showStatusValidationError = true;
                }

                // if it's an auditor approval but something went wrong server side,
                if (isAuditor && !result.isAuditorApproved) {
                    dispatch({
                        type: 'SUBMIT_FOR_APPROVAL_SUCCESS',
                        submitting: false,
                        showStatusMessage: true,
                        statusMessage: payablesErrorMessage,
                        showRejectForm: false,
                        showStatusValidationError: true
                    });
                }
                else {
                    dispatch({
                        type: 'SUBMIT_FOR_APPROVAL_SUCCESS',
                        submitting: false,
                        showStatusMessage: true,
                        statusMessage: statusMessage,
                        showRejectForm: false,
                        showStatusValidationError: showStatusValidationError
                    });
                }

                setTimeout(() => {
                    // refresh the trip selection list (hack)   
                    tripActions.actionCreators.getTrip(selectedTrip.id)(dispatch as any, getState);

                    let trips = getState().trip.trips;
                    dispatch({ type: 'GET_TRIPS', selectedTrip: { ...selectedTrip }, trips: trips });
                    trips = trips.filter((x: any) => x.id !== selectedTrip.id);

                    if (isAuditor) {
                        if (!getState().status.isAuditorApproved) {
                            dispatch({ type: 'GET_TRIPS', selectedTrip: { ...defaultSelectedTrip }, trips: trips });
                        }
                    }
                    if (isManager) {
                        if (!getState().status.isManagerApproved) {
                            dispatch({ type: 'GET_TRIPS', selectedTrip: { ...defaultSelectedTrip }, trips: trips });
                        }
                    }
                }, 1000);
                   
            })
            .catch(() => {
                dispatch({
                    type: 'SUBMIT_FOR_APPROVAL_SUCCESS',
                    submitting: false,
                    showStatusMessage: true,
                    statusMessage: payablesErrorMessage,
                    showRejectForm: false,
                    showStatusValidationError: true
                });
            });

    },
}

export const reducer: Reducer<IStatusTabState> = (state: IStatusTabState | undefined, action: KnownAction): IStatusTabState => {

    switch (action.type) {

        case 'GET_ALL_USERS': {
            return {
                ...state,
                users: action.users
            }
        }
        case 'SHOW_VALIDATION_ERROR': {
            return {
                ...state,
                showStatusValidationError: action.showStatusValidationError,
                statusMessage: action.statusMessage,
                submitting: action.submitting
            }
        }
        case 'HANDLE_REJECTION_CHANGE':
            return {
                ...state,
                rejectionReason: action.rejectionReason
            }
        case 'HANDLE_CLOSE_REJECTFORM':
            return {
                ...state,
                showRejectForm: action.showRejectForm
            }
        case 'OPEN_REJECTFORM':
            return {
                ...state,
                showRejectForm: action.showRejectForm
            }
        case 'RECALL_REPORT': {
            return {
                ...state,
                submitting: action.submitting,
                statusMessage: action.statusMessage,
                showStatusMessage: action.showStatusMessage,
                showStatusValidationError: action.showStatusValidationError
            };
        }
        case 'HANDLE_TEMP_PRIMARY_CHANGE':
            return {
                ...state,
                primaryManagerEmailSubmittedTo: action.primaryManagerEmailSubmittedTo,
                primaryManagerNameSubmittedTo: action.primaryManagerNameSubmittedTo,
                primaryManagerSubmittedTo: action.primaryManagerSubmittedTo

            }
        case 'HANDLE_MESSAGE_CLOSE':
            return {
                ...state,
                showStatusMessage: action.showStatusMessage,
                showStatusValidationError: action.showStatusValidationError
            }
        case 'MANAGER_APPROVE':
            return {
                ...state,
                isManagerApproved: action.isManagerApproved,
                isAuditorApproved: action.isAuditorApproved
            }
        case 'MANAGER_REJECT':
            return {
                ...state,
                isManagerApproved: action.isManagerApproved,
                isAuditorApproved: action.isAuditorApproved,
                showRejectForm: action.showRejectForm
            }
        case 'AUDITOR_APPROVE':
            return {
                ...state,
                isAuditorApproved: action.isAuditorApproved,
                isManagerApproved: action.isManagerApproved
            }
        case 'AUDITOR_REJECT':
            return {
                ...state,
                isAuditorApproved: action.isAuditorApproved,
                isManagerApproved: action.isManagerApproved,
                showRejectForm: action.showRejectForm
            }
        case 'SUBMIT_FOR_APPROVAL':
            return {
                ...state,
                submitting: action.submitting,
                statusMessage: action.statusMessage,
                showStatusMessage: action.showStatusMessage,
                showStatusValidationError: action.showStatusValidationError
            }
        case 'SUBMIT_FOR_APPROVAL_SUCCESS':
            return {
                ...state,
                submitting: action.submitting,
                statusMessage: action.statusMessage,
                showStatusMessage: action.showStatusMessage,
                showStatusValidationError: action.showStatusValidationError
            }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}
