import { addTask } from 'domain-task';
import moment from 'moment';
import { Reducer } from 'redux';
import { HandleShowProfileForm, HandleShowHelpFAQ } from '../profile/profileActions';
import {
    TripAction
} from './tripActions';
import { Api, getSessionInfo } from '../../api/Api';
import { IBusinessEntertainmentExpense, ICategory, ICurrency, IFilterSelect, IMiscExpense, IReceiptMetadata, ITrip } from '../../components/index';
import { defaultBusinessEntertainmentExpense, defaultCategory, defaultMiscExpense, defaultSelectedReceiptMetadata, defaultSelectedTrip } from '../../constants/TripDefaults';
import {
    isBusinessEntertainmentExpense, isMiscExpense, isPersonalCarMileage,
    NEW_CATEGORY, BUSINESS_ENTERTAINMENT_CATEGORY, MISC_CATEGORY, MOMENT_FORMAT_DEFAULT,
    PERSONAL_CAR_MILEAGE, VALIDATION_MESSAGE, USD_CURRENCY_ID
} from '../../constants/constants';
import { AppThunkAction } from '../../store/index';
import * as validator from '../utils/validators/validators';
import { ITripState, unloadedState } from './tripState';
import { deleteBlob } from '../utils/blobHelper';
import * as pdfActions from '../pdfs/pdfReducer';
import * as summaryActions from '../summary/summaryReducer';
import * as mileageRateActions from '../mileageRate/mileageRateReducer';

const api = new Api();

type KnownAction = TripAction | HandleShowProfileForm | HandleShowHelpFAQ;

export const actionCreators = {
    reload: (): AppThunkAction<KnownAction> => () => {
        window.location.reload();
    },
    getTrips: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        await api.getTrips(getState().auth.currentUser.id).then((trips: ITrip[]) => {
            dispatch({
                type: 'GET_TRIPS_SUCCESS',
                selectedTrip:
                {
                    ...defaultSelectedTrip
                },
                trips: trips,
                isEmployeeView: true,
                isManagerView: false,
                isAuditorView: false
            });

            (actionCreators.getTripsForManagerCount(getState().auth.currentUser.email))(dispatch, getState);
        });

        //dispatch({
        //    type: 'GET_TRIPS',
        //    trips: [],
        //    selectedTrip:
        //    {
        //        ...defaultSelectedTrip
        //    },
        //});

    },
    getTripsForManagerCount: (email: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getTripsForManager(email).then((trips: any) => {
            dispatch({ type: 'GET_TRIPS_MANAGER_COUNT', managerTripsCount: trips.length });
        });

        addTask(fetchTask);
    },
    getTripsForManager: (email: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({ type: 'GET_TRIPS_MANAGER', trips: [] });

        return await api.getTripsForManager(email).then((trips: any) => {
            dispatch({
                type: 'GET_TRIPS_MANAGER_SUCCESS',
                selectedTrip: { ...defaultSelectedTrip },
                trips: trips,
                isManagerView: true,
                isEmployeeView: false,
                isAuditorView: false
            });
        });
    },
    getTripsForAuditor: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getTripsForAuditor().then((trips: any) => {
            dispatch({
                type: 'GET_TRIPS_AUDITOR_SUCCESS',
                selectedTrip: { ...defaultSelectedTrip },
                trips: trips,
                isManagerView: false,
                isEmployeeView: false,
                isAuditorView: true,
            });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_TRIPS_AUDITOR', trips: [] });
    },
    getTripsSuccess: () => <KnownAction>{
        type: 'GET_TRIPS_SUCCESS',
        selectedTrip: { ...defaultSelectedTrip },
    },
    getTrip: (tripId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        await api.getTrip(tripId).then((selectedTrip: any) => {
            if (!selectedTrip.receiptMetadata) {
                return;
            }

            let total: number = 0;
            for (let meta of selectedTrip.receiptMetadata) {
                for (let category of meta.categories) {
                    total = total + parseFloat(category.expensedAmount.toString());
                }
            }

            let totalForeignCurrency: number = 0;
            for (let meta of selectedTrip.receiptMetadata) {
                for (let category of meta.categories) {
                    if (category.expensedAmountForeignCurrency) {
                        totalForeignCurrency = totalForeignCurrency + parseFloat(category.expensedAmountForeignCurrency.toString());
                    }
                }
            }

            let businessEntertainmentExpense: IBusinessEntertainmentExpense = { ...defaultBusinessEntertainmentExpense }
            let miscExpense: IMiscExpense = { ...defaultMiscExpense };

            let showBusinessExpense: boolean = false;
            let showMiscExpense: boolean = false;
            let showPersonalCarMileage: boolean = false;
            let calculatedMileageReimbursement = 0;

            (mileageRateActions.actionCreators.clearPersonalCarMileage())(dispatch as any, getState);

            selectedTrip.receiptMetadata.forEach((x: any) => {

                x.categories.forEach((c: ICategory) => {

                    switch (c.name) {
                        case BUSINESS_ENTERTAINMENT_CATEGORY:
                            businessEntertainmentExpense = {
                                businessEntertainmentId: c.businessEntertainmentDto.businessEntertainmentId,
                                personsEntertained: c.businessEntertainmentDto.personsEntertained,
                                isDeductible: c.businessEntertainmentDto.isDeductible,
                                purpose: c.businessEntertainmentDto.purpose,
                                location: c.businessEntertainmentDto.location,
                                company: c.businessEntertainmentDto.company,
                                receiptCategoryId: c.businessEntertainmentDto.receiptCategoryId
                            };

                            showBusinessExpense = true;
                            break;

                        case MISC_CATEGORY:
                            miscExpense = {
                                miscExpenseId: c.miscExpenseDto.miscExpenseId,
                                expenseItem: c.miscExpenseDto.expenseItem,
                                vendor: c.miscExpenseDto.vendor,
                                categoryId: c.miscExpenseDto.categoryId,
                                receiptCategoryId: c.miscExpenseDto.receiptCategoryId,
                                account: c.miscExpenseDto.defaultMiscAccount,
                                defaultMiscAccount: c.miscExpenseDto.defaultMiscAccount,
                                name: getState().trip.miscExpenseCategories.filter((x: any) => x.categoryId === c.miscExpenseDto.categoryId)[0] ?
                                    getState().trip.miscExpenseCategories.filter((x: any) => x.categoryId === c.miscExpenseDto.categoryId)[0].name : ''
                            };

                            showMiscExpense = true;
                            break;

                        case PERSONAL_CAR_MILEAGE:
                            let personalCarMileage = {
                                personalCarMileageId: c.personalCarMileageDto.personalCarMileageId,
                                mileage: c.personalCarMileageDto.mileage,
                                destination: c.personalCarMileageDto.destination,
                                receiptCategoryId: c.personalCarMileageDto.receiptCategoryId
                            };

                            (mileageRateActions.actionCreators.setPersonalCarMileage(personalCarMileage))(dispatch as any, getState);
                            calculatedMileageReimbursement = parseFloat((personalCarMileage.mileage * getState().mileageRate.mileageRate.amount).toFixed(2));
                            showPersonalCarMileage = true;

                            break;
                        default:
                    }
                });
            });

            if (window.sessionStorage) {
                window.sessionStorage.setItem('tripId', tripId.toString());
            }

            dispatch({
                type: 'GET_TRIP_SUCCESS',
                isTripRefreshing: false,
                pdfDirty: true,              
                selectedTrip: selectedTrip,
                defaultCategories: getState().trip.defaultCategories,
                categories: getState().trip.defaultCategories,
                existingReceipts: [],
                expensedTotal: parseFloat(total.toString()).toFixed(2),
                filteredReceiptMetadata: selectedTrip.receiptMetadata,               
                businessEntertainmentExpense: { ...defaultBusinessEntertainmentExpense },
                miscExpense: { ...defaultMiscExpense },
                showMiscExpense: showMiscExpense,
                showBusinessExpense: showBusinessExpense,
                showPersonalCarMileage: showPersonalCarMileage,               
                loadingImageKey: '',
                selectedReceiptMetadata: { ...defaultSelectedReceiptMetadata }
            });

            (actionCreators.recalculateTotalExpensed())(dispatch, getState);

            // refresh Mileage rate.
            (mileageRateActions.actionCreators.clearPersonalCarMileage())(dispatch as any, getState);
            (mileageRateActions.actionCreators.getMileageRate(selectedTrip.endDate))(dispatch as any, getState);
            (mileageRateActions.actionCreators.setCalculatedMileageReimbursement(calculatedMileageReimbursement))(dispatch as any, getState);

            // refresh Summary tab.
            (summaryActions.actionCreators.getTripCategorySummary(selectedTrip))(dispatch as any, getState);
            (summaryActions.actionCreators.getDailyTotals(selectedTrip))(dispatch as any, getState);
        
            // refresh PDF tab.
            (pdfActions.actionCreators.clearSelectedFiles())(dispatch as any, getState);
        });

        dispatch({ type: 'GET_TRIP', foreignCurrencyAmountIsValid: true, showMetadataForm: false, isTripRefreshing: false });
    },

    // Categories
    getCategories: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getCategories().then((categories: any) => {

            // filter out special categories and save.
            let specialCategories = categories
                .filter((x: any) => x.name === BUSINESS_ENTERTAINMENT_CATEGORY || x.name === MISC_CATEGORY || x.name === PERSONAL_CAR_MILEAGE);

            dispatch({ type: 'GET_CATEGORIES_SUCCESS', categories: categories, specialCategories: specialCategories });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_CATEGORIES' });
    },

    // Categories
    getMiscExpenseCategories: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getMiscExpenseCategories().then((miscExpenseCategories: any) => {
            dispatch({ type: 'GET_MISCEXPENSE_CATEGORIES_SUCCESS', miscExpenseCategories: miscExpenseCategories });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_MISCEXPENSE_CATEGORIES' });
    },
    getMiscExpenseCategoriesSuccess: () => <KnownAction>{ type: 'GET_MISCEXPENSE_CATEGORIES_SUCCESS' },   

    // Currencies
    getCurrencies: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getCurrencies().then((currencies: any) => {
            dispatch({ type: 'GET_CURRENCIES_SUCCESS', currencies: currencies });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_CURRENCIES' });
    },
    getCurrenciesSuccess: () => <KnownAction>{ type: 'GET_CURRENCIES_SUCCESS' },

    showNewReceiptForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let meta = getState().trip.selectedReceiptMetadata;

        meta.categories = [{ ...defaultCategory }];

        let expensedTotal = meta.amountTotal;
        let expensedTotalFC = meta.amountTotalForeignCurrency;

        meta.fxRate = (expensedTotal && expensedTotalFC) ? (expensedTotal / expensedTotalFC).toFixed(4) : '0';

        dispatch({ type: 'SHOW_NEWRECEIPTFORM', showMetadataForm: true, selectedReceiptMetadata: meta, isSaving: false });
    },
    showEditReceiptForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
     
        let meta = getState().trip.selectedReceiptMetadata;

        let expensedTotal = meta.amountTotal;
        let expensedTotalFC = meta.amountTotalForeignCurrency;

        meta.fxRate = (expensedTotal && expensedTotalFC) ? (expensedTotal / expensedTotalFC).toFixed(4) : '0';

        dispatch({ type: 'SHOW_NEWRECEIPTFORM', showMetadataForm: true, selectedReceiptMetadata: meta, isSaving: false });
    },

    // Business Entertainment
    handleBusinessEntertainmentChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (e.target.name === undefined) {
            return;
        }

        let trip = getState().trip;
        let expense = trip.businessEntertainmentExpense;
        if (e.target.name === 'isDeductible') {
            expense[e.target.name] = e.target.value === 'true';
        } else {
            expense[e.target.name] = e.target.value;
        }

        dispatch({ type: 'HANDLE_BUSINESSENTERTAINMENT_CHANGE_SUCCESS', businessEntertainmentExpense: expense, isBusinessExpenseDirty: true });
    },

    // Misc Expense
    handleMiscExpenseChange: (e: any, isCategoryChange: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let misc = getState().trip.miscExpense;

        if (isCategoryChange) {
            misc.categoryId =
                getState().trip.miscExpenseCategories.filter(x => x.categoryId === e.target.value)[0].categoryId!;
        } else {
            misc[e.target.name] = e.target.value;
        }

        // show / hide 'Account' textfield if changing from / to 'Other' category as Auditor role.
        let miscCategories = getState().trip.miscExpenseCategories;
        misc.name = miscCategories.filter(x => x.categoryId === misc.categoryId)[0] ? miscCategories.filter(x => x.categoryId === misc.categoryId)[0].name : '';

        dispatch({ type: 'HANDLE_MISCEXPENSE_CHANGE_SUCCESS', miscExpense: misc, isMiscExpenseDirty: true });
    },

   

    // Events
    handleViewUnapprovedExpensesOnly: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let viewUnapprovedOnly = !getState().trip.viewUnapprovedOnly;

        dispatch({
            type: 'HANDLE_SHOW_UNAPPROVED_EXPENSES_ONLY',
            viewUnapprovedOnly: viewUnapprovedOnly
        });
    },

    toggleSurrogate: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let submitAsSurrogate = !getState().trip.submitAsSurrogate;

        dispatch({
            type: 'TOGGLE_SURROGATE',
            submitAsSurrogate: submitAsSurrogate
        });

        if (!submitAsSurrogate) {
            (actionCreators.clearSurrogateInfo())(dispatch, getState);
        }
    },

    handleShowHelpFAQ: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_SHOW_HELP_FAQ', showHelpFAQ: true });
    },
    handleShowProfileForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_SHOW_PROFILE_FORM', showProfileForm: true, showValidationError: false, message: '' });
    },

    handleSubmittingOnBehalfOfChange: (submittedOnBehalfOf: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        if (submittedOnBehalfOf) {
            const cleanedName = submittedOnBehalfOf?.replace('.', ' ')?.replace(/@us\.schott\.com/g, '');
            let selectedTrip = getState().trip.selectedTrip;
        
            await api.getUser(cleanedName)
                .then(response => response.json())
                .then(data => {
                    const user = data.value;
                    
                    if (user.length === 0) {
                        return;

                    }
                    selectedTrip.submittedOnBehalfOfCostCenter = user[0].extension_823da9332d024198b63e63b45139c9b7_ITCostCenter || getState().auth.currentUser.itCostCenter;
                    selectedTrip.submittedOnBehalfOfName = cleanedName;
                    selectedTrip.submittedOnBehalfOfVendorNumber = user[0].employeeId || getState().auth.currentUser.employeeId;
                                   
                    dispatch({
                        type: 'HANDLE_SUBMITTING_ON_BEHALF_OF_CHANGE',
                        submittedOnBehalfOfEmail: submittedOnBehalfOf,
                        submittedOnBehalfOfName: cleanedName,
                        submittedOnBehalfOf: submittedOnBehalfOf,
                        submittedOnBehalfOfCostCenter: selectedTrip.submittedOnBehalfOfCostCenter ? selectedTrip.submittedOnBehalfOfCostCenter.toString() : '',
                        selectedTrip: selectedTrip
                    });
                });
        }
    },

    handleMessageClose: () => <any>{ type: 'HANDLE_MESSAGE_CLOSE' },
    handleCloseTripForm: () => <any>{ type: 'HANDLE_CLOSE_TRIPFORM', showTripForm: false },
    handleCloseTravelAdvanceForm: () => <any>{ type: 'HANDLE_CLOSE_TRAVELADVANCEFORM' },
    handleNewTravelAdvance: () => <any>{ type: 'HANDLE_NEWTRAVELADVANCE' },
    handleCloseReceiptForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'GET_TRIP',
            showMetadataForm: false,
            foreignCurrencyAmountIsValid: true,
            isTripRefreshing: false
        });
    },
    handleReceiptDateChange: (e: any, metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        metadata.dateOfReceipt = moment(e).format('YYYY-MM-DD');

        dispatch({
            type: 'HANDLE_RECEIPTDATE_CHANGE_SUCCESS', dateOfReceipt: metadata.dateOfReceipt, selectedReceiptMetadata: metadata
        });
    },
    handleCurrencyChange: (e: any, metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedCurrency: ICurrency = getState().trip.currencies.filter(x => x.currencyId === parseInt(e.target.value))[0]!;

        metadata.currencyId = e.target.value;
        metadata.currencyName = selectedCurrency.name;
        metadata.currencyIcon = selectedCurrency.currencyIcon;

        // if changing to USD, clear out any foreign currency amounts.
        let isPersonalCarMileage = false;
        let isUSD = selectedCurrency.currencyId === USD_CURRENCY_ID;

        if (isUSD) {
            metadata.fxRate = '';
            metadata.amountTotalForeignCurrency = 0;

            // clear out any previous values since the fxRate is now different.
            for (let i = 0; i < metadata.categories.length; i++) {
                metadata.categories[i].expensedAmountForeignCurrency = null;
            }
        } else {
            for (let i = 0; i < metadata.categories.length; i++) {
                if (metadata.categories[i].name === PERSONAL_CAR_MILEAGE) {
                    isPersonalCarMileage = true;
                }
            }
        }

        // if not USD and Personal Car Mileage category, show warning message.
        if (isPersonalCarMileage) {
            dispatch({
                type: 'HANDLE_CURRENCYCHANGE_SUCCESS',
                selectedReceiptMetadata: metadata,
                foreignCurrencyAmountIsValid: isUSD ? true : validator.isForeignCurrencyAmountValid(getState().trip.selectedReceiptMetadata),
                showAmountTotalForeignCurrency: !isUSD,
                showValidationError: true,
                message: 'Currency must be USD when Personal Car Mileage is selected!'
            });
        } else {
            dispatch({
                type: 'HANDLE_CURRENCYCHANGE_SUCCESS',
                selectedReceiptMetadata: metadata,
                foreignCurrencyAmountIsValid: isUSD ? true : validator.isForeignCurrencyAmountValid(getState().trip.selectedReceiptMetadata),
                showAmountTotalForeignCurrency: !isUSD,
                showValidationError: false,
                message: ''
            });
        }
    },
    handleAmountTotalChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'HANDLE_AMOUNTTOTAL_CHANGE',
            showAmountTotalForeignCurrency: false
        });

        let metadata = getState().trip.selectedReceiptMetadata;
        metadata.amountTotal = e.target.value;

        let expensedTotal = e.target.value;
        let expensedTotalFC = metadata.amountTotalForeignCurrency;

        metadata.fxRate = (expensedTotal && expensedTotalFC) ? (expensedTotal / expensedTotalFC).toFixed(4) : '0';

        // valid if the total for all foreign expenses equals the total input by the user.
        let foreignCurrencyAmountIsValid = validator.isForeignCurrencyAmountValid(metadata);

        // clear out any previous values since the fxRate is now different.
        for (let i = 0; i < metadata.categories.length; i++) {
            metadata.categories[i].expensedAmount = null;
            metadata.categories[i].expensedAmountForeignCurrency = null;
        }

        dispatch({
            type: 'HANDLE_AMOUNTTOTAL_CHANGE_SUCCESS',
            showAmountTotalForeignCurrency: getState().trip.selectedReceiptMetadata.currencyName === 'USD' ? false : true,
            fxRate: metadata.fxRate,
            foreignCurrencyAmountIsValid: foreignCurrencyAmountIsValid,
            selectedReceiptMetadata: metadata
        });
    },
    handleAmountTotalForeignCurrencyChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'HANDLE_AMOUNTTOTAL_CHANGE',
            showAmountTotalForeignCurrency: false
        });

        let metadata = getState().trip.selectedReceiptMetadata;
        metadata.amountTotalForeignCurrency = e.target.value;

        let expensedTotal = metadata.amountTotal;
        let expensedTotalFC = e.target.value;

        metadata.fxRate = (expensedTotal && expensedTotalFC) ? (expensedTotal / expensedTotalFC).toFixed(4) : '0';

        for (let i = 0; i < metadata.categories.length; i++) {
            metadata.categories[i].expensedAmountForeignCurrency = null;
            metadata.categories[i].expensedAmount = null;
        }

        // valid if the total for all foreign expenses equals the total input by the user.
        let foreignCurrencyAmountIsValid = validator.isForeignCurrencyAmountValid(getState().trip.selectedReceiptMetadata);

        dispatch({
            type: 'HANDLE_AMOUNTTOTAL_FOREIGNCURRENCY_CHANGE_SUCCESS',
            showAmountTotalForeignCurrency: getState().trip.selectedReceiptMetadata.currencyName === 'USD' ? false : true,
            fxRate: metadata.fxRate,
            foreignCurrencyAmountIsValid: foreignCurrencyAmountIsValid,
            selectedReceiptMetadata: metadata
        });
    },
    handleAmountForeignCurrencyChange: (expensedAmountForeignCurrencyValue: any, category: ICategory): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (!expensedAmountForeignCurrencyValue) {
            expensedAmountForeignCurrencyValue = 0;
        }

        let selectedReceiptMetadata = getState().trip.selectedReceiptMetadata;
        let fxRate = selectedReceiptMetadata.fxRate;

        let i: number = selectedReceiptMetadata.categories.findIndex((x: any) => x.categoryId === category.categoryId);

        expensedAmountForeignCurrencyValue = expensedAmountForeignCurrencyValue && parseFloat(expensedAmountForeignCurrencyValue);

        selectedReceiptMetadata.categories[i].expensedAmountForeignCurrency = parseFloat(expensedAmountForeignCurrencyValue.toFixed(2));

        // compute the new fx rate.
        selectedReceiptMetadata.categories[i].expensedAmount = parseFloat((expensedAmountForeignCurrencyValue * parseFloat(fxRate)).toFixed(2));

        // get the total of all foreign currency amounts for each category.
        let expensedAmountTotalAllCategoriesFC: number = 0;
        for (let j = 0; j < selectedReceiptMetadata.categories.length; j++) {
            if (selectedReceiptMetadata.categories[j].expensedAmountForeignCurrency) {
                expensedAmountTotalAllCategoriesFC += selectedReceiptMetadata.categories[j].expensedAmountForeignCurrency;
            }
        }
        console.log(parseFloat(expensedAmountTotalAllCategoriesFC.toFixed(2))); // true
        console.log(selectedReceiptMetadata.amountTotalForeignCurrency);// true

        // valid if the total for all foreign expenses equals the total input by the user.
        let foreignCurrencyAmountIsValid = parseFloat(expensedAmountTotalAllCategoriesFC.toFixed(2))
            == selectedReceiptMetadata.amountTotalForeignCurrency;
        console.log(foreignCurrencyAmountIsValid);


        let offByFC = '';
        if (!foreignCurrencyAmountIsValid) {
            offByFC = (expensedAmountTotalAllCategoriesFC - selectedReceiptMetadata.amountTotalForeignCurrency).toFixed(2);
        }
        // get the total of all amounts for each category.
        let expensedAmountTotalAllCategories: number = 0;
        for (let j = 0; j < selectedReceiptMetadata.categories.length; j++) {
            if (selectedReceiptMetadata.categories[j].expensedAmount) {
                expensedAmountTotalAllCategories += selectedReceiptMetadata.categories[j].expensedAmount;
            }
        }

        console.log(expensedAmountTotalAllCategories);
        console.log(selectedReceiptMetadata.amountTotal);
        // valid if the total for all foreign expenses equals the total input by the user.
        let amountIsValid = expensedAmountTotalAllCategories == selectedReceiptMetadata.amountTotal;
        console.log(amountIsValid);

        let offBy = '';
        if (!amountIsValid) {
            offBy = (expensedAmountTotalAllCategories - selectedReceiptMetadata.amountTotal).toFixed(2);
        }

        dispatch({
            type: 'HANDLE_AMOUNTCHANGE',
            foreignCurrencyAmountIsValid: foreignCurrencyAmountIsValid,
            selectedReceiptMetadata: selectedReceiptMetadata,
            offByFC: offByFC,
            offBy: offBy
        });
    },
    handleAmountChange: (value: number, category: ICategory): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedReceiptMetadata = getState().trip.selectedReceiptMetadata;
        let i: number = selectedReceiptMetadata.categories.findIndex((x: any) => x.categoryId === category.categoryId);
        selectedReceiptMetadata.categories[i].expensedAmount = value;

        dispatch({ type: 'HANDLE_AMOUNTCHANGE', foreignCurrencyAmountIsValid: true, selectedReceiptMetadata: selectedReceiptMetadata });
    },

    handleNewTrip: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedTrip = { ...defaultSelectedTrip }     
        selectedTrip.costCenter = getState().auth.currentUser.itCostCenter || '0';

        dispatch({
            type: 'HANDLE_NEWTRIP',
            showTripForm: true,
            isEditingTrip: false,
            selectedTrip: selectedTrip,
            submitAsSurrogate: false
        });
    },
    handleEditTrip: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedTrip = { ...getState().trip.selectedTrip }

        if (!selectedTrip.costCenter) {
            selectedTrip.costCenter = getState().auth.currentUser.itCostCenter;
        }
      
        dispatch({
            type: 'HANDLE_EDITTRIP',
            showTripForm: true,
            isEditingTrip: true,
            selectedTrip: selectedTrip,
            submitAsSurrogate: selectedTrip.submittedOnBehalfOfName !== '' && selectedTrip.submittedOnBehalfOfName !== null,
            submittedOnBehalfOfName: { label: selectedTrip.submittedOnBehalfOfName, value: selectedTrip.submittedOnBehalfOfEmail },
            submittedOnBehalfOf: { label: selectedTrip.submittedOnBehalfOfName, value: selectedTrip.submittedOnBehalfOfEmail }
        });
    },

    handleStartDateChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedTrip = getState().trip.selectedTrip;
        selectedTrip.startDate = moment(e).format('YYYY-MM-DD');

        dispatch({
            type: 'HANDLE_STARTDATE_CHANGE',
            startDate: moment(e).format('YYYY-MM-DD'),
            selectedTrip: selectedTrip || { ...defaultSelectedTrip }
        });
    },
    handleEndDateChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedTrip = getState().trip.selectedTrip;
        selectedTrip.endDate = moment(e).format('YYYY-MM-DD');

        dispatch({
            type: 'HANDLE_ENDDATE_CHANGE',
            endDate: moment(e).format('YYYY-MM-DD'),
            selectedTrip: selectedTrip || { ...defaultSelectedTrip }
        });
    },
    handleChange: (e: any, name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedTrip = getState().trip.selectedTrip;
        selectedTrip[name] = e.target.value;

        dispatch({ type: 'HANDLE_CHANGE', selectedTrip: selectedTrip });
    },
    handleTabChangeAndPreparePDFReport: (e: any, selectedTabValue: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({ type: 'HANDLE_TAB_CHANGE', selectedTabValue: selectedTabValue });

        if (selectedTabValue === 1 && getState().trip.pdfDirty) {
            // create pdf on blob storage so it's ready for the user to download.
            await (pdfActions.actionCreators.createPdfOnTabChange(e))(dispatch as any, getState);

            // update the pdfDirty flag.
            dispatch({ type: 'UPDATE_PDF_DIRTY_FLAG', pdfDirty: false });
        }
    },
    handleAddNewCategoryAndAmount: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let newDefaultCategory: ICategory = { ...defaultCategory, name: 'New' };

        let selectedReceiptMetadata = getState().trip.selectedReceiptMetadata;

        let waitingOnNewCategory = false;
        for (let category of selectedReceiptMetadata.categories) {
            if (category.categoryId === 0) {
                waitingOnNewCategory = true;
            }
        }

        if (!waitingOnNewCategory) {
            selectedReceiptMetadata.categories.push(newDefaultCategory);

            // filter out special categories.
            let categories = getState().trip.categories.filter((el: any) =>
                getState().trip.specialCategories.indexOf(el) < 0);

            dispatch({ type: 'HANDLE_ADDNEWCATEGORYANDAMOUNT', categories: categories, selectedReceiptMetadata: selectedReceiptMetadata });
        }
    },

    handleCategoryChange: (e: React.ChangeEvent<any>, category: ICategory): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedReceiptMetadata = getState().trip.selectedReceiptMetadata;

        let selectedCategory: ICategory = getState().trip.categories.filter(x => x.categoryId === parseInt(e.target.value))[0]!;

        if (!selectedCategory) {
            return;
        }

        category.replacementCategoryName = selectedCategory.name;

        // set to our current trip.
        selectedReceiptMetadata.tripId = getState().trip.selectedTrip.id;

        // if updating existing metadata...
        let showBusinessExpense = false;
        let showMiscExpense = false;
        let showPersonalCarMileage = false;
        let calculatedMileageReimbursement = 0;
       
        mileageRateActions.actionCreators.clearPersonalCarMileage()(dispatch as any, getState);    

        if (getState().trip.selectedReceiptMetadata.receiptMetadataId > 0) {

            // if switching to a special category...
            // Business Expense
            if (isBusinessEntertainmentExpense(category.replacementCategoryName)) {
                showBusinessExpense = true;
            } else {
                selectedCategory.businessEntertainmentDto = defaultCategory.businessEntertainmentDto;
            }

            // Misc
            if (isMiscExpense(category.replacementCategoryName)) {
                showMiscExpense = true;
            } else {
                selectedCategory.miscExpenseDto = defaultCategory.miscExpenseDto;
            }

            // Personal Car Mileage
            if (isPersonalCarMileage(category.replacementCategoryName)) {
                showPersonalCarMileage = true;
            } else {
                selectedCategory.personalCarMileageDto = defaultCategory.personalCarMileageDto;
            }

            // switch the current category to the category being changed to.
            let indexOfSelectedCategory: any = selectedReceiptMetadata.categories.findIndex((x: any) => x.receiptCategoryId === category.receiptCategoryId);
            selectedReceiptMetadata.categories[indexOfSelectedCategory].categoryId = selectedCategory.categoryId;
            selectedReceiptMetadata.categories[indexOfSelectedCategory].name = selectedCategory.name;
            selectedReceiptMetadata.categories[indexOfSelectedCategory].shortName = selectedCategory.shortName;
        }
        else {
            // add new.
            let indexOfSelectedCategory: any = selectedReceiptMetadata.categories.findIndex(x => x.replacementCategoryName === selectedCategory.name);

            if (category.name === NEW_CATEGORY) {
                selectedReceiptMetadata.categories.pop(); // remove the default 'placeholder' category.
                selectedReceiptMetadata.categories.push(selectedCategory);

            } else {
                selectedReceiptMetadata.categories[indexOfSelectedCategory] = selectedCategory;
            }

            if (isMiscExpense(selectedCategory.name)) {
                showMiscExpense = true;
            }
            else if (isBusinessEntertainmentExpense(selectedCategory.name)) {
                showBusinessExpense = true;
            }
            else if (isPersonalCarMileage(selectedCategory.name)) {
                showPersonalCarMileage = true;
                calculatedMileageReimbursement = 0;              

                // set personalCarMileage in mileageRate reducer state.
                mileageRateActions.actionCreators.setPersonalCarMileage({
                    personalCarMileageId: 0,
                    mileage: 0,
                    destination: '',
                    receiptCategoryId: getState().mileageRate.personalCarMileage.receiptCategoryId
                })(dispatch as any, getState);              
            }
        }

        // clear any expensed amount tied to this category.
        selectedCategory.expensedAmount = null;
        selectedCategory.expensedAmountForeignCurrency = null;

        dispatch({
            type: 'HANDLE_CATEGORYCHANGE',
            selectedReceiptMetadata: selectedReceiptMetadata,
            selectedCategory: selectedCategory,          
            showPersonalCarMileage: showPersonalCarMileage,
            showBusinessExpense: showBusinessExpense,
            showMiscExpense: showMiscExpense,           
        });

        (mileageRateActions.actionCreators.setCalculatedMileageReimbursement(calculatedMileageReimbursement))(dispatch as any, getState);
    },

    handleCategoryFilterChange: (categoryValue: any, dateValue: IFilterSelect | null): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let filteredReceiptMetadata: IReceiptMetadata[] = [];
        let trip = getState().trip;
        let selectedTrip = trip.selectedTrip;

        dispatch({ type: 'HANDLE_CATEGORY_FILTER_CHANGE', categoryValue: categoryValue });

        if (!categoryValue) {

            filteredReceiptMetadata = selectedTrip.receiptMetadata;
            categoryValue = '';
            dispatch({ type: 'HANDLE_DATE_FILTER_CHANGE', categoryValue: '', filteredReceiptMetadata: filteredReceiptMetadata, dateValue: dateValue });

        } else {

            let metadata;
            if (trip.dateValue) {
                // 'Date' filter will override the Category filter.
                metadata = trip.filteredReceiptMetadata;
            } else {
                metadata = selectedTrip.receiptMetadata;
            }

            for (let i = 0; i < metadata.length; i++) {

                if (categoryValue.length > 0) {

                    // filter for each category.
                    for (let filteredCategory of categoryValue.split(',')) {
                        for (let c of metadata[i].categories) {
                            if (c.categoryId === parseInt(filteredCategory)) {
                                filteredReceiptMetadata.push(metadata[i]);
                            }
                        }
                    }
                }
                else {
                    for (let c of metadata[i].categories) {
                        // filter single category.                          
                        if (c.categoryId === parseInt(categoryValue.value)) {
                            filteredReceiptMetadata.push(metadata[i]);
                        }
                    }
                }
            }

            dispatch({ type: 'SET_TRIP_METADATA', filteredReceiptMetadata: filteredReceiptMetadata });
        }
    },
    handleDateFilterChange: (dateValue: IFilterSelect | null): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedTrip = getState().trip.selectedTrip;
        let filteredReceiptMetadata = selectedTrip.receiptMetadata;

        dispatch({
            type: 'HANDLE_DATE_FILTER_CHANGE',
            dateValue: dateValue,
            categoryValue: null,
            filteredReceiptMetadata: filteredReceiptMetadata
        });

        if (dateValue !== null) {
            // for sanity, when clearing 'Date' filter also clear the 'Category' filter.   
            let filteredMetadata =
                filteredReceiptMetadata.filter(x => moment(x.dateOfReceipt).format(MOMENT_FORMAT_DEFAULT) ===
                    moment(dateValue.value).format(MOMENT_FORMAT_DEFAULT));

            dispatch({ type: 'SET_TRIP_METADATA', filteredReceiptMetadata: filteredMetadata });
        }
    },


    recalculateTotalExpensed: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedTrip = getState().trip.selectedTrip;
        let total: number = 0;

        for (let meta of selectedTrip.receiptMetadata) {
            for (let category of meta.categories) {
                total = total + parseFloat(category.expensedAmount.toString());
            }
        }

        selectedTrip.expensedTotal = parseFloat(total.toString()).toFixed(2);

        dispatch({
            type: 'RECALCULATE_TOTALEXPENSED',
            selectedTrip: selectedTrip, filteredReceiptMetadata: selectedTrip.receiptMetadata
        });
    },
    
    cancelUpdate: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CANCEL_UPDATE', showValidationError: false, message: '' });
    },
    clearMetadataForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'CLEAR_METADATAFORM',          
            categoryValue: '',           
            businessEntertainmentExpense: { ...defaultBusinessEntertainmentExpense },
            miscExpense: { ...defaultMiscExpense },
            categories: getState().trip.defaultCategories,
            isSaving: false,
            isMiscExpenseDirty: false,          
            isBusinessExpenseDirty: false,
            lastUpdatedMetadataId: 0,
            selectedReceiptMetadata: { ...defaultSelectedReceiptMetadata }
        });

        // clear out any previous values since the fxRate is now different.
        (mileageRateActions.actionCreators.clearPersonalCarMileage())(dispatch as any, getState);
        (mileageRateActions.actionCreators.setCalculatedMileageReimbursement(0))(dispatch as any, getState);

        (pdfActions.actionCreators.clearSelectedFiles())(dispatch as any, getState);
    },
    clearSurrogateInfo: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedTrip = getState().trip.selectedTrip;

        selectedTrip.submittedOnBehalfOfName = '';
        selectedTrip.submittedOnBehalfOfEmail = '';
        selectedTrip.submittedOnBehalfOfCostCenter = '0';
        selectedTrip.submittedOnBehalfOfVendorNumber = '';

        dispatch({
            type: 'CLEAR_SURROGATE_INFO',
            selectedTrip: selectedTrip
        });
    },
    removeReceiptCategory: (receiptCategoryId: number, metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip;
        let categories = trip.defaultCategories;
        let selectedReceiptMetadata = trip.selectedReceiptMetadata;

        selectedReceiptMetadata.categories.pop();

        if (receiptCategoryId === 0) {
            dispatch({
                type: 'REMOVE_RECEIPTCATEGORY',
                categories: categories,
                selectedReceiptMetadata: selectedReceiptMetadata
            });
        }

        if (receiptCategoryId > 0) {
            api.deleteReceiptCategory(receiptCategoryId)
                .then(() => {
                    dispatch({
                        type: 'REMOVE_RECEIPTCATEGORY',
                        foreignCurrencyAmountIsValid: !selectedReceiptMetadata.fxRate ? true : false,
                        categories: categories,
                        selectedReceiptMetadata: metadata
                    });

                    if (!selectedReceiptMetadata.fxRate) {
                        (actionCreators.updateReceiptMetadata(selectedReceiptMetadata))(dispatch, getState);
                    }
                });
        }
    },
    selectReceiptMetadata: (metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip;

        let categories = trip.defaultCategories;
        let selectedReceiptMetadata = metadata;

        let miscExpense = metadata.categories[0].miscExpenseDto;
        let businessEntertainmentExpense = metadata.categories[0].businessEntertainmentDto;
        let personalCarMileage = metadata.categories[0].personalCarMileageDto;

        let existingCategory = trip.miscExpenseCategories.filter(x => x.categoryId === miscExpense.categoryId)[0];
        miscExpense.name = existingCategory ? existingCategory.name : '';

        let expensedTotal = selectedReceiptMetadata.amountTotal;
        let expensedTotalFC = selectedReceiptMetadata.amountTotalForeignCurrency;

        if (expensedTotal && expensedTotalFC) {
            selectedReceiptMetadata.fxRate = (expensedTotal / expensedTotalFC).toFixed(4);
        } else {
            selectedReceiptMetadata.fxRate = '0';
        }

        // set the selected metadata being updated and load the special categories.  
        dispatch({
            type: 'SELECT_RECEIPTMETADATA',
            categories: categories,
            selectedReceiptMetadata: selectedReceiptMetadata,
            businessEntertainmentExpense: businessEntertainmentExpense,
            miscExpense: miscExpense,         
            showPersonalCarMileage: personalCarMileage && personalCarMileage.personalCarMileageId > 0,
            fxRate: metadata.fxRate,
          
        });

        // set personal car mileage in mileageRate reducer.
        let calculatedMileageReimbursement = parseFloat((metadata.categories[0].personalCarMileageDto.mileage * getState().mileageRate.mileageRate.amount).toFixed(2));

        (mileageRateActions.actionCreators.setPersonalCarMileage(personalCarMileage))(dispatch as any, getState);
        (mileageRateActions.actionCreators.setCalculatedMileageReimbursement(calculatedMileageReimbursement))(dispatch as any, getState);
    },
    setTripMetadata: (filteredMetadata: IReceiptMetadata[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_TRIP_METADATA', filteredReceiptMetadata: filteredMetadata });
    },

    updateAuditorApprovalItem: (metadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // make sure otherAdminExpense has an account value.
        let otherAdminExpense = (metadata.categories.filter(x => x.miscExpenseDto.name === 'Other Admin Expense'))[0];

        if (otherAdminExpense && !otherAdminExpense.miscExpenseDto.account) {

            metadata.isAuditorApproved = false;

            dispatch({
                type: 'UPDATE_AUDITOR_APPROVAL_ITEM',
                metadata: metadata,
                selectedReceiptMetadata: { ...metadata },
                showMessage: true,
                message: 'Please fill in an account # for Other Admin Expense'
            });

            return;
        }

        api.updateReceiptMetadata(metadata)
            .then((result: any) => {
                dispatch({
                    type: 'UPDATE_AUDITOR_APPROVAL_ITEM',
                    metadata: metadata,
                    selectedReceiptMetadata: { ...metadata }
                });
            });
    },
    updateReceiptMetadata: (metadata: IReceiptMetadata, showMessage: boolean = true): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip;

        if (trip.selectedReceiptMetadata.categories) {

            for (let m of trip.selectedReceiptMetadata.categories) {
                // important: pre-check for personal car mileage.
                if (getState().mileageRate.isPersonalCarMileageDirty && m.name === 'Personal Car Mileage') {
                    // need to set the expensed amount prior to saving.
                    m.expensedAmount = getState().mileageRate.calculatedMileageReimbursement;
                }
            }
            //console.log(getState().trip.offBy);
            //if there's any 'off by' value, add it to the expensed amount of the first category.    
            let offBy = parseFloat((parseFloat(getState().trip.offBy).toFixed(2)));
            //console.log(offBy);

            if (offBy && !isNaN(offBy)) {
                // console.log(trip.selectedReceiptMetadata.categories[0].expensedAmount);
                trip.selectedReceiptMetadata.categories[0].expensedAmount -= offBy;
                // console.log(trip.selectedReceiptMetadata.categories[0].expensedAmount);
            }
        }

        let valid = validator.isValidExpense(trip);

        if (!valid) {
            dispatch({
                type: 'UPDATE_RECEIPTMETADATA_SUCCESS',
                isSaving: false,
                selectedReceiptMetadata: { ...trip.selectedReceiptMetadata },
                showMetadataForm: true,
                lastUpdatedMetadataId: trip.selectedReceiptMetadata.receiptMetadataId,
                showValidationError: true,
                showDuplicationWarning: false,
                showMessage: true,
                message: VALIDATION_MESSAGE,
            });
            return;
        }

        for (let category of metadata.categories) {

            switch (category.name) {
                case 'Business Entertainment':
                    if (trip.isBusinessExpenseDirty) {
                        category.businessEntertainmentDto = trip.businessEntertainmentExpense;
                    }
                    break;
                case 'Misc':
                    if (trip.isMiscExpenseDirty) {
                        category.miscExpenseDto = trip.miscExpense;
                    }
                    break;
                case 'Personal Car Mileage':
                    if (getState().mileageRate.isPersonalCarMileageDirty) {
                        category.personalCarMileageDto = getState().mileageRate.personalCarMileage;
                    }
                    break;
            }
        }

        metadata.currentUserId = getState().auth.currentUser && getState().auth.currentUser.id;

        if (metadata.receiptMetadataId > 0) {
            api.updateReceiptMetadata(metadata)
                .then((result: any) => {

                    if ((result.isPossibleDuplicateInPreviousTrip || result.isPossibleDuplicateInThisTrip)
                        && !result.possibleDuplicateIsOK) {

                        dispatch({
                            type: 'UPDATE_RECEIPTMETADATA_SUCCESS',
                            selectedReceiptMetadata: metadata,
                            isSaving: false,
                            offBy: '',
                            showMetadataForm: true,
                            showValidationError: false,
                            showDuplicationWarning: true,
                            showMessage: false,
                            message: result.message
                        });
                    } else {
                        dispatch({
                            type: 'UPDATE_RECEIPTMETADATA_SUCCESS',
                            selectedReceiptMetadata: { ...defaultSelectedReceiptMetadata },
                            offBy: '',
                            isSaving: false,
                            showMetadataForm: false,
                            showValidationError: false,
                            showDuplicationWarning: false,
                            showMessage: showMessage,
                            message: 'Expense updated.'
                        });

                        (actionCreators.clearMetadataForm())(dispatch, getState);
                        (actionCreators.getTrip(trip.selectedTrip.id))(dispatch, getState);
                    }
                });

        } else {
            api.saveReceiptMetadata(metadata)
                .then((result: any) => {

                    metadata.receiptMetadataId = result.receiptMetadataId;

                    if ((result.isPossibleDuplicateInPreviousTrip || result.isPossibleDuplicateInThisTrip)
                        && !result.possibleDuplicateIsOK) {

                        dispatch({
                            type: 'UPDATE_RECEIPTMETADATA_SUCCESS',
                            isSaving: false,
                            offBy: '',
                            selectedReceiptMetadata: { ...getState().trip.selectedReceiptMetadata },
                            showMetadataForm: true,
                            lastUpdatedMetadataId: result.receiptMetadataId,
                            showValidationError: false,
                            showDuplicationWarning: true,
                            showMessage: false,
                            message: result.message
                        });
                    } else {
                        dispatch({
                            type: 'UPDATE_RECEIPTMETADATA_SUCCESS',
                            isSaving: false,
                            offBy: '',
                            selectedReceiptMetadata: { ...defaultSelectedReceiptMetadata },
                            showMetadataForm: false,
                            lastUpdatedMetadataId: result.receiptMetadataId,
                            showValidationError: false,
                            showDuplicationWarning: false,
                            showMessage: showMessage,
                            message: 'Expense added.'
                        });

                        (actionCreators.getTrip(trip.selectedTrip.id))(dispatch, getState);
                    }
                });
        }

        dispatch({
            type: 'UPDATE_RECEIPTMETADATA',
            lastUpdatedMetadataId: trip.selectedReceiptMetadata.receiptMetadataId,
            isSaving: true
        });
    },

    deleteReceipt: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = api.deleteReceiptMetadata(getState().trip.selectedReceiptMetadata.receiptMetadataId).then(() => {

            let trip = getState().trip;
            if (trip.selectedReceiptMetadata.key) {
                deleteBlob(trip.selectedReceiptMetadata.key).then(() => {
                    (actionCreators.getTrip(trip.selectedTrip.id))(dispatch, getState);
                }).catch((error) => {
                    // console.log('An error occurred:', error);
                });
            } else {
                (actionCreators.getTrip(trip.selectedTrip.id))(dispatch, getState);
            }
            dispatch({ type: 'DELETE_RECEIPT_SUCCESS', showMetadataForm: false, showMessage: true, message: 'Your expense has been removed.' });
        });

        addTask(fetchTask);

        dispatch({
            type: 'DELETE_RECEIPT',
            showMetadataForm: true,
            message: '',
            showMessage: true
        });
    },
    saveTrip: (newTrip: ITrip): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (!validator.isValidTrip(newTrip, dispatch, getState().auth.currentUser.email)) {
            return;
        };

        if (getState().trip.submitAsSurrogate) {

            // check if all surrogate fields are valid.
            newTrip.submittedOnBehalfOfEmail = getState().trip.submittedOnBehalfOfEmail;
            newTrip.submittedOnBehalfOfName = getState().trip.submittedOnBehalfOfName;

            let validSurrogateFields = getState().trip.submittedOnBehalfOfName !== ''
                && newTrip.submittedOnBehalfOfCostCenter
                && newTrip.submittedOnBehalfOfVendorNumber !== '';

            if (!validSurrogateFields) {
                return;
            }
        }

        let currentUser = getState().auth.currentUser;

        newTrip.userId = currentUser.id;
        newTrip.userEmail = currentUser.email;
        if (!newTrip.travelAdvanceAmount) {
            newTrip.travelAdvanceAmount = 0.00;
        }
        newTrip.approvalStatusId = 0;

        let fetchTask = api.saveTrip(newTrip)
            .then((trip: any) => {

                if (trip.message) {
                    dispatch({
                        type: 'SAVE_TRIP_ERROR',
                        isLoading: false,
                        showTripForm: true,
                        showValidationError: true,
                        message: 'Trip Name must be unique. Please try a different name.',
                        selectedTrip: newTrip
                    });

                } else {
                    newTrip.id = trip.id;

                    (actionCreators.getTrip(newTrip.id))(dispatch, getState);
                    (actionCreators.getTrips())(dispatch, getState);

                    dispatch({
                        type: 'SAVE_TRIP_SUCCESS',
                        isLoading: false,
                        showTripForm: false,
                        showValidationError: false,
                        message: ''
                    });
                }
            });

        addTask(fetchTask);

        dispatch({
            type: 'SAVE_TRIP',
            isLoading: true
        });
    },
    updateTrip: (trip: ITrip): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        let selectedTrip = getState().trip.selectedTrip;

        if (!validator.isValidTrip(trip, dispatch, getState().auth.currentUser.email)) {
            return;
        };

        let validDefaultFields = trip.name !== null && trip.description !== '' && trip.userId !== null;

        if (!getState().trip.submitAsSurrogate) {
            // we don't need to check costcenter of current user if submitting as surrogate.
            validDefaultFields = validDefaultFields && trip.costCenter !== null;

            if (!validDefaultFields) {
                return;
            }
        }

        if (getState().trip.submitAsSurrogate) {

            trip.submittedOnBehalfOfEmail = getState().trip.submittedOnBehalfOfEmail;

            // get the user's name from the email.
            const cleanedName = trip.submittedOnBehalfOfEmail?.replace('.', ' ')?.replace(/@us\.schott\.com/g, '');

            try {
                const response = await api.getUser(cleanedName);
                const data = await response.json();
                const user = data.value;

                if (user.length === 0) {
                    return;
                }

                trip.submittedOnBehalfOfName = (user[0] && user[0].displayName) ? user[0].displayName : user.displayName;

                // Check if all surrogate fields are valid
                let validSurrogateFields = trip.submittedOnBehalfOfEmail !== ''
                    && trip.submittedOnBehalfOfName !== ''
                    && trip.submittedOnBehalfOfCostCenter
                    && trip.submittedOnBehalfOfVendorNumber !== '';

                if (!validDefaultFields || !validSurrogateFields) {
                    return;
                }

            } catch (error) {
                // console.error("Error fetching or processing user:", error);
            }
        }

        let fetchTask = api.updateTrip(trip)
            .then(async () => {
                trip.startDate = moment(trip.startDate).format('MM/DD/YYYY');
                trip.endDate = moment(trip.endDate).format('MM/DD/YYYY');

                dispatch({
                    type: 'UPDATE_TRIP_SUCCESS',
                    isLoading: false,
                    selectedTrip: trip,
                    showTripForm: false,
                    lastUpdatedMetadataId: 0,
                    showValidationError: false,
                    showDuplicationWarning: false,
                    message: ''
                });

                await actionCreators.getTrips()(dispatch, getState);
                (actionCreators.getTrip(selectedTrip.id))(dispatch, getState);
            });

        addTask(fetchTask);

        dispatch({
            type: 'UPDATE_TRIP',
            isLoading: true
        });
    },
    deleteTrip: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip.selectedTrip;
        let selectedTripId = trip.id;

        let fetchTask = api.deleteTrip(selectedTripId)
            .then(() => {

                for (let i = 0; i < trip.receiptMetadata.length; i++) {
                    const key = trip.receiptMetadata[i].key;

                    if (key) {
                        deleteBlob(key).catch((error) => {
                            // console.log('An error occurred:', error);
                        });
                    }
                }

                dispatch({
                    type: 'DELETE_TRIP_SUCCESS',
                    isLoading: false,
                    showTripForm: false,
                    isEditingTrip: false,
                    selectedTrip: { ...defaultSelectedTrip },
                    showValidationError: false,
                    message: ''
                });

                (actionCreators.getTrips())(dispatch, getState);

            });

        addTask(fetchTask);

        dispatch({
            type: 'DELETE_TRIP',
            isLoading: true
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<ITripState> = (state: ITripState, action: KnownAction) => {

    switch (action.type) {
        case 'UPDATE_PDF_DIRTY_FLAG': {
            return {
                ...state,
                pdfDirty: action.pdfDirty
            }
        }
        case 'HANDLE_SHOW_UNAPPROVED_EXPENSES_ONLY': {
            return {
                ...state,
                viewUnapprovedOnly: action.viewUnapprovedOnly
            }
        }
        case 'TOGGLE_SURROGATE': {
            return {
                ...state,
                submitAsSurrogate: action.submitAsSurrogate
            }
        }
        case 'UPDATE_AUDITOR_APPROVAL_ITEM': {
            return {
                ...state,
                metadata: action.metadata,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                showMessage: action.showMessage,
                message: action.message
            }
        }
        case 'GET_TRIPS_MANAGER_SUCCESS': {
            return {
                ...state,
                selectedTrip: action.selectedTrip,
                trips: action.trips,
                isManagerView: action.isManagerView,
                isEmployeeView: action.isEmployeeView,
                isAuditorView: action.isAuditorView
            }
        }
        case 'GET_TRIPS_MANAGER_COUNT': {
            return {
                ...state,
                managerTripsCount: action.managerTripsCount
            }
        }
        case 'GET_TRIPS_AUDITOR_SUCCESS': {
            return {
                ...state,
                selectedTrip: action.selectedTrip,
                trips: action.trips,
                isManagerView: action.isManagerView,
                isEmployeeView: action.isEmployeeView,
                isAuditorView: action.isAuditorView
            }
        }
        case 'CLEAR_SURROGATE_INFO': {
            return {
                ...state,
                selectedTrip: action.selectedTrip
            }
        }
        case 'SHOW_VALIDATION_ERROR': {
            return {
                ...state,
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'SHOW_NEWRECEIPTFORM':
            return {
                ...state,
                showMetadataForm: action.showMetadataForm,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                isSaving: action.isSaving
            }
        case 'UPDATE_TRIP_SUCCESS':
            return {
                ...state,
                isLoading: action.isLoading,
                selectedTrip: action.selectedTrip,
                showTripForm: action.showTripForm,
                lastUpdatedMetadataId: action.lastUpdatedMetadataId,
                showValidationError: action.showValidationError,
                message: action.message,
                pdfDirty: true
            }
        case 'UPDATE_RECEIPTMETADATA': {
            return {
                ...state,
                isSaving: action.isSaving,
                lastUpdatedMetadataId: action.lastUpdatedMetadataId
            }
        }
        case 'UPDATE_RECEIPTMETADATA_SUCCESS': {
            return {
                ...state,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                isSaving: action.isSaving,
                offBy: action.offBy,
                showMetadataForm: action.showMetadataForm,
                lastUpdatedMetadataId: action.lastUpdatedMetadataId,
                showValidationError: action.showValidationError,
                showDuplicationWarning: action.showDuplicationWarning,
                showMessage: action.showMessage,
                message: action.message,
                pdfDirty: true
            }
        }
        case 'HANDLE_DATE_FILTER_CHANGE': {
            return {
                ...state,
                categoryValue: action.categoryValue,
                dateValue: action.dateValue,
                filteredReceiptMetadata: action.filteredReceiptMetadata
            }
        }
        case 'HANDLE_CATEGORY_FILTER_CHANGE': {
            return {
                ...state,
                categoryValue: action.categoryValue,

            }
        }
        case 'HANDLE_SUBMITTING_ON_BEHALF_OF_CHANGE': {
            return {
                ...state,
                submittedOnBehalfOfEmail: action.submittedOnBehalfOfEmail,
                submittedOnBehalfOfName: action.submittedOnBehalfOfName,
                submittedOnBehalfOfCostCenter: action.submittedOnBehalfOfCostCenter,
                submittedOnBehalfOf: action.submittedOnBehalfOf,
                selectedTrip: action.selectedTrip
            }
        }
        case 'SET_TRIP_METADATA': {
            return {
                ...state,
                filteredReceiptMetadata: action.filteredReceiptMetadata
            }
        }
        case 'SAVE_TRIP': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'SAVE_TRIP_SUCCESS': {
            return {
                ...state,
                isLoading: action.isLoading,
                showTripForm: action.showTripForm,
                showValidationError: action.showValidationError,
                message: action.message,
                pdfDirty: true

            }
        }
        case 'SAVE_TRIP_ERROR': {
            return {
                ...state,
                isLoading: action.isLoading,
                showTripForm: action.showTripForm,
                showValidationError: action.showValidationError,
                message: action.message,
                selectedTrip: action.selectedTrip

            }
        }
        case 'DELETE_TRIP': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'DELETE_TRIP_SUCCESS': {
            return {
                ...state,
                isLoading: action.isLoading,
                showTripForm: action.showTripForm,
                isEditingTrip: action.isEditingTrip,
                selectedTrip: { ...defaultSelectedTrip },
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'CANCEL_UPDATE': {
            return {
                ...state,
                isLoading: false,
                showTripForm: false,
                isEditingTrip: false,
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'SELECT_RECEIPTMETADATA':
            return {
                ...state,
                categories: action.categories,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                businessEntertainmentExpense: action.businessEntertainmentExpense,
                miscExpense: action.miscExpense,                
                showPersonalCarMileage: action.showPersonalCarMileage,               
            }
        case 'REMOVE_RECEIPTCATEGORY':
            return {
                ...state,
                categories: action.categories,
                foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                pdfDirty: true
            }
        case 'DELETE_RECEIPT':
            return {
                ...state,
                showMetadataForm: action.showMetadataForm,
                message: action.message,
                showMessage: action.showMessage,
            }
        case 'DELETE_RECEIPT_SUCCESS':
            return {
                ...state,
                showMetadataForm: action.showMetadataForm,
                message: action.message,
                showMessage: action.showMessage,
                pdfDirty: true
            }
        case 'CLEAR_METADATAFORM':
            return {
                ...state,              
                categoryValue: action.categoryValue,
                businessEntertainmentExpense: action.businessEntertainmentExpense,
                miscExpense: action.miscExpense,
                categories: action.categories,
                isSaving: action.isSaving,
                isMiscExpenseDirty: action.isMiscExpenseDirty,
                isBusinessExpenseDirty: action.isBusinessExpenseDirty,
                lastUpdatedMetadataId: action.lastUpdatedMetadataId,
                selectedReceiptMetadata: action.selectedReceiptMetadata
            }
        case 'GET_TRIPS':
            return { ...state, isLoading: true, trips: action.trips, selectedTrip: action.selectedTrip };
        case 'GET_TRIPS_MANAGER':
            return { ...state, isLoading: true, trips: action.trips };
        case 'GET_TRIPS_AUDITOR':
            return { ...state, isLoading: true, trips: action.trips };
        case 'GET_TRIPS_SUCCESS':
            return {
                ...state,
                selectedTrip: state.selectedTrip,
                isLoading: false,
                trips: action.trips,
                isManagerView: action.isManagerView,
                isEmployeeView: action.isEmployeeView,
                isAuditorView: action.isAuditorView
            };
        case 'GET_CATEGORIES':
            return { ...state, categories: state.categories };
        case 'GET_CATEGORIES_SUCCESS':
            return { ...state, categories: action.categories, specialCategories: action.specialCategories, defaultCategories: action.categories };
        case 'GET_MISCEXPENSE_CATEGORIES':
            return { ...state, miscExpenseCategories: state.miscExpenseCategories };
        case 'GET_MISCEXPENSE_CATEGORIES_SUCCESS':
            return { ...state, miscExpenseCategories: action.miscExpenseCategories };
        
        case 'GET_CURRENCIES':
            return { ...state, currencies: state.currencies };
        case 'GET_CURRENCIES_SUCCESS':
            return { ...state, currencies: action.currencies };
        case 'GET_TRIP':
            return { ...state, foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid, selectedTrip: state.selectedTrip, isTripRefreshing: action.isTripRefreshing, showMetadataForm: action.showMetadataForm };
        case 'GET_TRIP_SUCCESS':
            return {
                ...state,
                selectedTrip: action.selectedTrip,
                isTripRefreshing: action.isTripRefreshing,
                pdfDirty: action.pdfDirty,
                categories: action.defaultCategories,
                existingReceipts: [],               
                expensedTotal: action.expensedTotal,
                filteredReceiptMetadata: action.filteredReceiptMetadata,
                selectedReceiptMetadata: action.selectedReceiptMetadata,               
                businessEntertainmentExpense: action.businessEntertainmentExpense,
                miscExpense: action.miscExpense,
                showMiscExpense: action.showMiscExpense,
                showBusinessExpense: action.showBusinessExpense,
                showPersonalCarMileage: action.showPersonalCarMileage,               
            };
        case 'HANDLE_CURRENCYCHANGE_SUCCESS':
            return { ...state, showValidationError: action.showValidationError, message: action.message, foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid, showAmountTotalForeignCurrency: action.showAmountTotalForeignCurrency, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_AMOUNTTOTAL_CHANGE_SUCCESS':
            return { ...state, foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid, fxRate: action.fxRate, showAmountTotalForeignCurrency: action.showAmountTotalForeignCurrency, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_AMOUNTTOTAL_FOREIGNCURRENCY_CHANGE_SUCCESS':
            return { ...state, foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid, fxRate: action.fxRate, showAmountTotalForeignCurrency: action.showAmountTotalForeignCurrency, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_MESSAGE_CLOSE':
            return { ...state, showMessage: false, showValidationError: false, showDuplicationWarning: false };
        case 'HANDLE_CLOSE_TRIPFORM':
            return { ...state, showTripForm: false, showValidationError: false };
        case 'HANDLE_CLOSE_TRAVELADVANCEFORM':
            return { ...state, showTravelAdvanceForm: false };
        case 'HANDLE_NEWTRAVELADVANCE':
            return { ...state, showTravelAdvanceForm: true, isEditingTrip: false };
        case 'HANDLE_CLOSE_RECEIPTFORM':
            return {
                ...state,
                showMetadataForm: action.showMetadataForm,
                selectedReceiptMetadata: action.selectedReceiptMetadata
            };
        case 'HANDLE_BUSINESSENTERTAINMENT_CHANGE_SUCCESS':
            return { ...state, businessEntertainmentExpense: action.businessEntertainmentExpense, isBusinessExpenseDirty: action.isBusinessExpenseDirty };
        case 'HANDLE_MISCEXPENSE_CHANGE_SUCCESS':
            return { ...state, miscExpense: action.miscExpense, isMiscExpenseDirty: action.isMiscExpenseDirty };
      
        case 'HANDLE_RECEIPTDATE_CHANGE_SUCCESS':
            return { ...state, dateOfReceipt: action.dateOfReceipt, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_NEWTRIP':
            return { ...state, submitAsSurrogate: action.submitAsSurrogate, showTripForm: action.showTripForm, isEditingTrip: action.isEditingTrip, selectedTrip: action.selectedTrip };
        case 'HANDLE_EDITTRIP':
            return {
                ...state, submittedOnBehalfOf: action.submittedOnBehalfOf, submittedOnBehalfOfName: action.submittedOnBehalfOfName, submitAsSurrogate: action.submitAsSurrogate, showTripForm: action.showTripForm, isEditingTrip: action.isEditingTrip, selectedTrip: action.selectedTrip
            };
        case 'HANDLE_STARTDATE_CHANGE':
            return { ...state, startDate: action.startDate, selectedTrip: action.selectedTrip };
        case 'HANDLE_ENDDATE_CHANGE':
            return { ...state, endDate: action.endDate, selectedTrip: action.selectedTrip };
        case 'HANDLE_CHANGE':
            return { ...state, selectedTrip: action.selectedTrip };
        case 'HANDLE_TAB_CHANGE':
            return { ...state, selectedTabValue: action.selectedTabValue };
        case 'HANDLE_ADDNEWCATEGORYANDAMOUNT':
            return { ...state, categories: action.categories, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_AMOUNTCHANGE':
            return { ...state, offByFC: action.offByFC, offBy: action.offBy, foreignCurrencyAmountIsValid: action.foreignCurrencyAmountIsValid, selectedReceiptMetadata: action.selectedReceiptMetadata };
        case 'HANDLE_CATEGORYCHANGE':
            return {
                ...state,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                selectedCategory: action.selectedCategory,
                showBusinessExpense: action.showBusinessExpense,
                showMiscExpense: action.showMiscExpense,
                showPersonalCarMileage: action.showPersonalCarMileage   
            };
        case 'RECALCULATE_TOTALEXPENSED':
            return {
                ...state, selectedTrip: action.selectedTrip, filteredReceiptMetadata: action.filteredReceiptMetadata
            }
      
        default:
            break;
    }

    return state || unloadedState;
};
