import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Thumbnail from '../../Expenses/Thumbnail/Thumbnail';
import Typography from '@material-ui/core/Typography';
import { default as NumberFormat } from 'react-number-format';
import moment from 'moment';
import {
    createStyles,
    withStyles,
    Theme,
    WithStyles
} from '@material-ui/core/styles';
import { ICategorySummary, IDailyCategoryAmount } from "../../index";

interface ICategorySummaryTableProps extends WithStyles<typeof styles> {
    calculateForex: any;
    summary: ICategorySummary;
    loadingImageKey: string;
    isThumbnailLoadComplete: boolean;
}


class CategorySummaryTable extends React.Component<ICategorySummaryTableProps, {}>{
    constructor(props?: (ICategorySummaryTableProps)) {
        super(props as any);
    }

    render() {

        const { classes } = this.props as any;

        return <div>

            <Table style={{
                "flex": "0 0 100%", "flexGrow": 1
            }}>

                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Expensed</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {this.props.summary.dailyAmounts && this.props.summary.dailyAmounts.map((daily: IDailyCategoryAmount, $index) =>
                        <TableRow key={$index}>

                            <TableCell >
                                <div>{moment(daily.expensedDate).format("dddd")}
                                    <br />
                                    {moment(daily.expensedDate).format("MMMM Do")}
                                    <br />
                                    <Typography variant="caption">
                                        <br />{daily.categoryName}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell style={{ "padding": 0 }}>
                                <Thumbnail showLightbox={true}
                                    isLoadingPDF={false}
                                    isThumbnailLoadComplete={this.props.isThumbnailLoadComplete}
                                    loadingImageKey={this.props.loadingImageKey}
                                    metadata={daily.receiptMetadata}
                                    selectedReceiptMetadata={null}
                                />
                                <div style={{
                                    "padding": "20px", "textAlign": "center"
                                }}>

                                    <Typography gutterBottom variant="subtitle1" component="h5" className={classes.money}>
                                        <NumberFormat value={daily.expensedDailyAmount !== undefined ? daily.expensedDailyAmount.toFixed(2) : "0.00"} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" style={{
                                        "visibility": daily.receiptMetadata.amountTotalForeignCurrency ? "visible" : "hidden"
                                    }}>
                                        <span>{daily.receiptMetadata.currencyIcon} {daily.receiptMetadata.amountTotalForeignCurrency} (FX: {daily.receiptMetadata.fxRate}</span>
                                    </Typography>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    table: {
        display: "inline-block", width: "100%", minWidth: 700,
    },
    formControl: {
        width: "100%",
        padding: 20
    },
    select: {
        width: '100%'
    },
    money: {
        color: 'green'
    }
});

export default withStyles(styles)(CategorySummaryTable);