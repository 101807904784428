import * as React from 'react';
import Typography from '@material-ui/core/Typography';

const ESignatureText = (props: any) => {
    return <div>
        <Typography variant='body2' color='primary'>By clicking the 'Approval' button, you are signing this report electronically. </Typography>
        <Typography variant='body2' color='primary'>I certify that the above expenses are true and in accordance with Schott North America, Inc. policy. </Typography>
    </div>;
}

export default ESignatureText;