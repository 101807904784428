import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { ApplicationState } from '../../store/index';
import * as ProfileStore from '../../store/profile/profileReducer';
import HelpPanel from './HelpPanel';

interface IHelpProps {

}
type HelpProps = ProfileStore.IProfileState & IHelpProps & typeof ProfileStore.actionCreators & RouteComponentProps<{}>;

export class Help extends React.Component<HelpProps, {}> {

    constructor(props?: any) {
        super(props);
    }

    render() {

        const { classes } = this.props as any;
        const gifPath = './assets/gifs/';

        const basicList = [{
            title: 'How to Log in',
            subTitle: '',
            gif: `${gifPath}01_log on.gif`
        }];
        let basicFAQs = basicList.map((x) => {
            return <HelpPanel key={x.title} title={x.title} gif={x.gif} subTitle={x.subTitle} />
        });

        const profileList = [{
            title: 'Updating Profile',
            subTitle: '',
            gif: `${gifPath}02_update profile.gif`
        }];

        let profileFAQs = profileList.map((x) => {
            return <HelpPanel key={x.title} title={x.title} gif={x.gif} subTitle={x.subTitle}/>
        });

        const tripList = [
            {
                title: 'Creating a New Trip',
                subTitle: '',
                gif: `${gifPath}03_create new trip.gif`
            },
            {
                title: 'Entering on behalf of another',
                subTitle: 'An expense report can be entered by the admin assistant on behalf of their manager.  The admin assistant must start the report using the instructions below.  Once the report is started, both the admin assistant and the manager can contribute receipts to the report at the same time.',
                gif: `${gifPath}04_on behalf of.gif`
            },
            {
                title: 'Editing a Trip (Draft only)',
                subTitle: '',
                gif: `${gifPath}05_edit trip header.gif`
            },
            {
                title: 'Deleting a Trip (Draft only)',
                subTitle: '',
                gif: `${gifPath}06_delete trip.gif`
            },
            {
                title: 'How do I charge more than one cost center?',
                subTitle: 'If you need to charge more than one cost center, on the trip header the cost center field can be overwritten using the format CC1 / CC2 (%/%), e.g. 174050 / 174051 (60/40)',
                gif: ``
            },
        ];

        let tripFAQs = tripList.map((x) => {
            return <HelpPanel key={x.title} title={x.title} gif={x.gif} subTitle={x.subTitle}/>
        });

        const expenseList = [
            {
                title: 'Adding an Expense (Draft only)',
                subTitle: '',
                gif: `${gifPath}07_add expense.gif`
            },
            {
                title: 'Adding another Category to an existing Expense',
                subTitle: '',
                gif: `${gifPath}08_add category to expense.gif`
            },
            {
                title: 'Expenses using Foreign Currency',
                subTitle: '',
                gif: `${gifPath}09_foreign currency.gif`
            }           
        ];

        let expenseFAQs = expenseList.map((x) => {
            return <HelpPanel key={x.title} title={x.title} gif={x.gif} subTitle={x.subTitle}/>
        });

        const approvalList = [
            {
                title: 'Switching to Manager Approval',
                subTitle: '',
                gif: `${gifPath}12_switch to manager.gif`
            },
            {
                title: 'Approving expense report (Manager only)',
                subTitle: '',
                gif: `${gifPath}13_approve expense.gif`
            },
            {
                title: 'Rejecting expense report (Manager only) ',
                subTitle: '',
                gif: `${gifPath}14_reject expense.gif`
            },
            {
                title: 'Submitting to a substitute manager to approve',
                subTitle: 'If you do not get an email that your report is approved within a few days (as the approver may be on vacation), you must first recall the report, then you can submit to another person to approve using the instructions below. They must be a US employee to approve.',
                gif: `${gifPath}15_send to substitute approver.gif`
            },
            {
                title: 'Recalling an expense report after submitting',
                subTitle: 'An Expense report can be recalled any time before the manager approves it. This will put the status back to draft.',
                gif: `${gifPath}16_recall exp report.gif`
            }
        ];

        let approvalFAQs = approvalList.map((x) => {
            return <HelpPanel key={x.title} title={x.title} gif={x.gif} subTitle={x.subTitle} />
        });
        return <div>
            <Dialog fullScreen open={this.props.showHelpFAQ} onClose={this.props.handleCloseHelpFAQ} >
                <AppBar position='static'>
                    <Toolbar>
                        <IconButton onClick={this.props.handleCloseHelpFAQ} aria-label='Menu' color='inherit'>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' color='inherit' >
                            HELP / FAQ
                        </Typography>
                    </Toolbar>
                </AppBar>


                <Grid container justifyContent='center'>
                    <Grid item xs={10} sm={4}>
                        <Typography className={classes.slightPad} variant='h6' align='center' color='textSecondary' paragraph>
                            Travel Expense FAQ
                        </Typography>
                        <Typography className={classes.slightPad} variant='caption' align='center' color='textSecondary' paragraph>
                            Need Help? Please submit an IT ticket for technical issues.
                            <br /><br />For process questions call <a href='tel:1-914-831-2275'>1-914-831-2275</a>&nbsp;  
                              or email <a href='mailto:Christina.Doerr@us.schott.com'>Christina.Doerr@us.schott.com</a>
                           
                            In addition to the FAQs below, tutorial presentations have been created for your convenience, click on the links below if your PC is on the Schott network.
                            <br /><br />
                            <a target='_blank' rel='noopener noreferrer' href='https://my.schott.net/documents/1014043/88361685/T%26E+Tuitorial.pptx/228b04ca-d2da-ab46-650c-6e0d92b582c0?t=1549029185708'>Travel Expense Web App Tutorial for travelers</a>
                            <br />
                            <a target='_blank' rel='noopener noreferrer' href='https://my.schott.net/documents/1014043/88361685/T%26E+Tuitorial+-+Approvers.pptx/84e8e9a8-ecf0-6b2c-b3d7-d5e625e78daf?t=1549029486428'>Travel Expense Web App Tutorial for approvers</a>
                            <br /><br />
                           {/* <a target='_blank' rel='noopener noreferrer' href='https://expenseappreceipts.blob.core.windows.net/shared/Check+Request_Tutorial.pptx?sp=r&st=2023-10-10T20:59:56Z&se=2023-10-11T04:59:56Z&spr=https&sv=2022-11-02&sr=c&sig=r%2B4ONpxfKFeMRCEvxu%2F5d3uAAxHPsLQqWY8gPl%2ByIL8%3D'>Check Request Tutorial</a>*/}
                        </Typography>
                        <br />                    

                        <Typography className={classes.faqItem} variant='subtitle1' align='left' color='textSecondary' paragraph>
                            The Basics
                        </Typography>
                        <Paper>
                            {basicFAQs}
                        </Paper>

                        <Typography className={classes.faqItem} variant='subtitle1' align='left' color='textSecondary' paragraph>
                            Profile
                        </Typography>
                        <Paper>
                            {profileFAQs}
                        </Paper>
                        <br />
                        <Typography className={classes.faqItem} variant='subtitle1' align='left' color='textSecondary' paragraph>
                            Trips
                        </Typography>
                        <Paper>
                            {tripFAQs}
                        </Paper>
                        <br />
                        <Typography className={classes.faqItem} variant='subtitle1' align='left' color='textSecondary' paragraph>
                            Expenses
                        </Typography>
                    
                        <Paper>
                            {expenseFAQs}
                        </Paper>
                      
                        <Paper>  <Divider />
                            <br />
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. You see 'No option' when selecting an approver?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. If you see 'No option' as a result please log off the expense web app to refresh the list.
                            </Typography>

                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. Images required for which expenses?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. Receipts are required for all expenses <strong>except</strong> Tips and Mileage
                            </Typography>
                           
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. How do I enter hotel receipts with internet, parking, and breakfast charges included?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. In this expense you must add other categories for internet, parking, and breakfast if they are charged separately on the hotel receipt. 
                               The sum of all categories should equal the receipt total.  See 'Add category to Expense' demo.
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. What date do I use for hotel receipts?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. All expenses should be recorded on the day of checkout.
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. How are Travel Agency Fees entered ?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. You will find the travel agency fees in the airline receipt. Separate the charges into the plane category and also the Travel Agency Fee category. If you have a separate receipt for the fees enter as a new expense.
                            </Typography>
                           
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                                Q. Can I charge this expense report to another Cost Center ?
                            </Typography>
                            <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                                A. Yes, you can. Just override the default cost center shown with another one or even an internal order number.
                            </Typography>
                            <br /> <br />
                        </Paper>

                        <br />
                        <Typography className={classes.faqItem} variant='subtitle1' align='left' color='textSecondary' paragraph>
                            Approvals
                        </Typography>
                        <Paper>
                            {approvalFAQs}
                        </Paper>
                        <br />
                        <Typography className={classes.faqItem} variant='body1' align='left' color='textPrimary' paragraph>
                            Q. How long do I need to keep paper receipts?
                            </Typography>
                        <Typography className={classes.faqItem} variant='body1' align='left' color='textSecondary' paragraph>
                            A. Until you receive payment
                            </Typography>
                        <br /> <br /> <br />
                    </Grid>
                </Grid>
            </Dialog>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    pad: {
        paddingTop: '50px'
    },
    gif: {
        width: '90%'
    },
    faqItem: {
        marginTop: '20px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    slightPad: {
        paddingTop: '20px'
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    primary: {},
    icon: {},
});

export default compose(connect(
    (state: ApplicationState) => state.profile, 
    ProfileStore.actionCreators                 
), withStyles(styles))(Help) as any;