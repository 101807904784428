import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import DraftIcon from '@material-ui/icons/Drafts';
import MailIcon from '@material-ui/icons/Mail';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import * as React from 'react';
import { ITrip } from '../../index';
import ExpensedTotalHeading from './ExpensedTotalHeading';
import Box from '@material-ui/core/Box';

interface ITripCardProps extends WithStyles<typeof styles> {
    selectedTrip: ITrip;
    handleEditTrip: any;
    submittedBy: string;
}

class TripCard extends React.Component<ITripCardProps, {}> {

    constructor(props?: (ITripCardProps)) {
        super(props as any);
    }

    render() {

        const { selectedTrip } = this.props;
        const { classes } = this.props as any;

        const subheader = <Typography gutterBottom variant='subtitle1' style={{ 'color': '#fff' }}>
            {selectedTrip.description}
            <br />
            {this.props.selectedTrip.submittedOnBehalfOfName ? <span>(on behalf of) {this.props.submittedBy}</span> : <span>{this.props.submittedBy}</span> }
            {this.props.selectedTrip.submittedOnBehalfOfName ? <span> {this.props.selectedTrip.submittedOnBehalfOfEmail}</span> : <span></span>}
        </Typography>;


        return <div>
            <Card className={classes.card}>

                <CardHeader
                    style={{
                        'backgroundColor': '#006fbb'
                    }}
                    title={selectedTrip.name}
                    subheader={subheader}
                    classes={{
                        title: classes.title,
                        subheader: classes.subheader,
                    }}
                />

                <Typography gutterBottom variant='h6' color='primary' title='Status' component='div' style={{
                    'border': '1px solid',
                    'padding': '10px',
                    'backgroundColor': '#eee'
                }}>


                    <Box display='flex' alignItems='center' justifyContent='center'>
                        {this.props.selectedTrip.approvalStatusName === 'Draft' ? <DraftIcon className={classes.icon} />
                            : this.props.selectedTrip.approvalStatusName === 'Submitted' ? <MailIcon className={classes.icon} />
                                : this.props.selectedTrip.approvalStatusName === 'Approved' ? <CheckIcon className={classes.icon} />
                                    : <MonetizationOn className={classes.icon} />}
                        &nbsp;{this.props.selectedTrip.approvalStatusName}
                    </Box>  
                  
                    <Typography variant='subtitle1'>
                        <Grid container justifyContent='center'> <Grid item xs={6}>
                            From: <Typography component='strong' variant='h6'>{selectedTrip.startDate}</Typography>
                        </Grid>
                            <Grid item xs={6}>
                                To:  <Typography component='strong' variant='h6'>{selectedTrip.endDate}
                                </Typography></Grid></Grid>
                    </Typography>
                  
                </Typography>

              

                <ExpensedTotalHeading expensedTotal={this.props.selectedTrip.expensedTotal} />
                <br />    <br />
            </Card>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    icon: {
        verticalAlign: 'bottom'
    },
    title: {
        color: 'white',
    },
    subheader: {
        color: 'white',
    },
    card: {
        textAlign: 'center',
        marginTop: '10px',
        opacity: 1
    },
});

export default withStyles(styles)(TripCard as any);