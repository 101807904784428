export const BUSINESS_ENTERTAINMENT_CATEGORY = 'Business Entertainment';
export const MISC_CATEGORY = 'Misc';
export const PERSONAL_CAR_MILEAGE = 'Personal Car Mileage';
export const HOTEL_CATEGORY = 'Hotel';
export const PLANE_CATEGORY = 'Bus, Plane, Train';
export const NEW_CATEGORY = 'New';
export const USD = 'USD';

export const AUDITOR_GROUPID = 'SCHOTTG_APPL_ExpenseAppAuditor';
export const MANAGER_GROUPID = 'SCHOTTG_APPL_ExpenseAppManager';
export const EMPLOYEE_GROUPID = 'SCHOTTG_APPL_ExpenseAppEmployee';
export const US_GROUPID = '3253ac0b-86d5-485f-8911-f8b5a4b36b7e';
export const TENANT_ID = '086819db-ed40-4544-bfd2-412c6f1af603';
export const CLIENT_ID = 'b44233d5-46ac-4cc8-bbfe-de88f4ac5b55';
export const GRAPH_URL = 'https://graph.microsoft.com';
export const GRAPH_API_LENGTH = 43;
export const GRAPH_API_VERSION = 'v1.0';
export const GRAPH_API_URL = `${GRAPH_URL}/${GRAPH_API_VERSION}`;
export const GRAPH_API_URL_ME = `${GRAPH_API_URL}/me`;
export const BLOB_URL = 'https://expenseappreceipts.blob.core.windows.net/docs';
export const MSGRAPH_USERS_URL = 'https://graph.microsoft.com/v1.0/users';

export const DELETE_WARNING_MESSAGE = 'Are you sure you want to delete this? No Undo.';
export const USER_AS_APPROVER_WARNING = 'Sorry, you cannot select yourself as the approver. Please change the Approver Email address!';
export const MISSING_PRIMARYEMAIL = 'Please verify the Primary Manager Email has a valid address';
export const MISSING_VENDOR_NUMBER = 'Please verify the Vendor Number has a value.';
export const MISSING_ITCOST_CENTER = 'Please verify the IT Cost Center has a value.';
export const UPDATED_PROFILE_MESSAGE = 'Your Profile has been updated successfully.';
export const VALIDATION_MESSAGE = 'Please verify all required fields are filled in';
export const FOREIGN_CURRENCY_TOTAL_WARNING = '* Categories do not add up to Foreign Currency Total!';
export const HOTEL_NOTIFICATION = '* All expenses should be recorded on the day of checkout. ' +
    'In this expense you must add other categories for internet, parking, and breakfast if they are charged separately on the hotel receipt. '
    + ' The sum of all categories should equal the receipt total.';
export const START_END_DATE_WARNING = 'End Date must be after Start Date!';
export const TRIP_OVER_21_DAYS_WARNING = 'Trip must be less than or equal to 21 days! If your trip is over 21 days, please divide it into 2 separate trips.';
export const SUBMITTED_BY_EMAIL_WARNING = 'You cannot use your own email for submitted on behalf of.';
export const PLANETRAIN_NOTIFICATION = '* If there are travel agency fees separate the charges into the Plane category and also the Travel Agency Fee category. If you have a separate receipt for the fees enter as a new expense.';
export const DO_NOT_USE_PLANE_CATEGORY = 'Do NOT submit flights or agency fees booked through CONCUR and TravelStore.';

export const POSSIBLE_DUPLICATE_IN_THIS_TRIP_WARNING = 'Possible duplicate expense (matching Date and Amount) in this trip.';
export const POSSIBLE_DUPLICATE_IN_PREVIOUS_TRIP_WARNING = 'Possible duplicate expense (matching Date and Amount) in another one of your trips.';
export const POSSIBLE_DUPLICATE_WARNING_TITLE = 'Possible Duplicate Expense';

export const USD_CURRENCY_ID = 1;
export const MOMENT_FORMAT_DEFAULT = 'dddd, MMMM Do';
export const TRIP_DATE_FORMAT = 'YYYY-MM-DD';
export const INVALID_CHARACTERS = ['\\', '/', ';', '~', '`', '//', '.', '%', '&', '#', '!', '^', '{', '}', '[', ']', ',', '*', '+'];
export const TRIP_NAME_MISSING_WARNING = 'Trip name must have a value.';

export function isAmountValid(value: any): boolean {
    if (!isNumber(value)) {

        if (value.indexOf('.') === -1) {
            return false;
        }

        let decimals = value.split('.');

        if (decimals.length > 2) {
            return false;
        }

        if (!isNumber(decimals[1])) {
            return false;
        }
    }

    if (value.indexOf('.') > -1) {
        let amountArray = value.split('.');
        if (amountArray[0].length === 6 || amountArray[1].length === 3) {
            return false;
        }
    } else {
        // limit to ten thousandth.
        if (value.length === 6) {
            return false;
        }
    }

    return true;
}

export function isNumber(str: any) {

    const backspace = 8;

    let code, i, len;

    let valid = true;
    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);

        if (!(code > 47 && code < 58)) {
            if (code !== backspace)
                valid = false;
        }
    }

    return valid;
}

export function dataURLtoFile(dataurl: string, filename: string) {
    let arr: any = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function isBusinessEntertainmentExpense(category: string) {
    return category === BUSINESS_ENTERTAINMENT_CATEGORY;
}
export function isMiscExpense(category: string) {
    return category === MISC_CATEGORY;
}
export function isPersonalCarMileage(category: string) {
    return category === PERSONAL_CAR_MILEAGE;
}