import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ITrip } from '../../index';
import CardMedia from '@material-ui/core/CardMedia';

interface ICarouselProps extends WithStyles<typeof styles> {
    selectedTrip: ITrip;
}
interface ICarouselState {
    currentComponent: any;
}

class Carousel extends React.Component<ICarouselProps, ICarouselState> {

    constructor(props?: (ICarouselProps)) {
        super(props as any);

        this.state = {
            currentComponent: Math.floor(Math.random() * 8)
        };
    }

    render() {

        const { classes } = this.props as any;

        const components = [
            <CardMedia key={0} className={`${classes.media}`} image='./assets/images/train-min.webp' />,
            <CardMedia key={1} className={`${classes.media}`} image='./assets/images/passport.webp' />,
            <CardMedia key={2} className={`${classes.media}`} image='./assets/images/airplane-aisle-min.webp' />,
            <CardMedia key={3} className={`${classes.media}`} image='./assets/images/boarding-min.webp' />,
            <CardMedia key={4} className={`${classes.media}`} image='./assets/images/chair-min.webp' />,
            <CardMedia key={5} className={`${classes.media}`} image='./assets/images/airplane-wing.webp' />,
            <CardMedia key={6} className={`${classes.media}`} image='./assets/images/suitcase.webp' />,
            <CardMedia key={7} className={`${classes.media}`} image='./assets/images/taxi-min.webp' />,            
        ];

        return <React.Fragment>
            <div style={{ 'marginTop': '50px', 'display': this.props.selectedTrip.id > 0 ? 'none' : 'inherit' }}>
                <Grid container justifyContent='center'>
                    <Grid item xs={10} md={8} className={classes.border}>
                       {components[this.state.currentComponent]}
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>;
    }
}

const styles = (theme: Theme) => createStyles({
    media: {
        height: 0,
        minHeight: 200,
        paddingTop: '36.25%', // 16:9
    },
    border: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,

        border: '12px solid #eee'

    },
});


export default withStyles(styles)(Carousel as any);