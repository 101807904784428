import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { IBusinessEntertainmentExpense } from '../../..';


    interface IBusinessEntertainmentFormProps extends WithStyles<typeof styles> {
        businessEntertainmentExpense?: IBusinessEntertainmentExpense;
        handleBusinessEntertainmentChange: any;
        showBusinessExpense: boolean;
    }
     interface IBusinessEntertainmentFormState {
         businessEntertainmentExpense: IBusinessEntertainmentExpense;
    }


class BusinessEntertainmentForm extends React.Component<IBusinessEntertainmentFormProps, IBusinessEntertainmentFormState> {

    constructor(props?: (IBusinessEntertainmentFormProps)) {
        super(props as any);
        this.state = {
            businessEntertainmentExpense: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: any) {

        let businessEntertainmentExpense = this.state.businessEntertainmentExpense;
  
        this.setState({
            businessEntertainmentExpense: businessEntertainmentExpense
        });

        this.props.handleBusinessEntertainmentChange(e);
    };

    render() {

        const { classes } = this.props as any;

        return <div className={classes.pad} style={{
            'display': this.props.showBusinessExpense ? 'inline-block' : 'none'
        }}>
            <Paper className={classes.padding} elevation={4}>

                <Typography variant='body1' align='center' color='textPrimary' gutterBottom>
                    * Please fill out the following information:
                </Typography>

                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='personsEntertained'
                        onChange={this.handleChange}
                        required 
                        value={this.props.businessEntertainmentExpense.personsEntertained || ''}
                        label='Persons Entertained:'
                        helperText='Please provide the list of names.'
                    />
                </div>
                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='company'
                        required 
                        onChange={this.handleChange}
                        value={this.props.businessEntertainmentExpense.company || ''}
                        label='Company:'
                    />
                </div>
                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='location'
                        required 
                        onChange={this.handleChange}
                        value={this.props.businessEntertainmentExpense.location || ''}
                        label='Location:'
                    />
                </div>
                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='purpose'
                        required 
                        onChange={this.handleChange}
                        value={this.props.businessEntertainmentExpense.purpose || ''}
                        label='Purpose:'
                    />
                </div>
                <div className={classes.pad}>
                    <FormControl className={classes.formControl}>
                        <FormLabel></FormLabel>
                        <RadioGroup
                            aria-label='Deductible'
                            name='isDeductible'
                            className={classes.group}
                            value={this.props.businessEntertainmentExpense.isDeductible || ''}
                            onChange={this.handleChange}
                        >
                            <FormControlLabel value={true} control={<Radio />} label='Deductible' />
                            <FormControlLabel value={false} control={<Radio />} label='Non-Deductible' />
                        </RadioGroup>
                    </FormControl>
                </div>
            </Paper>
        </div>;
    }
}
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `0 0`,
    },
    textfield: {       
        width: 200,
    },
    padding: {
        padding: 20
    },
    pad: {
        width: '100%',
        paddingTop: '50px'
    },
});

export default withStyles(styles)(BusinessEntertainmentForm);