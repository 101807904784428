import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../../../store/index';
import * as TripStore from '../../../store/trips/tripReducer';
import * as MileageRateStore from '../../../store/mileageRate/mileageRateReducer';
import { Api } from '../../../api/Api';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { ICategory, ICurrency, IReceiptMetadata } from '../../index';
import BusinessEntertainmentForm from './BusinessEntertainmentForm/BusinessEntertainmentForm';
import CategoryNotification from './CategoryNotification/CategoryNotification';
import MiscExpenseForm from './MiscExpenseForm/MiscExpenseForm';
import PersonalCarMileageForm from './PersonalCarMileageForm/PersonalCarMileageForm';
import {
    isAmountValid, USD_CURRENCY_ID, FOREIGN_CURRENCY_TOTAL_WARNING, HOTEL_NOTIFICATION, HOTEL_CATEGORY, PLANE_CATEGORY,
    MISC_CATEGORY, BUSINESS_ENTERTAINMENT_CATEGORY, PERSONAL_CAR_MILEAGE, DO_NOT_USE_PLANE_CATEGORY, PLANETRAIN_NOTIFICATION, DELETE_WARNING_MESSAGE
} from '../../../constants/constants';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ITripState } from '../../../store/trips/tripState';

interface IExpenseFormState {
    expenseSubMenuOpen: boolean;
    amount: number;
    amountForeignCurrency: number;
    amountTotal: number;
    amountTotalForeignCurrency: number;
    anchorEl: any;
}
interface IExpenseFormLocalProps {
    handleTripCloseReceiptForm: any;
    updateTripReceiptMetadata: any;
}
type IExpenseFormProps =
    ITripState
    & MileageRateStore.IMileageRateState
    & IExpenseFormLocalProps
    & typeof MileageRateStore.actionCreators
    & typeof TripStore.actionCreators;

class ExpenseForm extends React.Component<IExpenseFormProps, IExpenseFormState> {

    api: Api = new Api();

    constructor(props?: (IExpenseFormProps)) {
        super(props as any);

        this.state = {
            amount: 0,
            amountForeignCurrency: 0,
            amountTotal: 0,
            amountTotalForeignCurrency: 0,
            expenseSubMenuOpen: false,
            anchorEl: null
        };

        this.isSpecialCategory = this.isSpecialCategory.bind(this);
        this.deleteReceipt = this.deleteReceipt.bind(this);
        this.handleCloseExpenseSubMenu = this.handleCloseExpenseSubMenu.bind(this);
        this.openExpenseSubMenu = this.openExpenseSubMenu.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleAmountForeignCurrencyChange = this.handleAmountForeignCurrencyChange.bind(this);
        this.handleAmountTotalForeignCurrencyChange = this.handleAmountTotalForeignCurrencyChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleReceiptDateChange = this.handleReceiptDateChange.bind(this);
        this.handleAddNewCategoryAndAmount = this.handleAddNewCategoryAndAmount.bind(this);
    }

    isSpecialCategory = () => this.props.selectedReceiptMetadata.categories.findIndex(
        x => x.name === BUSINESS_ENTERTAINMENT_CATEGORY ||
            x.name === MISC_CATEGORY ||
            x.name === PERSONAL_CAR_MILEAGE) !== -1;

    openExpenseSubMenu() {
        this.setState({
            expenseSubMenuOpen: true
        });
    }
    handleCloseExpenseSubMenu() {
        this.setState({
            expenseSubMenuOpen: false
        });
    }
    handleAddNewCategoryAndAmount(e: any) {
        this.handleCloseExpenseSubMenu();
        this.props.handleAddNewCategoryAndAmount(e);
    }

    handleCurrencyChange(e: any) {

        let amountTotalForeignCurrency = this.state.amountTotalForeignCurrency;

        this.setState({
            amountTotalForeignCurrency: amountTotalForeignCurrency
        });

        this.props.handleCurrencyChange(e, this.props.selectedReceiptMetadata)
    }

    handleReceiptDateChange(e: any, selectedReceiptMetadata: IReceiptMetadata) {
        this.props.handleReceiptDateChange(e, selectedReceiptMetadata)
    }

    handleAmountChange(e: any, category: any) {

        let value = e.target.value.toString();

        if (!isAmountValid(value)) {
            return false;
        }

        this.setState({
            amount: value
        });

        this.props.handleAmountChange(value, category);
    }

    handleAmountForeignCurrencyChange(e: any, category: any) {

        let value = e.target.value.toString();

        if (!isAmountValid(value)) {
            return false;
        }

        this.setState({
            amountForeignCurrency: value
        });

        this.props.handleAmountForeignCurrencyChange(value, category);
    }

    handleAmountTotalChange(e: any) {
        let value = e.target.value.toString();

        if (!isAmountValid(value)) {
            return false;
        }

        this.setState({
            amountTotal: value
        });

        this.props.handleAmountTotalChange(e);
    }

    handleAmountTotalForeignCurrencyChange(e: any) {

        let value = e.target.value.toString();

        if (!isAmountValid(value)) {
            return false;
        }

        this.setState({
            amountTotalForeignCurrency: value
        });

        this.props.handleAmountTotalForeignCurrencyChange(e);
    }

    deleteReceipt() {
        if (window.confirm(DELETE_WARNING_MESSAGE)) {
            this.handleCloseExpenseSubMenu();
            this.props.deleteReceipt();
        }
    }

    render() {
        const { classes } = this.props as any;

        if (!this.props.selectedReceiptMetadata) {
            return <div> </div>;
        }

        return (
            <div className={classes.pad}>
                <Dialog fullScreen open={this.props.showMetadataForm} onClose={this.props.handleCloseReceiptForm}>
                    <AppBar position='static'>
                        <Toolbar>
                            <IconButton className={classes.menuButton} onClick={this.props.handleCloseReceiptForm} aria-label='Menu' color='inherit'>
                                <CloseIcon />
                            </IconButton>

                            <Typography variant='h6' color='inherit' style={{ 'paddingLeft': '10px' }}>
                                {this.props.selectedTrip.name}
                            </Typography>

                            <section>
                                <Box display='flex' alignItems='right' className={classes.padBottom}>
                                    <IconButton className={classes.moreVertIcon}
                                        onClick={(e: any) => this.setState({ anchorEl: e.currentTarget, expenseSubMenuOpen: true })}
                                        color='primary'>
                                        <Icon>menu</Icon>
                                    </IconButton>

                                    <Menu
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.expenseSubMenuOpen}
                                        onClose={this.handleCloseExpenseSubMenu}>
                                        <MenuItem disabled={this.props.selectedReceiptMetadata.categories.length === 0 || this.isSpecialCategory()} onClick={this.handleAddNewCategoryAndAmount}>
                                            <AddBoxIcon /> Add Category / Amount
                                        </MenuItem>
                                        <MenuItem disabled={!this.props.selectedReceiptMetadata.receiptMetadataId} onClick={this.deleteReceipt}>
                                            <DeleteIcon /> Delete Expense
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </section>
                        </Toolbar>
                    </AppBar>


                    <DialogContent>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12} md={5} >

                                {/* Date */}
                                <div className={classes.pad}>
                                    <div className={classes.container}>

                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <div className={classes.container}>
                                                <DatePicker
                                                    initialFocusedDate={this.props.selectedTrip.startDate}
                                                    minDate={this.props.selectedTrip.startDate}
                                                    maxDate={this.props.selectedTrip.endDate}
                                                    autoOk={true}
                                                    error={!this.props.selectedReceiptMetadata.dateOfReceipt}
                                                    invalidDateMessage={'Please enter a Date'}
                                                    required
                                                    emptyLabel={' '}
                                                    label='Expense Date'
                                                    format={moment(this.props.selectedReceiptMetadata.dateOfReceipt).format('MM/DD/YYYY')}
                                                    value={this.props.selectedReceiptMetadata.dateOfReceipt || null}
                                                    className={classes.textField}
                                                    onChange={(e: any) => this.handleReceiptDateChange(e, this.props.selectedReceiptMetadata)}
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>

                                {/* Currency */}
                                <div className={classes.pad}>
                                    <FormControl className={classes.select}>
                                        <InputLabel>Currency</InputLabel>
                                        <Select className={classes.select}
                                            error={this.props.showPersonalCarMileage && this.props.selectedReceiptMetadata.currencyId !== USD_CURRENCY_ID}
                                            value={this.props.selectedReceiptMetadata.currencyId}
                                            onChange={(e: any) => this.handleCurrencyChange(e)}
                                        >

                                            {this.props.currencies.map((c: ICurrency) =>
                                                <MenuItem key={c.currencyId} value={c.currencyId}>{`${c.currencyIcon}  ${c.name}`}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>

                                    { /* TOTAL values for currencies */}
                                    <div style={{
                                        'display': this.props.selectedReceiptMetadata.currencyName !== 'USD'
                                            && this.props.selectedReceiptMetadata.categories
                                            && this.props.selectedReceiptMetadata.categories[0]
                                            && this.props.selectedReceiptMetadata.categories[0].name !== PERSONAL_CAR_MILEAGE ? 'inherit' : 'none'
                                    }}>

                                        <br /><br />

                                        <TextField
                                            fullWidth
                                            error={!this.props.selectedReceiptMetadata.amountTotalForeignCurrency}
                                            helperText={`FX: (${this.props.selectedReceiptMetadata.fxRate})`}
                                            onChange={(e: any) => this.handleAmountTotalForeignCurrencyChange(e)}
                                            type='number'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: { inputMode: 'decimal' }
                                            }}
                                            value={this.props.selectedReceiptMetadata.amountTotalForeignCurrency ? this.props.selectedReceiptMetadata.amountTotalForeignCurrency : ''}
                                            label={`* (${this.props.selectedReceiptMetadata.currencyIcon}) Foreign Currency Total`}
                                        />

                                        <br /><br /><br />

                                        <TextField
                                            fullWidth
                                            helperText={this.props.offBy ? `(off by: ${this.props.offBy}) - Rounding will be added to the first category after saving the report. ` : ''}
                                            error={!this.props.selectedReceiptMetadata.amountTotal}
                                            color={'primary'}
                                            onChange={(e: any) => this.handleAmountTotalChange(e)}
                                            type='number'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    inputMode: 'decimal'
                                                }
                                            }}
                                            value={this.props.selectedReceiptMetadata.amountTotal ? this.props.selectedReceiptMetadata.amountTotal : ''}
                                            label={<Typography color='primary'>* ($) USD Total </Typography>}
                                        />
                                    </div>
                                </div>

                                { /* Categories */}
                                {this.props.selectedReceiptMetadata.categories.map((category: ICategory, $index) =>
                                    <Paper key={category.categoryId} style={{
                                        marginTop: '15px'
                                    }}>

                                        <div className={classes.pad}>
                                            <FormControl className={classes.select}>
                                                <InputLabel required>Category</InputLabel>
                                                <Select
                                                    required
                                                    error={!category.categoryId}
                                                    value={category.categoryId}
                                                    onChange={(e: any) => this.props.handleCategoryChange(e, category)}
                                                >
                                                    <MenuItem value=''>
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {this.props.categories.map((c: ICategory) =>
                                                        <MenuItem key={c.categoryId} value={c.categoryId}>{`${c.name}`}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div> { /* Remove Category */}
                                            <Button variant='outlined' style={{ 'display': $index > 0 ? 'inherit' : 'none', 'cursor': 'pointer' }} onClick={() => this.props.removeReceiptCategory(category.receiptCategoryId, this.props.selectedReceiptMetadata)} >
                                                <Typography variant='caption' style={{ marginLeft: '13px', paddingBottom: '13px' }}>(x) remove</Typography>
                                            </Button>
                                        </div>

                                        <div> { /* Personal Car Mileage Form */}
                                            <PersonalCarMileageForm
                                                showPersonalCarMileage={this.props.showPersonalCarMileage && category.name === PERSONAL_CAR_MILEAGE}
                                                personalCarMileage={this.props.personalCarMileage}
                                                handlePersonalCarMileageChange={(e: any) => this.props.handlePersonalCarMileageChange(e, this.props.selectedReceiptMetadata)} />

                                            { /* Personal Car Mileage Amount */}
                                            <TextField className={classes.margin}
                                                style={{
                                                    marginLeft: '20px',
                                                    padding: '20px',
                                                    display: this.props.showPersonalCarMileage && category.name === PERSONAL_CAR_MILEAGE ? 'inline-block' : 'none'
                                                }}
                                                type='number'
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                                inputProps={{
                                                    inputMode: 'numeric'
                                                }}
                                                disabled={true}
                                                value={this.props.calculatedMileageReimbursement}
                                                label={<Typography variant='caption'>Calculated Expensed (USD):</Typography>}
                                            />
                                        </div>

                                        { /* Amount (NATIVE) */}
                                        <div className={classes.pad}
                                            style={{
                                                'display': category.name !== PERSONAL_CAR_MILEAGE
                                                    && this.props.selectedReceiptMetadata.currencyName === 'USD' ? 'block' : 'none'
                                            }}>

                                            <FormControl fullWidth className={classes.margin}>
                                                <InputLabel htmlFor='amount' required>{`${category.shortName ? category.shortName : category.name} Expensed (USD):`}</InputLabel>
                                                <Input
                                                    required
                                                    inputProps={{
                                                        inputMode: 'decimal'
                                                    }}
                                                    id='amount'
                                                    type='number'
                                                    disabled={!category.categoryId || category.name === PERSONAL_CAR_MILEAGE}
                                                    value={category.expensedAmount ? category.expensedAmount : null}
                                                    onChange={(e: any) => this.handleAmountChange(e, category)}
                                                    startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </div>

                                        { /* Amount (FC) */}
                                        <div className={classes.pad}
                                            style={{
                                                'display': category.name !== PERSONAL_CAR_MILEAGE
                                                    && this.props.selectedReceiptMetadata.currencyName !== 'USD' ? 'block' : 'none'
                                            }}>

                                            <FormControl fullWidth className={classes.margin}>
                                                <InputLabel htmlFor='amount_fc' required>{`${category.shortName ? category.shortName : category.name} Expensed (${this.props.selectedReceiptMetadata.currencyName}):`}</InputLabel>
                                                <Input
                                                    required
                                                    error={!category.expensedAmountForeignCurrency}
                                                    inputProps={{
                                                        inputMode: 'decimal'
                                                    }}
                                                    id='amount_fc'
                                                    type='number'
                                                    disabled={!category.categoryId || category.name === PERSONAL_CAR_MILEAGE}
                                                    value={category.expensedAmountForeignCurrency ? category.expensedAmountForeignCurrency : ''}
                                                    onChange={(e: any) => this.handleAmountForeignCurrencyChange(e, category)}
                                                    endAdornment={<Typography variant='subtitle1' color='primary'>${category.expensedAmount} </Typography>}
                                                    startAdornment={<InputAdornment position='start'>{this.props.selectedReceiptMetadata.currencyIcon || ''}</InputAdornment>}
                                                />
                                                <Typography variant='caption'>{!this.props.foreignCurrencyAmountIsValid ?
                                                    <span style={{ color: 'red' }}>{FOREIGN_CURRENCY_TOTAL_WARNING}</span>
                                                    : ''}
                                                </Typography>
                                            </FormControl>
                                        </div>

                                        <Grid item xs={12} md={12}>
                                            {/* Business Entertainment Expense Form  */}
                                            <BusinessEntertainmentForm
                                                showBusinessExpense={this.props.showBusinessExpense && category.name === BUSINESS_ENTERTAINMENT_CATEGORY}
                                                businessEntertainmentExpense={this.props.businessEntertainmentExpense}
                                                handleBusinessEntertainmentChange={this.props.handleBusinessEntertainmentChange} />

                                            {/* Misc Expense Form */}
                                            <MiscExpenseForm
                                                isAuditor={this.props.isAuditorView}
                                                showMiscExpense={this.props.showMiscExpense && category.name === MISC_CATEGORY}
                                                miscExpense={this.props.miscExpense}
                                                handleMiscExpenseChange={this.props.handleMiscExpenseChange}
                                                miscExpenseCategories={this.props.miscExpenseCategories} />

                                        </Grid>
                                        <div>
                                            {/* Hotel Notification */}
                                            <CategoryNotification show={category.name === HOTEL_CATEGORY} notification={HOTEL_NOTIFICATION} />
                                        </div>

                                        <div>
                                            {/* Bus / Plane / Train Notification */}
                                            <CategoryNotification show={category.name === PLANE_CATEGORY} notification={DO_NOT_USE_PLANE_CATEGORY} />
                                        </div>

                                        <div style={{ 'display': category.name === HOTEL_CATEGORY || category.name === PLANE_CATEGORY ? 'inline-block' : 'none' }}>
                                            <br /><br />
                                            <Button disabled={!category.expensedAmount} color='primary' onClick={this.handleAddNewCategoryAndAmount}>

                                                <AddBoxIcon /> Add another Category
                                            </Button>
                                        </div>
                                    </Paper>
                                )}

                                {/* Save */}
                                <Grid container spacing={2} style={{ 'marginTop': '20px', 'paddingBottom': '50px' }}>
                                    <Grid item style={{ 'marginTop': '20px' }}>
                                        <Button
                                            disabled={this.props.isSaving
                                                || !this.props.foreignCurrencyAmountIsValid
                                                || (this.props.showPersonalCarMileage && this.props.selectedReceiptMetadata.currencyId !== USD_CURRENCY_ID)
                                                || (this.props.showPersonalCarMileage && this.props.personalCarMileage && !this.props.personalCarMileage.mileage)
                                                || (this.props.showPersonalCarMileage && this.props.personalCarMileage && !this.props.personalCarMileage.destination)
                                            }
                                            variant='contained'
                                            color='secondary'
                                            onClick={(e: any) => this.props.updateTripReceiptMetadata(this.props.selectedReceiptMetadata)}>
                                            {this.props.isSaving ? 'Saving...' : 'Save'}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ 'marginTop': '20px' }}>
                                        <Button style={{ 'display': this.props.isSaving ? 'none' : 'inherit' }}
                                            variant='outlined'
                                            onClick={this.props.handleTripCloseReceiptForm} >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const styles = (theme: Theme) => createStyles({
    select: {
        width: '100%'
    },
    pad: {
        padding: '15px'
    },
    textfield: {

        width: 200,
    },
    button: {

    },
    moreVertIcon: {
        marginLeft: '50px',
        color: 'white'
    }
});

export default compose(connect(
    (state: ApplicationState) => state.mileageRate,
    MileageRateStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(ExpenseForm) as any;