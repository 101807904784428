import * as React from 'react';
import { Icon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { default as NumberFormat } from 'react-number-format';

interface ICategorySummaryHeaderProps {
    categoryName: string;
    materialIconName: string;
    total: string;
    count: number;
}

const categorySummaryHeader = (props: ICategorySummaryHeaderProps) => {

    return <div>
        <Typography variant="body2" style={{ "color": "#fff" }} gutterBottom><Icon style={{ "verticalAlign": "bottom" }}>
            {props.materialIconName}</Icon> {props.categoryName} 
        </Typography>

        <Typography variant="body2" style={{ "color": "#fff","float":"left" }} gutterBottom>          
            ({props.count})    <NumberFormat value={props.total !== undefined ? parseFloat(props.total.toString()).toFixed(2) : "0.00"} displayType={'text'} thousandSeparator={true} prefix={'$'} />
               
        </Typography>
    </div>;
}

export default categorySummaryHeader;