import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { IPersonalCarMileage } from '../../../index';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

interface IPersonalCarMileageFormProps {
    personalCarMileage: IPersonalCarMileage;
    handlePersonalCarMileageChange: any;
    showPersonalCarMileage: boolean;
}
interface IPersonalCarMileageState {
    personalCarMileage: any;
}

export default class PersonalCarMileageForm extends React.Component<IPersonalCarMileageFormProps, IPersonalCarMileageState> {
    constructor(props: any) {
        super(props);

        this.state = {
             personalCarMileage: null
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e: any) {

        let personalCarMileage = this.state.personalCarMileage;

        this.setState({
            personalCarMileage: personalCarMileage
        });

        this.props.handlePersonalCarMileageChange(e);
    }
    render() {

        return <div style={{
            'display': this.props.showPersonalCarMileage ? 'inline-block' : 'none', 'marginTop': '20px', padding: '20px'
        }}>

            <Typography variant='body1' align='center' color='textPrimary' gutterBottom>
                * Please fill out the following information:
                </Typography>
            <br />
            <TextField        
                fullWidth
                required
                name='destination'
                onChange={(e: any) => this.handleChange(e)}
                value={this.props.personalCarMileage.destination || ''}
                label='Destination:'
            />
            <br /> <br />
            <FormControl fullWidth>
                <InputLabel htmlFor='amount' required>Mileage:</InputLabel>
                <Input
                    name='mileage'
                    required
                    inputProps={{
                        inputMode: 'numeric'
                    }}
                    id='amount'
                    type='number'                   
                    value={this.props.personalCarMileage.mileage ? this.props.personalCarMileage.mileage : 0}
                    onChange={(e: any) => this.handleChange(e)}
                />
                <Typography variant='caption'>Please round to the nearest whole number.</Typography>
            </FormControl>
           
        </div>;
    }
}
