import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    createStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

interface IHelpPanelProps {
    title: string;
    subTitle: string;
    gif: string;
}

export class HelpPanel extends React.Component<IHelpPanelProps, {}>{

    constructor(props?: (IHelpPanelProps)) {
        super(props as any);
    }

    render() {
        const { classes } = this.props as any;

        return <div>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color='textPrimary'>{this.props.title}</Typography>
                </AccordionSummary>
                <AccordionActions>
                    <Card className={classes.card}>
                        <CardContent>
                            <img className={classes.gif} src={`${this.props.gif}`} />
                            <br />
                            <Typography variant='body1'>{this.props.subTitle}</Typography>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>

                        </CardContent>
                    </Card>
                </AccordionActions>
            </Accordion>

        </div>
    }
}

const styles = (theme: Theme) => createStyles({
    gif: {
        width: '90%'
    },
    card: {
        width: '100%',
        backgroundColor: '#ccc'
    }
});

export default withStyles(styles)(HelpPanel);
