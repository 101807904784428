import {  IReceiptMetadata, ITrip } from '../../../components/index';
import moment from 'moment';
import { MISC_CATEGORY, BUSINESS_ENTERTAINMENT_CATEGORY, USD_CURRENCY_ID, START_END_DATE_WARNING, TRIP_OVER_21_DAYS_WARNING, SUBMITTED_BY_EMAIL_WARNING }
    from '../../../constants/constants';
import { ITripState } from '../../../store/trips/tripState';

export function isValidExpense(trip: ITripState): boolean {

    if (trip.selectedReceiptMetadata.categories) {

        for (let m of trip.selectedReceiptMetadata.categories) {

            if (m.name && m.name.toLowerCase() === MISC_CATEGORY.toLowerCase()) {

                if (!m.miscExpenseDto) {
                    if (trip.miscExpense) {
                        if (!trip.miscExpense.expenseItem
                            || !trip.miscExpense.vendor
                            || !trip.miscExpense.categoryId) {

                            return false;
                        } else {
                            return true;
                        }
                    }

                    return false;
                }
                else {
                    if (!m.miscExpenseDto.expenseItem || !m.miscExpenseDto.vendor || !m.miscExpenseDto.categoryId) {
                        return false;
                    }
                }
            }

            if (m.name && m.name.toLowerCase() === BUSINESS_ENTERTAINMENT_CATEGORY.toLowerCase()) {

                if (!m.businessEntertainmentDto) {
                    if (trip.businessEntertainmentExpense) {
                        if (!trip.businessEntertainmentExpense.personsEntertained
                            || !trip.businessEntertainmentExpense.company
                            || !trip.businessEntertainmentExpense.location
                            || !trip.businessEntertainmentExpense.purpose) {

                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                }
                else {

                    if (!m.businessEntertainmentDto.personsEntertained
                        || !m.businessEntertainmentDto.company
                        || !m.businessEntertainmentDto.location
                        || !m.businessEntertainmentDto.purpose) {

                        return false;
                    }
                }
            }
        }
    }

    return true;
}
export function isValidTrip(trip: ITrip, dispatch: any, currentUserEmail: string): boolean {

    let start = moment(trip.startDate);
    let end = moment(trip.endDate);

    if (end < start) {
        dispatch({
            type: 'SHOW_VALIDATION_ERROR',
            message: START_END_DATE_WARNING,
            showValidationError: true
        });

        return false;
    }
    if (start > end) {
        dispatch({
            type: 'SHOW_VALIDATION_ERROR',
            message: START_END_DATE_WARNING,
            showValidationError: true
        });

        return false;
    }

    let numberOfDays = Math.abs(start.diff(end, 'days', false)) + 1; // +1 to include first day.

    if (numberOfDays > 21) {
        dispatch({
            type: 'SHOW_VALIDATION_ERROR',
            message: TRIP_OVER_21_DAYS_WARNING,
            showValidationError: true
        });

        return false;
    }

    if (currentUserEmail && currentUserEmail.toLowerCase() === trip.submittedOnBehalfOfEmail && trip.submittedOnBehalfOfEmail.toLowerCase()) {
        dispatch({
            type: 'SHOW_VALIDATION_ERROR',
            message: SUBMITTED_BY_EMAIL_WARNING,
            showValidationError: true
        });

        return false;
    }

    return true;
}
export function isForeignCurrencyAmountValid(selectedReceiptMetadata: IReceiptMetadata): boolean {
    if (selectedReceiptMetadata.currencyId === USD_CURRENCY_ID) {
        return true;
    }

    // get the total of all foreign currency amounts for each category.   
    let expensedAmountTotalAllCategoriesFC: number = 0;
    for (let j = 0; j < selectedReceiptMetadata.categories.length; j++) {      
        if (selectedReceiptMetadata.categories[j] && selectedReceiptMetadata.categories[j].expensedAmountForeignCurrency) {
            expensedAmountTotalAllCategoriesFC += selectedReceiptMetadata.categories[j].expensedAmountForeignCurrency;
        }
    }

    //console.log(expensedAmountTotalAllCategoriesFC); // 0
    //console.log(selectedReceiptMetadata.amountTotalForeignCurrency); // 5.99

    // valid if the total for all foreign expenses equals the total input by the user.
    return expensedAmountTotalAllCategoriesFC == selectedReceiptMetadata.amountTotalForeignCurrency;
}
