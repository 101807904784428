import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import {
    GetOptionValue, GetOptionLabel, HandleAutocompleteStatus, GetAllUsers, GetCurrentUser, HandleCloseHelpFAQ, HandleCloseProfileForm,
    HandleCompanyNumberChange, HandleITCostCenterChange, HandleVendorNumberChange, HandleMessageClose, HandlePrimaryChange, HandleShowHelpFAQ, HandleShowProfileForm,
    SaveProfile, SaveProfileSuccess, ShowValidationError
} from './profileActions';
import { HandleTempPrimaryChange } from '../status/statusActions';
import { GetCurrentUserSuccess } from '../auth/authActions';
import * as authActions from '../auth/authReducer';
import { Api } from '../../api/Api';
import { IFilterSelect, IUser } from '../../components/index';
import { MISSING_ITCOST_CENTER, MISSING_PRIMARYEMAIL, MISSING_VENDOR_NUMBER, UPDATED_PROFILE_MESSAGE, USER_AS_APPROVER_WARNING } from '../../constants/constants';
import { AppThunkAction } from '../../store/index';

const api = new Api();

export interface IProfileState {
    showValidationError: boolean;
    primaryManagerEmail: string;
    primaryManagerName: string;
    message: string;
    messageProfile: string;
    users: any;
    primaries: IFilterSelect[];
    primaryManager: IFilterSelect;
    showMessage: boolean;
    showMessageProfile: boolean;
    companyNumber: number;
    vendorNumber: string;
    itCostCenter: string;
    showProfileForm: boolean;
    showHelpFAQ: boolean;
    autocompleteLoading: boolean;
    autocompleteOpen: boolean;
    optionValue: any;
    optionLabel: any;
}
export const emptySelectalue: IFilterSelect = { label: '', value: '' };

export const unloadedState: IProfileState = {
    showValidationError: false,
    autocompleteLoading: false,
    optionValue: '',
    optionLabel: '',
    primaryManagerEmail: '',
    primaryManagerName: '',
    primaries: [emptySelectalue],
    users: [emptySelectalue],
    primaryManager: emptySelectalue,
    companyNumber: 0,
    vendorNumber: '',
    itCostCenter: '',
    message: '',
    messageProfile: '',
    showMessage: false,
    showProfileForm: false,
    showMessageProfile: false,
    showHelpFAQ: false,
    autocompleteOpen: false
}

type KnownAction = GetOptionLabel | GetOptionValue | HandleAutocompleteStatus | GetCurrentUserSuccess
    | HandleTempPrimaryChange | HandleCloseHelpFAQ | HandleShowHelpFAQ | HandleShowProfileForm | HandleCloseProfileForm
    | HandlePrimaryChange | HandleMessageClose | HandleVendorNumberChange | HandleITCostCenterChange
    | HandleCompanyNumberChange | SaveProfile | SaveProfileSuccess
    | GetCurrentUser | GetAllUsers | ShowValidationError;

export const actionCreators = {

    handleMessageProfileClose: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_MESSAGE_CLOSE', showMessageProfile: false, showValidationError: false });
    },
    handleCompanyNumberChange: (companyNumber: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_COMPANYNUMBER_CHANGE', companyNumber: companyNumber });
    },
    handleITCostCenterChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_ITCOSTCENTER_CHANGE', itCostCenter: e.target.value });
    },
    handleVendorNumberChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_VENDORNUMBER_CHANGE', vendorNumber: e.target.value });
    },
    handleCloseProfileForm: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_CLOSE_PROFILE_FORM', showProfileForm: false });
    },
    getCurrentUserForProfile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let currentUser = getState().auth.currentUser;

        dispatch({
            type: 'GET_CURRENT_USER',
            primaryManagerEmail: currentUser.primaryManagerEmail,
            companyNumber: currentUser.companyNumber,
            vendorNumber: currentUser.vendorNumber,
            itCostCenter: currentUser.itCostCenter,
            showMessage: false,
            showValidationError: false
        });
    }
    ,
    saveProfile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let profile = getState().profile;
        let currentUser = getState().auth.currentUser;

        // validation: not blank, doesn't equal current user's email.
        if (!profile.primaryManagerEmail) {
            dispatch({
                type: 'SHOW_VALIDATION_ERROR',
                message: MISSING_PRIMARYEMAIL, showValidationError: true
            });
            return;
        }

        if (profile.primaryManagerEmail === currentUser.email) {
            dispatch({
                type: 'SHOW_VALIDATION_ERROR',
                message: USER_AS_APPROVER_WARNING, showValidationError: true
            });
            return;
        }

        if (!profile.itCostCenter) {
            dispatch({
                type: 'SHOW_VALIDATION_ERROR',
                message: MISSING_ITCOST_CENTER, showValidationError: true
            });
            return;
        }
        if (!profile.vendorNumber) {
            dispatch({
                type: 'SHOW_VALIDATION_ERROR',
                message: MISSING_VENDOR_NUMBER, showValidationError: true
            });
            return;
        }

        let managerEmail = profile.primaryManagerEmail && profile.primaryManagerEmail.toLowerCase();

        let fetchTask = api.saveProfile({
            companyNumber: profile.companyNumber,
            id: currentUser.id,
            azureId: currentUser.azureId,
            primaryManagerEmail: managerEmail,
            vendorNumber: profile.vendorNumber,
            itCostCenter: profile.itCostCenter
        })
            .then(() => {

                dispatch({
                    type: 'SAVE_PROFILE_SUCCESS',
                    messageProfile: UPDATED_PROFILE_MESSAGE,

                    showMessageProfile: true,
                    showValidationError: false,
                    showProfileForm: true
                });

                setTimeout(() => {
                    dispatch({
                        type: 'SAVE_PROFILE_SUCCESS',
                        messageProfile: UPDATED_PROFILE_MESSAGE,

                        showMessageProfile: false,
                        showValidationError: false,
                        showProfileForm: false
                    });
                }, 1000);

                (authActions.actionCreators.getCurrentUser())(dispatch as any, getState);
            });

        addTask(fetchTask);

    },

    handleShowHelpFAQ: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_SHOW_HELP_FAQ', showHelpFAQ: true });
    },
    handleCloseHelpFAQ: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HANDLE_CLOSE_HELP_FAQ', showHelpFAQ: false });
    },
    handleShowProfileForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'GET_CURRENT_USER',
            primaryManagerEmail: getState().auth.currentUser.primaryManagerEmail,
            companyNumber: getState().auth.currentUser.companyNumber,
            vendorNumber: getState().auth.currentUser.vendorNumber,
            itCostCenter: getState().auth.currentUser.itCostCenter,
            showMessage: false,
            showValidationError: false
        });
        dispatch({ type: 'HANDLE_SHOW_PROFILE_FORM', showProfileForm: true, showValidationError: false, message: '' });
    },


    handlePrimaryChange: (primary: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'HANDLE_PRIMARY_CHANGE',
            primaryManager: primary,
            primaryManagerEmail: primary
        });
    },
};

export const reducer: Reducer<IProfileState> = (state: IProfileState | undefined, action: KnownAction): IProfileState => {

    switch (action.type) {
        case 'GET_OPTION_VALUE': {
            return {
                ...state,
                optionValue: action.optionValue
            }
        }
        case 'GET_OPTION_LABEL': {
            return {
                ...state,
                optionLabel: action.optionLabel
            }
        }
        case 'HANDLE_AUTOCOMPLETE_STATUS': {
            return {
                ...state,
                autocompleteOpen: action.autocompleteOpen
            }
        }
        case 'HANDLE_SHOW_HELP_FAQ': {
            return {
                ...state,
                showHelpFAQ: action.showHelpFAQ
            }
        }
        case 'HANDLE_CLOSE_HELP_FAQ': {
            return {
                ...state,
                showHelpFAQ: action.showHelpFAQ
            }
        }
        case 'HANDLE_SHOW_PROFILE_FORM': {
            return {
                ...state,
                showProfileForm: action.showProfileForm,
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'HANDLE_CLOSE_PROFILE_FORM': {
            return {
                ...state,
                showProfileForm: action.showProfileForm,
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'SHOW_VALIDATION_ERROR': {
            return {
                ...state,
                showValidationError: action.showValidationError,
                message: action.message
            }
        }
        case 'GET_ALL_USERS': {
            return {
                ...state,
                users: action.users
            }
        }
        case 'GET_CURRENT_USER': {
            return {
                ...state,
                primaryManagerEmail: action.primaryManagerEmail,
                companyNumber: action.companyNumber,
                vendorNumber: action.vendorNumber,
                itCostCenter: action.itCostCenter,
                showValidationError: action.showValidationError,
                showMessage: action.showMessage
            }
        }
        case 'HANDLE_PRIMARY_CHANGE':
            return {
                ...state,
                primaryManagerEmail: action.primaryManagerEmail,
                primaryManagerName: action.primaryManagerName,
                primaryManager: state.primaryManager,
                primaries: action.primaries
            }
        case 'HANDLE_MESSAGE_CLOSE':
            return {
                ...state,
                showMessageProfile: action.showMessageProfile
            }
        case 'HANDLE_COMPANYNUMBER_CHANGE':
            return {
                ...state,
                companyNumber: action.companyNumber
            }
        case 'HANDLE_ITCOSTCENTER_CHANGE':
            return {
                ...state,
                itCostCenter: action.itCostCenter
            }
        case 'HANDLE_VENDORNUMBER_CHANGE':
            return {
                ...state,
                vendorNumber: action.vendorNumber
            }

        case 'SAVE_PROFILE': {
            return {
                ...state,
                showValidationError: action.showValidationError,
                showProfileForm: action.showProfileForm
            }
        }
        case 'SAVE_PROFILE_SUCCESS':
            return {
                ...state,
                messageProfile: action.messageProfile,
                showMessageProfile: action.showMessageProfile || state.showMessageProfile,
                showValidationError: action.showValidationError || state.showValidationError,
                showProfileForm: action.showProfileForm || state.showProfileForm
            }
        default:
            break;
    }

    return state || unloadedState;
}