import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckBoxIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../store/index';
import * as ProfileStore from '../store/profile/profileReducer';
import { ITrip } from './index';

export type ISideMenuProps =
    ProfileStore.IProfileState
    & ISideMenuLocalProps
    & typeof ProfileStore.actionCreators;

interface ISideMenuLocalProps {
    handleNewTrip: any;
    handleNewTravelAdvance: any;
    handleEditTrip: any;
    handleLogout: any;
    selectedTrip: ITrip;
    currentUserHasProfile: boolean;
    isAuditorView: boolean;
}

class SideMenu extends React.Component<ISideMenuProps, {}> {

    constructor(props: (ISideMenuProps)) {
        super(props as any);
    }

    render() {

        const { classes } = this.props as any;

        return <div>
            <div className={classes.list}>
                <List style={{
                    'display':
                        !this.props.currentUserHasProfile ? 'none' : 'inherit'
                }}>
                    <ListItem button>
                        <HomeIcon className={classes.icon} />
                        <ListItemText primary={<NavLink style={{ color: '#000' }} to='/' className={classes.noDecorationLink}> Home </NavLink>} />
                    </ListItem>
                    <br />
                    <Divider />
                    <div style={{
                        display: this.props.currentUserHasProfile ? 'inherit' : 'none'
                    }}>
                        <ListItem button onClick={this.props.handleNewTrip}>
                            <AddBoxIcon className={classes.icon} />
                            <ListItemText primary='Add New Trip' />
                        </ListItem>
                        <ListItem style={{
                            'display':
                                this.props.selectedTrip.id > 0
                                    && (this.props.selectedTrip.approvalStatusName !== 'Draft' && !this.props.isAuditorView)
                                    ? 'inherit' : 'none'
                        }}>
                            <ListItemText primary='Edit only available in Draft mode.' />
                        </ListItem>
                        <ListItem style={{
                            'visibility':
                                this.props.selectedTrip.id > 0
                                    && (this.props.selectedTrip.approvalStatusName === 'Draft' || this.props.isAuditorView)
                                    ? 'visible' : 'hidden'
                        }} button onClick={this.props.handleEditTrip}>
                            <EditIcon className={classes.icon} />
                            <ListItemText primary='Edit This Trip' />
                        </ListItem>
                    </div>
                </List>
                <Divider />

                <List>
                    <div style={{
                        'display':
                            !this.props.currentUserHasProfile ? 'none' : 'inherit'
                    }}>
                        <ListItem button>
                            <CheckBoxIcon className={classes.icon} />
                            <ListItemText primary={<NavLink style={{ color: '#000' }} to='/checkrequest' className={classes.noDecorationLink}> Check Requests </NavLink>} />
                        </ListItem>
                    </div>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={this.props.handleShowProfileForm}>
                        <SettingsIcon className={classes.icon} />
                        <ListItemText primary='Profile' />
                    </ListItem>
                    <ListItem button onClick={this.props.handleShowHelpFAQ}>
                        <InfoIcon className={classes.icon} />
                        <ListItemText primary='Help / FAQ' />
                    </ListItem>
                    <Divider />
                    <br />
                </List>
                <List>
                    <ListItem id='logout' button onClick={this.props.handleLogout}>
                        <AccountCircleIcon className={classes.icon} />
                        <ListItemText primary='Logout' />
                    </ListItem>
                </List>
            </div>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    icon: {
        marginRight: 10
    },
    list: {
        width: 250,
    },
    noDecorationLink: {
        textDecoration: 'none',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
});

export default compose(connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
), withStyles(styles))(SideMenu) as any;