import * as React from 'react';
import Typography from '@material-ui/core/Typography';

interface ICategoryNotificationProps {
    notification: string;
    show: boolean;
}

const CategoryNotification = (props: ICategoryNotificationProps) => {
    return <div style={{
        'display': props.show ? 'inline-block' : 'none', 'marginTop': '25px', padding: '15px'
    }}>
        <Typography color='secondary' variant='caption'>{props.notification}</Typography>
    </div>;
}

export default CategoryNotification;
