import * as React from 'react';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Typography } from '@material-ui/core';

interface IAddExpenseButtonProps {
    showNewReceiptForm: any;
}

class AddExpenseButton extends React.Component<IAddExpenseButtonProps, {}>{

    constructor(props?: (IAddExpenseButtonProps)) {
        super(props as any);
    }

    render() {

        return <div>

            <Button variant='contained' color='secondary'
                onClick={(e: any) => this.props.showNewReceiptForm()}>
                <Typography variant='body2' style={{ 'color': '#fff' }}>Add Expense &nbsp;</Typography>
                <AddCircleIcon />
            </Button>

        </div>;
    };
}

export default AddExpenseButton;

