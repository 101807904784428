import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import MDSpinner from 'react-md-spinner';
import Viewer from 'react-viewer';
import { Api } from '../../../api/Api';
import { BLOB_URL } from '../../../constants/constants';
import Button from '@material-ui/core/Button';
import { IReceiptMetadata } from '../..';

const api = new Api();

interface IThumbnailState {
    isOpen: boolean;
    lightboxImages: any;
    thumbnailLink: string;
}

interface IThumbnailProps extends WithStyles<typeof styles> {
    metadata: IReceiptMetadata;
    showLightbox: boolean;
    loadingImageKey: string;
    isThumbnailLoadComplete: boolean;
    isLoadingPDF: boolean;
    selectedReceiptMetadata: IReceiptMetadata;
}

class Thumbnail extends React.Component<IThumbnailProps, IThumbnailState> {

    constructor(props?: (IThumbnailProps)) {
        super(props as any);

        this.state = {
            isOpen: false,
            lightboxImages: '',
            thumbnailLink: ''
        }

        this.openLightbox = this.openLightbox.bind(this);
        this.setThumbnailLinkWithSASToken = this.setThumbnailLinkWithSASToken.bind(this);
    }

    async componentDidMount() {
        if (this.props.metadata.thumbnailLink) {
            await this.setThumbnailLinkWithSASToken();
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.metadata.thumbnailLink && (this.props.metadata.thumbnailLink !== prevProps.metadata.thumbnailLink)) {
            await this.setThumbnailLinkWithSASToken();
        }
    }

    async setThumbnailLinkWithSASToken() {
        const thumbnailName = this.props.metadata.thumbnailLink.replace(BLOB_URL, '');

        await api.getBlobSasToken(thumbnailName).then((sasToken) => {
            const sasTokenLink = this.props.metadata.thumbnailLink + sasToken;            
            this.setState({ thumbnailLink: sasTokenLink });
        });
    }

    openLightbox(link: string) {

        this.setState({
            lightboxImages: link
        });

        this.setState({
            isOpen: true
        });
    }

    render() {
        const { classes } = this.props as any;
        const { lightboxImages } = this.state;
        const viewer = <Viewer
            noImgDetails={true}
            zoomSpeed={0.02}
            drag={false}
            scalable={false}
            visible={this.state.isOpen}
            noNavbar={true}
            changeable={false}
            onClose={() => { this.setState({ isOpen: false }); }}
            onMaskClick={() => { this.setState({ isOpen: false }); }}
            images={[{ src: lightboxImages, alt: '' }]}
        />;
        return <div>

            <div style={{
                'paddingTop': '20px', 'textAlign': 'center'
            }}>
                {/* No Image Message */}
                <div style={{ 'display': !this.props.metadata.key && !this.props.metadata.pdfKey ? 'inline-block' : 'none' }}>
                    <Typography className={classes.faqItem} variant='body1' align='center' color='textSecondary' paragraph>
                        No image uploaded.
                    </Typography>
                </div>

                {/* spinner */}
                <div style={{
                    'display': !this.props.isThumbnailLoadComplete
                        && this.props.metadata.receiptMetadataId === this.props.selectedReceiptMetadata.receiptMetadataId ? 'block' : 'none'
                }}>
                    <MDSpinner />
                </div> 

                {/* thumbnail - direct from blob storage */}
                <Button className={classes.thumbnailImage} style={{
                    'display': this.props.isThumbnailLoadComplete
                        && this.props.metadata.thumbnailLink
                        && this.props.loadingImageKey === this.props.metadata.key ? 'inline-block' : 'none'
                }} onClick={(e: any) => this.openLightbox(this.state.thumbnailLink)}>
                    <img className={classes.thumbnailImage} src={this.state.thumbnailLink} alt='' />
                </Button>

                {/* thumbnail - after uploading new image */}
                <Button className={classes.thumbnailImage} style={{
                    'display': this.props.loadingImageKey !== this.props.metadata.key
                        && this.props.metadata.thumbnailLink ? 'inline-block' : 'none'
                }} onClick={(e: any) => this.openLightbox(this.state.thumbnailLink)}>
                   <img className={classes.thumbnailImage} src={this.state.thumbnailLink} alt='' />
                </Button>

                {/* spinner pdf */}
                <div style={{
                    'display': this.props.isLoadingPDF ? 'block' : 'none'
                }}>
                    <MDSpinner />
                </div>

            </div>

            {viewer}

        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    cardMedia: {
        width: 160,
    },
    thumbnail:
    {
        border: '2px solid #000'
    },
    thumbnailImage: {
        objectFit: 'cover',  // or 'cover'
        width: '100%',
        height: '100%',
    },
});

export default withStyles(styles)(Thumbnail);
