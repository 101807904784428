import { Icon } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ICategory, IReceiptMetadata } from '../../index';
import { default as NumberFormat } from 'react-number-format';

export interface ICategoriesProps extends WithStyles<typeof styles> {
    metadata: IReceiptMetadata;
    miscCategories: ICategory[];
    isAuditor: boolean;
}

export class Categories extends React.Component<ICategoriesProps, {}> {

    constructor(props?: (ICategoriesProps)) {
        super(props as any);
    }

    render() {
        const { classes } = this.props;


        return <div>
            <List className={classes.flexContainer}>
                {this.props.metadata.categories.map((c: ICategory, index: number) =>
                    <div key={c.categoryId} >
                        {/* Category  */}
                        <ListItem className={classes.root}>
                            <Chip className={classes.chip} avatar={
                                <Avatar>
                                    <Icon className={classes.avatar}>{c.materialIconName}</Icon>
                                </Avatar>
                            } label={c.name} />
                        </ListItem>

                        {/* Amount  */}
                        <ListItem className={classes.root}>
                            <Typography variant='body2' align='center' style={{ 'marginLeft': '30px' }}>
                                <NumberFormat value={c.expensedAmount ? parseFloat(c.expensedAmount.toString()).toFixed(2) : '0.00'} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </Typography>
                            <br />
                            <Typography variant='body1' color='textSecondary' style={{ 'visibility': c.expensedAmountForeignCurrency ? 'visible' : 'hidden' }}>
                                <span style={{ 'marginLeft': '30px' }}>
                                    {this.props.metadata.currencyIcon} {c.expensedAmountForeignCurrency ? parseFloat(c.expensedAmountForeignCurrency.toString()).toFixed(2) : '0.00'}</span>
                            </Typography>
                        </ListItem>

                        {/* Flattened Personal Car Mileage  */}
                        <ListItem className={classes.root} style={{ 'display': c.personalCarMileageDto !== undefined && c.personalCarMileageDto && c.personalCarMileageDto.personalCarMileageId > 0 ? 'block' : 'none' }}>
                            <br />
                            <div>
                                <br />
                                <Typography variant='body1'>Destination: {c.personalCarMileageDto && c.personalCarMileageDto.destination}</Typography>
                                <br />
                                <Typography variant='body1'>Mileage: {c.personalCarMileageDto && c.personalCarMileageDto.mileage}</Typography> <br />
                            </div>
                            <br />
                        </ListItem>

                        {/* Flattened Misc Expense  */}
                        <ListItem className={classes.root} style={{ 'display': c.miscExpenseDto !== undefined && c.miscExpenseDto.miscExpenseId > 0 ? 'block' : 'none' }}>
                            <br /><div>
                                <Typography variant='body1'>Expense Item: {c.miscExpenseDto.expenseItem}</Typography>
                                <br />
                                <Typography variant='body1'>Vendor: {c.miscExpenseDto.vendor}</Typography>
                                <br />
                                <Typography variant='body1'>Misc. Category:&nbsp;&nbsp;{this.props.miscCategories.filter(x => x.categoryId === c.miscExpenseDto.categoryId)[0] && this.props.miscCategories.filter(x => x.categoryId === c.miscExpenseDto.categoryId)[0].name}
                                </Typography>
                                <br />
                                <div style={{
                                    'display': this.props.isAuditor && c.miscExpenseDto.name
                                        === 'Other Admin Expense' ? 'block' : 'none'
                                }}>
                                    {c.miscExpenseDto.account && <Typography variant='body1'>Account: {c.miscExpenseDto.account}</Typography>}
                                    {!c.miscExpenseDto.account && <Typography variant='body1' style={{ color: 'red' }}>Account: (please enter manually)</Typography>}
                                </div>

                            </div>
                            <br />
                        </ListItem>

                        {/* Flattened Business Entertainment  */}
                        <ListItem className={classes.root} style={{ 'display': c.businessEntertainmentDto !== undefined && c.businessEntertainmentDto.businessEntertainmentId > 0 ? 'block' : 'none' }}>
                            <br /> <div>
                                <Typography variant='body1'>Persons Entertained: {c.businessEntertainmentDto.personsEntertained}</Typography>
                                <br />
                                <Typography variant='body1'>Company: {c.businessEntertainmentDto.company}</Typography>
                                <br />
                                <Typography variant='body1'>Location: {c.businessEntertainmentDto.location}</Typography>
                                <br />
                                <Typography variant='body1'>Purpose: {c.businessEntertainmentDto.purpose}</Typography>
                                <br />
                                <Typography variant='body1'>Is Deductible: {c.businessEntertainmentDto.isDeductible ? 'Yes' : 'No'}</Typography>
                            </div>
                        </ListItem>
                    </div>
                )}
            </List>
        </div>;
    }
}
const styles = (theme: Theme) => createStyles({
    flexContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        color: 'default',
        width: '25px',
        height: '25px'
    },
    chipLabel: {
        verticalAlign: 'text-bottom'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0
    },
    chip: {
        border: '1px solid #ccc',
        backgroundColor: '#fff',     
        fontSize: '12px'
    },
});

export default withStyles(styles)(Categories as any);