import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../store/index';
import * as AuthStore from '../store/auth/authReducer';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, CircularProgress } from '@material-ui/core';
import HelpOutlineSharp from '@material-ui/icons/HelpOutlineTwoTone';
import moment from 'moment';
import { ITrip } from './index';
import { Box } from '@material-ui/core';
import { DO_NOT_USE_PLANE_CATEGORY } from '../constants/constants';

interface IFooterLocalProps extends WithStyles<typeof styles> {
    handleShowHelpFAQ: any;
    selectedTrip: ITrip;
}

type IFooterProps =
    AuthStore.IAuthState
    & IFooterLocalProps
    & typeof AuthStore.actionCreators;

type IFooterState = {
    isLoggingIn: boolean;
}

class Footer extends React.Component<IFooterProps, IFooterState>{

    constructor(props?: (IFooterProps)) {
        super(props as any);

        this.state = {
            isLoggingIn: false
        }
    }

    async componentDidMount() {
        // if there is 'code' in the URL then we are logging in.
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');

        if (code) {
            this.setState({ isLoggingIn: true });
        }
    }
    render() {
        const { classes } = this.props as any;

        return <Grid container>
            <Grid item sm={4} xs={12}></Grid>
            <Grid item sm={4} xs={12} className={classes.border}>
                <footer className={classes.footer}>
                    <Typography id='loginButton' className={classes.pad} variant='body1' align='center' color='textSecondary' component='div'>
                        <Button style={{ display: this.props.currentUserHasProfile || (this.props.isLoggingIn || this.state.isLoggingIn) ? 'none' : 'inline-block' }} variant='contained' color='primary'
                            onClick={this.props.redirectToAzureAD}>login</Button>
                        <CircularProgress style={{ display: this.props.isLoggingIn || (this.state.isLoggingIn && !this.props.currentUser) ? 'inline-block' : 'none' }} size={60} />
                    </Typography>
                    <Typography variant='h6' align='center' gutterBottom>
                        Travel Expense App
                    </Typography>
                    <Typography variant='h6' align='center' gutterBottom>
                        SCHOTT North America, Inc.
                    </Typography>
                    <Typography variant='body1' align='center' color='textSecondary' component='p' gutterBottom>
                        {DO_NOT_USE_PLANE_CATEGORY}
                    </Typography>
                    <Typography variant='subtitle1' align='center' color='textSecondary' component='p'>
                        {/*{moment(new Date()).format('YYYY')}*/}
                        {/*<br />*/}
                        <Typography align='center' variant='caption'>v.2.1.7</Typography>
                    </Typography>
                    <br />
                </footer>
            </Grid>
            <Grid item sm={4} xs={12}></Grid>
        </Grid>
    }
}

const styles = (theme: Theme) => createStyles({
    pad: {
        paddingTop: '10px',
        marginBottom: 50
    },
    border: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
        border: '1px solid #eee'

    },
    footer: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    link: {
        color: theme.palette.primary.main,
    },
    chip: {
        marginTop: 50
    },
    helpIcon: {
        display: 'flex',
        alignItems: 'center'
    }
});

export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), withStyles(styles))(Footer) as any;
