import {
    ITrip, IReceiptMetadata
} from '../components/index';
import { MSGRAPH_USERS_URL } from '../constants/constants';

const GET: string = 'GET';
const POST: string = 'POST';
const PUT: string = 'PUT';
const DELETE: string = 'DELETE';

const decodeJWT = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(atob(base64));
    } catch (err) {
       // console.error('Failed to decode JWT', err);
        return null;
    }
};

export function hasValidSession() {
    const sessionToken = window.sessionStorage.getItem('session_token');
    return sessionToken && sessionToken !== 'undefined';
}

export function getSessionInfo() {
    return decodeJWT(window.localStorage.getItem('id_token')) || '';
}
export function getHashValue(key: any) {
    let matches = window.location.hash.match(new RegExp(key + '=([^&]*)'));
    return matches ? matches[1] : null;
}

function isTokenExpired(token) {
    const decodedToken = decodeJWT(token);

    if (!decodedToken) {
        //console.error('Invalid token');
        return true; // If the token is invalid, consider it as expired
    }

    const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds since the Epoch

    // Check if the current time is greater than the expiration time
    return currentTimestamp >= decodedToken.exp;
}

export class Api {
    getHeaders() {
        return {
            credentials: 'same-origin',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Pragma: 'no-cache',
            'Authorization': 'Bearer ' + sessionStorage.getItem('expense_token')
        };
    }
    async getAuthenticationToken(code, codeChallenge) {
        const response = await fetch('api/auth/code',
            {
                method: POST,
                headers: this.getHeaders(),
                body: JSON.stringify({ code: code, codeVerifier: codeChallenge })
            });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const data = await response.json();
            return data;
        }
    }
    // generate SAS token for blob storage
    async getBlobSasToken(blobName: string) {
        if (blobName === undefined || blobName === null || blobName === '') {
            return '';
        }
  
        // remove any starting forward slash from blobName
        if (blobName.charAt(0) === '/') {
            blobName = blobName.substr(1);
        }

        const response = await fetch(`api/blob/sas-token?blobName=${blobName}`,
            {
                method: GET,
                headers: this.getHeaders()
            });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const data = await response.json();          
            return data;
        }
    }

    async getUser(searchTerm: string) {
        const apiUrl = 'https://graph.microsoft.com/v1.0/users';
        const query = `?$filter=startswith(displayName, '${searchTerm}')&$top=1&$select=displayName,mail,employeeId,extension_823da9332d024198b63e63b45139c9b7_ITCostCenter`;

        // Make the API call to get Azure AD users
        return await fetch(`${apiUrl}${query}`, {
            headers: {
                credentials: 'same-origin',
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Pragma: 'no-cache',
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            }
        });
    }
    async getAutocompleteUser(searchTerm: string) {
       
        const query = `?$filter=startswith(displayName, '${searchTerm}')&$top=25&$select=displayName,mail,employeeId,extension_823da9332d024198b63e63b45139c9b7_ITCostCenter`;

        let accessToken = sessionStorage.getItem('session_token');
        if (isTokenExpired(accessToken)) {
         
            let currentUser = getSessionInfo();

            // get new token - silent refresh shhh.
            const response = await fetch(`api/auth/refreshToken?name=${currentUser.name}&email=${currentUser.email}&code=${accessToken}`);           

            // Check if the response is ok
            if (!response.ok) {
                // If the response is unauthorized, it means the user is not logged in anymore. Redirect to the home page.
                localStorage.removeItem('id_token');
                window.location.href = "/";
                return;
            }
           
            const responseBody = await response.text();

            try {
                // Try to parse the string as JSON
                const data = JSON.parse(responseBody);
               
                accessToken = data.value;
                sessionStorage.setItem('session_token', accessToken);
            } catch (e) {                
                //console.error('Failed to parse JSON:', responseBody);               
                // If it's a JWT, just store it directly
                sessionStorage.setItem('session_token', responseBody);
            }
        }

        // Make the API call to get Azure AD users
        return fetch(`${MSGRAPH_USERS_URL}${query}`, {
            headers: {
                credentials: 'same-origin',
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Pragma: 'no-cache',
                'Authorization': 'Bearer ' + accessToken
            }
        });
    }

    async getCurrentUser(name: string, email: string) {
        const response = await fetch(`api/auth?name=${name}&email=${email}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const data = await response.json();          
            return data;
        }
    }
    async getTrip(tripId: number) {
        return fetch(`api/trip/${tripId}`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }

    getTrips(userId: string) {
        return fetch(`api/user/${userId}/trips`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getTripsForManager(email: string) {
        return fetch(`api/user/manager/${email}`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getTripsForAuditor() {
        return fetch(`api/user/auditor`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getTripByReceiptMetadataId(receiptMetadataId: number) {
        return fetch(`api/trip/metadata/${receiptMetadataId}`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    recallReport(workflowDto: any) {
        return fetch('api/workflow/recall',
            {
                method: POST,
                body: JSON.stringify(workflowDto),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    async routeForward(workflowDto: any) {
        return fetch('api/workflow',
            {
                method: POST,
                body: JSON.stringify(workflowDto),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    saveProfile(profile: any) {
        return fetch('api/user/profile',
            {
                method: PUT,
                body: JSON.stringify(profile),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    addMiscExpense(miscExpense: any) {
        return fetch('api/miscexpense',
            {
                method: POST,
                body: JSON.stringify(miscExpense),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    addBusinessEntertainmentExpense(businessEntertainmentExpense: any) {
        return fetch('api/businessentertainment',
            {
                method: POST,
                body: JSON.stringify(businessEntertainmentExpense),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    addPersonalCarMileage(personalCarMileage: any) {
        return fetch('api/personalcarmileage',
            {
                method: POST,
                body: JSON.stringify(personalCarMileage),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getMileageRate(endDate: any) {
        return fetch(`api/mileagerate?endDate=${endDate}`,
            {

                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getTripDailyTotalsSummary(id: number) {
        return fetch(`api/summary/${id}/daily-totals`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getTripCategorySummary(id: number) {
        return fetch(`api/summary/${id}/categories`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getCurrencies() {
        return fetch(`api/currency`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getCategories() {
        return fetch(`api/category`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getMiscExpenseCategories() {
        return fetch(`api/category/misc`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getPdfByTripName(tripName: string) {
        return fetch(`api/export/by/${tripName}`,
            {
                method: GET,
                headers: this.getHeaders()
            });
    }
    async getPdf(tripId: number) {
        return await fetch(`api/export/${tripId}`,
            {
                method: GET,
                headers: this.getHeaders()
            });
    }
    saveReceiptMetadata(receipt: IReceiptMetadata) {
        return fetch('api/receiptmetadata',
            {
                method: POST,
                body: JSON.stringify(receipt),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateImage(metadata: any) {
        return fetch('api/receiptmetadata/image',
            {
                method: PUT,
                body: JSON.stringify(metadata),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updatePdf(metadata: any) {
        return fetch('api/receiptmetadata/pdf',
            {
                method: PUT,
                body: JSON.stringify(metadata),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateCheckRequestPdf(metadata: any) {
        return fetch('api/checkrequest/pdf',
            {
                method: PUT,
                body: JSON.stringify(metadata),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateReceiptCategory(receipt: any) {
        return fetch('api/receiptcategory',
            {
                method: PUT,
                body: JSON.stringify(receipt),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateReceiptMetadata(receipt: IReceiptMetadata) {
        return fetch('api/receiptmetadata',
            {
                method: PUT,
                body: JSON.stringify(receipt),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    deleteReceiptMetadata(id: number) {
        return fetch(`api/receiptmetadata/${id}`,
            {
                method: DELETE,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    addReceiptCategory(receiptCategory: any) {
        return fetch(`api/receiptcategory`,
            {
                method: POST,
                body: JSON.stringify(receiptCategory),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    deleteReceiptCategory(id: number) {
        return fetch(`api/receiptcategory/${id}`,
            {
                method: DELETE,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateTrip(trip: ITrip) {
        return fetch('api/trip',
            {
                method: PUT,
                body: JSON.stringify(trip),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    saveTrip(trip: any) {
        return fetch('api/trip',
            {
                method: POST,
                body: JSON.stringify(trip),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    deleteTrip(id: number) {
        return fetch(`api/trip/${id}`,
            {
                method: DELETE,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getCheckRequests(requestedByEmail: string) {
        return fetch(`api/checkrequest/${requestedByEmail}`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getCheckRequestsToApprove(approvedByEmail: string) {
        return fetch(`api/checkrequest/approvals/${approvedByEmail}`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    getCheckRequestApproverList() {
        return fetch(`api/checkrequest/approver-list`,
            {
                method: GET,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    createCheckRequest(checkRequestDto: any) {
        return fetch('api/checkrequest',
            {
                method: POST,
                body: JSON.stringify(checkRequestDto),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    updateCheckRequest(checkRequest: any) {

        return fetch('api/checkrequest',
            {
                method: PUT,
                body: JSON.stringify(checkRequest),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    sendCheckRequestToApprover(checkRequest: any) {
        return fetch('api/checkrequest/send-to-approver',
            {
                method: PUT,
                body: JSON.stringify(checkRequest),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    sendCheckRequestToController(checkRequest: any) {
        return fetch('api/checkrequest/send-to-controller',
            {
                method: PUT,
                body: JSON.stringify(checkRequest),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    sendCheckRequestToPayables(checkRequest: any) {
        return fetch('api/checkrequest/send-to-payables',
            {
                method: PUT,
                body: JSON.stringify(checkRequest),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    denyCheckRequest(checkRequest: any) {
        return fetch('api/checkrequest/deny',
            {
                method: PUT,
                body: JSON.stringify(checkRequest),
                headers: this.getHeaders()
            }).then(res => res.json());
    }
    deleteCheckRequest(id: number) {
        return fetch(`api/checkrequest/${id}`,
            {
                method: DELETE,
                headers: this.getHeaders()
            }).then(res => res.json());
    }
}