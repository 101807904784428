import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { ICheckRequest } from "../index";

interface ICheckRequestDenyDialogProps {
    handleDenyClickClose: any;
    handleDenyCheckRequest: any;
    handleDenyClickOpen: any;
    handleCheckRequestFormValueChange: any;
    checkRequest: ICheckRequest;
    isDenyOpen: boolean;
}

export class CheckRequestDenyDialog extends React.Component<ICheckRequestDenyDialogProps, {}>{

    constructor(props?: (ICheckRequestDenyDialogProps)) {
        super(props as any);            
    }
  
    render() {
        const { classes } = this.props as any;

        return <div>
            {/* Dialog for denying check requests */}
            <Dialog open={this.props.isDenyOpen} onClose={this.props.handleDenyClickClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Deny this Check Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a reason for denying the check request. This will be sent in an email to the requested by user.
                            </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="* Reason"
                        value={this.props.checkRequest.denyComments || ""}
                        onChange={(e: any) => this.props.handleCheckRequestFormValueChange(e, "denyComments")}
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleDenyClickClose} color="primary">
                        Cancel
          </Button>
                    <Button onClick={this.props.handleDenyCheckRequest} color="secondary">
                        Deny
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    }
}

const styles = (theme: Theme) => createStyles({
    appBar: {
        backgroundColor: theme.palette.primary.main
    },

});

export default withStyles(styles)(CheckRequestDenyDialog);
