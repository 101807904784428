import { Api } from '../../api/Api';

const api = new Api();
const { BlobServiceClient } = require('@azure/storage-blob');
const accountName = 'expenseappreceipts';
const containerName = 'docs';

async function getBlobSasToken(blobName) {
    if (!blobName) {
        return false;
    }
  
    return api.getBlobSasToken(blobName)
        .then((sasToken: any) => {
            return sasToken;
        });
}

export async function uploadBlob(blobName, file) {
    try {
        const sasToken = await getBlobSasToken(blobName);
        const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        let uploadResponse;
        if (blobName.includes('.pdf')) {
            const blobOptions = { blobHTTPHeaders: { blobContentType: 'application/pdf' } };
            uploadResponse = await blockBlobClient.upload(file, file.size, blobOptions);
        } else {
            uploadResponse = await blockBlobClient.upload(file, file.size);
        }
        //console.log('Blob uploaded successfully', uploadResponse);
        return sasToken;
    } catch (error) {
        //console.error('Error uploading blob:', error);
        throw error; // Re-throw the error to be handled by the caller.
    }
}
export async function deleteBlob(blobName) {

    getBlobSasToken(blobName)
        .then(async (sasToken: any) => {
            const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.delete();
           // console.log('Blob deleted successfully');
        }).catch((error: any) => {
            //console.log(error);
        });
}