import * as React from 'react';
import * as ReactDom from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import green from '@material-ui/core/colors/green';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/Attachment';
import CameraIcon from '@material-ui/icons/CameraAlt';
import EditIcon from '@material-ui/icons/Edit';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import WarningIcon from '@material-ui/icons/Warning';
import { Api } from '../../api/Api';
import { IReceiptMetadata } from '../index';
import Filter from './Filters/Filter';
import AddExpenseButton from './Buttons/AddExpenseButton';
import CategoryList from './List/CategoryList';
import ReceiptDate from './List/ReceiptDate';
import ReceiptTotal from './List/ReceiptTotal';
import Thumbnail from './Thumbnail/Thumbnail';
import * as TripStore from '../../store/trips/tripReducer';
import * as PdfStore from '../../store/pdfs/pdfReducer';
import { ApplicationState } from '../../store/index';
import { ITripState } from '../../store/trips/tripState';
import { BLOB_URL, POSSIBLE_DUPLICATE_IN_PREVIOUS_TRIP_WARNING, POSSIBLE_DUPLICATE_IN_THIS_TRIP_WARNING } from '../../constants/constants';

const api = new Api();

interface IExpenseIndexProps extends WithStyles<typeof styles> {
    handleCloseReceiptForm: any;
    editReceiptForm: any;
    receiptMetadata: IReceiptMetadata[];
}
interface IExpenseIndexState {
    isApproved: boolean;
    anchorEl: any;
    duplicateMessage: string;
}

type ExpenseIndexProps = IExpenseIndexProps
    & PdfStore.IPdfState
    & ITripState
    & typeof PdfStore.actionCreators
    & typeof TripStore.actionCreators;

class ExpenseIndex extends React.Component<ExpenseIndexProps, IExpenseIndexState> {
    api: Api = new Api();

    constructor(props?: (ExpenseIndexProps)) {
        super(props as any);

        this.state = { isApproved: false, anchorEl: null, duplicateMessage: '' }

        this.handleCloseReceiptForm = this.handleCloseReceiptForm.bind(this);
        this.handleApprovalChange = this.handleApprovalChange.bind(this);
        this.isTripSelected = this.isTripSelected.bind(this);
        this.handleDuplicateMessageClick = this.handleDuplicateMessageClick.bind(this);
        this.handleDuplicateMessageClose = this.handleDuplicateMessageClose.bind(this);

        this.openFileDialog = this.openFileDialog.bind(this);
        this.openPDFDialog = this.openPDFDialog.bind(this);
    }
  
    // file upload event handlers
    openFileDialog(metadata: IReceiptMetadata) {      
        this.props.selectReceiptMetadata(metadata);

        let fileUploadDom: any = ReactDom.findDOMNode(this.refs.fileUpload);
        fileUploadDom.click();
    }
    openPDFDialog = (metadata: IReceiptMetadata) => {       
        this.props.selectReceiptMetadata(metadata);

        let fileUploadDom: any = ReactDom.findDOMNode(this.refs.pdfUpload);
        fileUploadDom.click();
    }

    isTripSelected() {
        return this.props.selectedTrip.id > 0;
    }
    handleCloseReceiptForm() {
        this.props.handleCloseReceiptForm();
    }

    handleApprovalChange(e: any, metadata: IReceiptMetadata) {
        metadata.isAuditorApproved = e.target.checked;
        this.props.updateAuditorApprovalItem(metadata);
    }

    async openPdfAttachment(event: any, receiptMetadata: IReceiptMetadata) {
        let key = receiptMetadata.pdfKey;

        // Open a blank window first
        const newWindow = window.open('', '_blank');

        await api.getBlobSasToken(key).then((sasToken) => {
            const sasTokenLink = key + sasToken;
            const url = `${BLOB_URL}/${sasTokenLink}`;

            if (newWindow) {
                newWindow.location.href = url;
            }
        });       
    }

    handleDuplicateMessageClick(e: any, tripId: number = 0) {   

        const currentTarget = e.currentTarget;
        if (tripId > 0) {
            // find and show the name of the trip where the duplicate exists.
            this.api.getTrip(tripId)
                .then((result: any) => {
                    setTimeout(() => {
                        this.setState({
                            anchorEl: currentTarget,
                            duplicateMessage: `${POSSIBLE_DUPLICATE_IN_PREVIOUS_TRIP_WARNING}: '${result.name}'.`                            
                        });
                    }, 0);
                });
        } else {
            this.setState({
                anchorEl: currentTarget,
                duplicateMessage: `${POSSIBLE_DUPLICATE_IN_THIS_TRIP_WARNING}`
            });
        }
    }

    handleDuplicateMessageClose() {
        this.setState({
            anchorEl: null,
        });
    }

    render() {

        const { classes } = this.props as any;
        const { anchorEl } = this.state;

        const open = Boolean(anchorEl);
        const selectedTripDraftStatus = this.props.selectedTrip.approvalStatusName === 'Draft';

        return <div>

            <div className='container'>

                <div className='animation-container'>
                    <div style={{ 'display': this.props.selectedTrip.id > 0 ? 'block' : 'none' }} className={classes.sticky}>
                        <br />

                        {/* Button: Add Expense form (sticky scroll) */}
                        <div style={{ 'textAlign': 'center', 'display': !selectedTripDraftStatus || !this.isTripSelected() ? 'none' : 'block' }}>
                            <div>
                                <AddExpenseButton showNewReceiptForm={this.props.showNewReceiptForm} />
                            </div>
                        </div>

                        <Typography variant='subtitle1' align='center' color='textPrimary' gutterBottom
                            style={{ 'display': this.props.selectedTrip.receiptMetadata && this.props.selectedTrip.receiptMetadata.length > 0 && this.props.isAuditorView ? 'block' : 'none' }}>
                            <strong style={{ 'color': 'green' }}>{this.props.receiptMetadata && this.props.receiptMetadata.filter(x => x.isAuditorApproved).length} approved</strong> out of {this.props.receiptMetadata.length} total expenses.
                        </Typography>
                    </div>
                    <br />
                    <Typography variant='h6' align='center' color='textPrimary' style={{ 'margin': '50px', 'display': this.props.selectedTrip.id > 0 && this.props.receiptMetadata.length === 0 ? 'block' : 'none' }} gutterBottom>
                        No expenses found.
                    </Typography>
                    <br />

                    {/* Filters */}
                    <Filter categoryValue={this.props.categoryValue}
                        dateValue={this.props.dateValue}
                        handleCategoryFilterChange={this.props.handleCategoryFilterChange}
                        handleDateFilterChange={this.props.handleDateFilterChange}
                        selectedTrip={this.props.selectedTrip}
                        categories={this.props.categories} />
                    <br />
                    <br />
                    <div style={{ display: this.props.isAuditorView ? 'inherit' : 'none' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.viewUnapprovedOnly}
                                    onChange={this.props.handleViewUnapprovedExpensesOnly}
                                    value={`${this.props.viewUnapprovedOnly}`}
                                />
                            }
                            label='Show Unapproved Only'
                        /> 
                    </div>

                    {/* Expense list */}
                    {this.props.receiptMetadata && this.props.receiptMetadata.map((metadata) =>
                        <div style={{
                            'display': (this.props.isAuditorView
                                && metadata.isAuditorApproved
                                && this.props.viewUnapprovedOnly ? 'none' : 'inherit')
                        }}
                            className={classes.pad} key={metadata.receiptMetadataId} id={metadata.receiptMetadataId.toString()}>
                            <div style={{ 'backgroundColor': '#006fbb', 'paddingTop': '10px', 'textAlign': 'center' }}>
                                {/* Date */}
                                <ReceiptDate metadata={metadata} />
                                <Divider />
                            </div>
                            <Grid container>
                                <Grid item xs={12} md={12}>

                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography variant='h5'>
                                                    <ReceiptTotal metadata={metadata} />
                                                </Typography>
                                                <Typography variant='body1' color='textSecondary' style={{
                                                    'visibility': metadata.currencyName === 'USD' ? 'hidden' : 'visible'
                                                }}>
                                                    <span style={{ 'marginLeft': '20px' }}>{metadata.currencyIcon} {metadata.amountTotalForeignCurrency}</span>
                                                    <br />
                                                    <span style={{ 'marginLeft': '20px' }}>(FX: {metadata.fxRate})</span>
                                                </Typography>

                                                {/* CategoryList */}
                                                <CategoryList metadata={metadata} miscCategories={this.props.miscExpenseCategories} isAuditor={this.props.isAuditorView} />
                                                <br />

                                                {/* Duplicate Warning Message */}
                                                <div style={{
                                                    'display': metadata.isPossibleDuplicateInPreviousTrip ? 'block' : 'none'
                                                }}>
                                                    <WarningIcon onClick={(e: any) => this.handleDuplicateMessageClick(e, metadata.possibleDuplicateInTripId)} className={classes.duplicateIcon} />
                                                </div>

                                                <div style={{
                                                    'display': metadata.isPossibleDuplicateInThisTrip ? 'block' : 'none'
                                                }}>
                                                    <WarningIcon onClick={(e: any) => this.handleDuplicateMessageClick(e)} className={classes.duplicateIcon} />
                                                </div>
                                                <Popover
                                                    id='duplication-warning-popper'
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={this.handleDuplicateMessageClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Typography className={classes.duplicateWarning}>{this.state.duplicateMessage}</Typography>
                                                </Popover>
                                            </CardContent>
                                        </div>

                                        <div className={classes.cardDetails}>
                                            {/* Image */}
                                            <CardContent className={classes.thumbnail}>
                                                <Thumbnail
                                                    loadingImageKey={this.props.loadingImageKey}
                                                    isThumbnailLoadComplete={this.props.isThumbnailLoadComplete}
                                                    showLightbox={true}
                                                    isLoadingPDF={this.props.isLoadingPdf}
                                                    selectedReceiptMetadata={this.props.selectedReceiptMetadata}
                                                    metadata={metadata} />

                                                {/* PDF */}
                                                <div style={{ 'display': metadata.pdfKey ? 'inherit' : 'none' }}>
                                                    <div style={{ 'paddingTop': '20px', 'textAlign': 'center' }}>
                                                        <PdfIcon className='pointer'
                                                            onClick={(e: any) => this.openPdfAttachment(e, metadata)} style={{ 'color': '#e31110', 'height': '50px', 'width': '44px' }} />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>

                            {/* Options */}
                            <div style={{
                                'visibility': (this.props.selectedTrip.approvalStatusName === 'Draft'
                                    || (this.props.isAuditorView && this.props.selectedTrip.approvalStatusName !== 'Processed'))
                                    && this.props.selectedTrip.receiptMetadata.length > 0 ? 'visible' : 'hidden'
                            }}>


                                {/* Hidden PDF Upload */}
                                <input style={{ 'visibility': 'hidden' }} id='pdfFileInput' ref='pdfUpload' type='file' accept='application/pdf'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.uploadPdfAttachment(e.target.files)} />

                                {/* Hidden Image Upload */}
                                <input style={{ 'visibility': 'hidden' }} id='fileInput' ref='fileUpload' type='file' accept='image/*'
                                    onChange={(e: any) => this.props.fileUploadHandler(e.target.files)} />

                                <BottomNavigation className={classes.bottomNav} showLabels>

                                    <BottomNavigationAction disabled={this.props.isTripRefreshing} style={{ 'maxWidth': '300px' }} className={classes.icon}
                                        label='Edit'
                                        icon={<EditIcon />}
                                        onClick={() => this.props.editReceiptForm(metadata)}
                                    />

                                    <BottomNavigationAction style={{ 'maxWidth': '300px' }} className={classes.icon}
                                        label={!metadata.pdfKey ? 'Attach PDF' : 'Remove PDF'}
                                        icon={<AttachFileIcon />}
                                        onClick={(e: any) => metadata.pdfKey ? this.props.removePdfAttachment(e, metadata) : this.openPDFDialog(metadata)}
                                    />

                                    <BottomNavigationAction style={{ 'maxWidth': '300px' }} className={classes.icon}
                                        label={!metadata.key ? 'Add Image' : 'Remove Image'}
                                        icon={!metadata.key ? <CameraIcon /> : <SwitchCameraIcon />}
                                        onClick={(e: any) => metadata.key ? this.props.removeImage(metadata) : this.openFileDialog(metadata)}
                                    />
                                </BottomNavigation>

                                {/* Approval Checkbox */}
                                <Paper className={classes.approvals} style={{
                                    'display': this.props.isAuditorView
                                        && this.props.selectedTrip.approvalStatusName !== 'Processed'
                                        ? 'block' : 'none'
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={metadata.isAuditorApproved ? true : false}
                                                onChange={(e: any) => this.handleApprovalChange(e, metadata)}
                                                value={metadata.isAuditorApproved.toString()}
                                                classes={{
                                                    root: classes.root,
                                                    checked: classes.checked
                                                }}

                                            />}
                                        label='Approve'
                                    />
                                </Paper>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        '&$checked': {
            color: green[500],
        },
        height: 'auto'
    },
    duplicateIcon: {
        color: '#ed8000'
    },
    duplicateWarning: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        fontSize: '12px'
    },
    checked: { color: green[600] },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
        borderRight: '1px solid #ccc'
    },
    cardMedia: {
        width: 160,
    },
    addExpenseButton: {
        margin: 12
    },
    formControl: {
        margin: theme.spacing(3),
    },
    subtitle: {
        fontSize: '12px',
        float: 'right',
        name: {
            'margin': 0,
            'marginTop': '5px'
        }
    },
    select: {
        width: '100%'
    },
    approvals: {
        padding: '40px',
        textAlign: 'center'
    },
    pad: {
        paddingBottom: '50px'
    },
    textfield: {
        width: 200,
    },
    icon: {
        '&:hover': {
            color: '#006fbb !important',
        },
    },
    bottomNav: {
        border: '1px solid #ccc',
        backgroundColor: '#fff',
        height: 'auto'
    },
    sticky: {
        position: 'sticky',
        top: 0,
        zIndex: 400,
        borderBottom: '1px solid #f50057',
        paddingBottom: '20px',
        background: 'rgba(255, 255, 255, 0.9)'
    },

});

export default compose(connect(
    (state: ApplicationState) => state.pdf,
    PdfStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(ExpenseIndex) as any;
