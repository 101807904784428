import * as React from 'react';
import moment from 'moment';
import {IReceiptMetadata} from '../../index';

interface IReceiptDateProps {
    metadata: IReceiptMetadata;
}

const ReceiptDate = (props: IReceiptDateProps) => {
    const styles = {
        receiptDate: {
            color: '#fff',
            fontSize: '16px', 
            fontWeight: 500
        }
    }
    return <div>
        <h4 style={styles.receiptDate}>
            {props.metadata.dateOfReceipt && props.metadata.dateOfReceipt[0] === '0'
                ? 'TBD'
                : moment(props.metadata.dateOfReceipt).format('dddd, MMMM Do YYYY')}
        </h4>
    </div>;
}

export default ReceiptDate;