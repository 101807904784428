import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../../../store/index';
import * as TripStore from '../../../store/trips/tripReducer';
import * as AuthStore from '../../../store/auth/authReducer';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Profile from '../../Profile/Profile';
import SideMenu from '../../SideMenu';
import { Box } from '@material-ui/core';
import { ITripState } from '../../../store/trips/tripState';

interface ITripHeaderLocalProps {
    handleLogout: any;
}
interface ITripHeaderState {
    drawerOpen: boolean;
}
type ITripHeaderProps =
    AuthStore.IAuthState &
    ITripState
    & ITripHeaderLocalProps
    & typeof AuthStore.actionCreators
    & typeof TripStore.actionCreators;

class TripHeader extends React.Component<ITripHeaderProps, ITripHeaderState> {

    constructor(props?: (ITripHeaderProps)) {
        super(props as any);

        this.state = {
            drawerOpen: false
        }

        this.toggleDrawer = this.toggleDrawer.bind(this);
    }
    toggleDrawer() {
        this.setState({
            drawerOpen: !this.state.drawerOpen,
        });
    };
    render() {

        const { classes } = this.props as any;
        const drawer = <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer}>
            <div
                tabIndex={0}
                role='button'
                onClick={this.toggleDrawer}
                onKeyDown={this.toggleDrawer}
            >
                <SideMenu
                    isAuditorView={this.props.isAuditorView}
                    handleEditTrip={this.props.handleEditTrip}
                    handleLogout={this.props.handleLogout}
                    currentUserHasProfile={this.props.currentUserHasProfile}
                    handleNewTravelAdvance={this.props.handleNewTravelAdvance}
                    handleNewTrip={this.props.handleNewTrip}
                    selectedTrip={this.props.selectedTrip}
                />
            </div>
        </Drawer>;

        return <div>

            <Profile />

            <AppBar position='static' className={classes.appBar}>
                <Toolbar>
                    <IconButton className={classes.menuButton} color='inherit' aria-label='Menu' onClick={this.toggleDrawer} >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' color='inherit' className={classes.flex} component='div'>
                        <NavLink style={{ color: '#fff' }} to='/' className={classes.noDecorationLink}>SCHOTT Travel Expense App</NavLink>
                        <Typography variant='body1' color='inherit' component='div'>

                            <Box display='flex' alignItems='center' className={classes.padBottom}>
                                <AccountCircleIcon fontSize='small' />

                                &nbsp;&nbsp;{this.props.currentUser ? this.props.currentUser.name : ''}
                            </Box>
                        </Typography>
                    </Typography>
                </Toolbar>
            </AppBar>

            {drawer}

            <div style={{ display: !this.props.currentUserHasProfile ? 'inherit' : 'none' }}>
                <Typography className={classes.pad} variant='h5' align='center' color='textSecondary' paragraph>
                    Welcome to the   <br /><strong>SCHOTT Travel Expense App</strong>
                    <br />
                    <br />
                    <br />
                    <br />
                    To get started,<br /> <a className={classes.link} onClick={this.props.handleShowProfileForm}>please create a profile.
               

                    </a>
                </Typography>
            </div>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    appBar: {
        backgroundColor: theme.palette.primary.main
    },
    menuButton: {
        marginRight: 16,
        marginLeft: -12,
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    pad: {
        paddingTop: '30px'
    },
    padBottom: {
        paddingBottom: '5px'
    },
    card: {
        width: '100%',
        backgroundColor: '#000'
    },
    noDecorationLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
});


export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(TripHeader) as any;