import { connect } from 'react-redux';
import { compose } from 'redux';
import { ApplicationState } from '../../../store/index';
import * as TripStore from '../../../store/trips/tripReducer';
import * as AuthStore from '../../../store/auth/authReducer';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import * as React from 'react';
import { Api } from '../../../api/Api';
import { INVALID_CHARACTERS, TRIP_NAME_MISSING_WARNING, TRIP_DATE_FORMAT, DELETE_WARNING_MESSAGE } from '../../../constants/constants';
import { ITrip } from '../../index';
import { defaultSelectedTrip } from '../../../constants/TripDefaults';
import TripFormButtons from './TripFormButtons';
import { ITripState } from '../../../store/trips/tripState';
import UserAutocomplete from '../../UserAutocomplete';
import { filterUsers } from '../../../store/utils/userUtils';

export interface ITripFormState {
    tripSubMenuOpen: boolean;
    selectedTrip: ITrip | any;
    isValidName: boolean;
    tripNameErrorText: string;
    requiredText: string;
    isValid: boolean;
    inputValue: any;
    selectedSurrogate: any;
    
}
export interface ITripFormLocalProps extends WithStyles<typeof styles> {
    saveNewTrip: any;
    updateCurrentTrip: any;     
}
type ITripFormProps =
    ITripState
    & AuthStore.IAuthState
    & ITripFormLocalProps
    & typeof AuthStore.actionCreators
    & typeof TripStore.actionCreators;

class TripForm extends React.Component<ITripFormProps, ITripFormState> {

    api: Api = new Api();

    constructor(props?: (ITripFormProps)) {
        super(props as any);

        this.state = {
            tripSubMenuOpen: false,
            selectedTrip: { ...this.props.selectedTrip },
            isValidName: true,
            tripNameErrorText: '',
            requiredText: '',
            isValid: false,
            inputValue: '',
            selectedSurrogate: null
        }

        this.handleTripSubMenuClose = this.handleTripSubMenuClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateTrip = this.validateTrip.bind(this);
        this.validateSaveTrip = this.validateSaveTrip.bind(this);     
        this.deleteTrip = this.deleteTrip.bind(this);
    }

    componentDidMount() {
        this.validateName();
    }
    handleTripSubMenuClose() {
        this.setState({
            tripSubMenuOpen: false
        });
    }

    handleChange(e: any, property: string) {    
        this.setState({    
            selectedTrip: this.state.selectedTrip
        });

        this.props.handleChange(e, property);
    }
    handleStartDateChange(e: any) {
        let trip = this.state.selectedTrip ? this.state.selectedTrip : { ...defaultSelectedTrip };

        trip.startDate = moment(e).format(TRIP_DATE_FORMAT);
        this.setState({
            selectedTrip: trip
        });
        this.props.handleStartDateChange(e);
    }
    handleEndDateChange(e: any) {
        let trip = this.state.selectedTrip ? this.state.selectedTrip : { ...defaultSelectedTrip };

        trip.endDate = moment(e).format(TRIP_DATE_FORMAT);
        this.setState({
            selectedTrip: trip
        });
        this.props.handleEndDateChange(e);
    }
    validateSaveTrip(selectedTrip: any) {      
        this.validateTrip(selectedTrip, true);
    }
    validateTrip(selectedTrip: any, isNewTrip: boolean = false) {
      
        this.validateName();

        if (this.state.isValidName) {
         
            let isValid = true;
            let requiredText = '';            

            if (!this.props.selectedTrip.costCenter) {
                if (!this.props.selectedTrip.submittedOnBehalfOfCostCenter) { 
                    isValid = false;
                    requiredText = 'This field is required.';
                }
            }

            this.setState({
                isValid: isValid,
                isValidName: true,
                requiredText: requiredText
            });
           
            if (isValid) {
                if (isNewTrip) {
                    this.props.saveNewTrip(selectedTrip);
                }
                else {
                    this.props.updateCurrentTrip(selectedTrip);
                }
            }
        }
    }
   
    validateName() {
        let isValid = true;
        let tripNameErrorText = '';

        if (!this.props.selectedTrip.name) {
            isValid = false;
            tripNameErrorText = TRIP_NAME_MISSING_WARNING;
        }

        for (let i = 0; i < INVALID_CHARACTERS.length; i++) {
            if (this.props.selectedTrip.name.indexOf(INVALID_CHARACTERS[i]) > -1) {
                isValid = false;
                tripNameErrorText = `Trip name must not contain a ${INVALID_CHARACTERS[i]} character.`;
            }
        }

        this.setState({
            isValidName: isValid,
            tripNameErrorText: tripNameErrorText
        });
    }
    deleteTrip() {
        if (window.confirm(DELETE_WARNING_MESSAGE)) {
            this.props.deleteTrip();
        };
    }

    render() {

        const { classes } = this.props as any;

        const loadUsers = (inputValue, callback) => {
            this.props.loadUserList(inputValue, null);
            callback(filterUsers(this.props.users, inputValue));
        };
        return (
            <div className={classes.pad}>
                { /* Name */}
                <div className={classes.pad}>
                    <TextField
                        fullWidth
                        id='name'
                        autoComplete='off'
                        label='Trip Name (this will be a unique identifier)'
                        required
                        error={!this.state.isValidName}
                        value={this.props.selectedTrip.name}
                        onChange={(e: any) => this.handleChange(e, 'name')}
                        disabled={this.props.isEditingTrip}
                        margin='normal' InputLabelProps={{
                            shrink: true
                        }}
                        helperText={this.state.tripNameErrorText}
                        onBlur={this.validateName}
                    />
                </div>

                { /* Submitted On Behalf Of - only for Surrogates */}
                <div className={classes.pad} style={{
                    'display': this.props.submitAsSurrogate ? 'inherit' : 'none'
                }}>

                    <Typography variant='body1'>* Submitting On Behalf Of: {this.props.selectedTrip.submittedOnBehalfOfName}</Typography>
                    {this.state.inputValue && !this.props.selectedTrip.submittedOnBehalfOfName && <Typography variant='caption'>hint: keep typing until the name appears in the dropdown, then select it.</Typography>}
            
                    <UserAutocomplete loadUsers={loadUsers} changeUser={this.props.handleSubmittingOnBehalfOfChange} />         
                </div>

                <div className={classes.pad} style={{
                    'display': this.props.submitAsSurrogate ? 'inherit' : 'none'
                }}>
                    <TextField
                        fullWidth={true}
                        error={!this.props.selectedTrip.submittedOnBehalfOfVendorNumber}
                        id='submittedOnBehalfOfVendorNumber'
                        label='* Submitting On Behalf Of - Vendor Number'
                        value={this.props.selectedTrip.submittedOnBehalfOfVendorNumber || ''}
                        helperText='This is the *Vendor Number* of whom you are submitting this for. Must be 7 digits.'
                        onChange={(e: any) => this.handleChange(e, 'submittedOnBehalfOfVendorNumber')}
                        margin='normal'
                        inputProps={{
                            maxLength: 7,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div className={classes.pad} style={{
                    'display': this.props.submitAsSurrogate ? 'inherit' : 'none'
                }}>
                    <TextField
                        fullWidth={true}
                        error={!this.props.selectedTrip.submittedOnBehalfOfCostCenter}                       
                        id='submittedOnBehalfOfCostCenter'
                        label='* Submitting On Behalf Of - Cost Center / Internal Order #'
                        value={this.props.selectedTrip.submittedOnBehalfOfCostCenter || ''}
                        helperText='This is the *Cost Center* or *Internal Order* of whom you are submitting this for'
                        onChange={(e: any) => this.handleChange(e, 'submittedOnBehalfOfCostCenter')}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                { /* Reason for Trip */}
                <div className={classes.pad}>
                    <TextField
                        fullWidth
                        id='description'
                        autoComplete='off'
                        required
                        error={!this.props.selectedTrip.description}
                        label='Reason for Trip:'
                        value={this.props.selectedTrip.description}
                        onChange={(e: any) => this.handleChange(e, 'description')}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                { /* Start Date */}
                <div className={classes.pad}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <form className={classes.container} noValidate>
                            <DatePicker
                                autoOk={true}
                                required
                                error={!this.props.selectedTrip.startDate}
                                emptyLabel={' '}
                                label='Start Date:'
                                format={moment(this.props.selectedTrip.startDate).format('MM/DD/YYYY')}
                                value={this.props.selectedTrip.startDate}
                                className={classes.textField}
                                onChange={(e: any) => this.props.handleStartDateChange(e)}
                            />
                        </form>
                    </MuiPickersUtilsProvider>
                </div>

                { /* End Date */}
                <div className={classes.pad}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <form className={classes.container} noValidate>
                            <DatePicker clearable={true}
                                autoOk={true}
                                emptyLabel={' '}
                                required
                                error={!this.props.selectedTrip.endDate}
                                label='End Date:'
                                format={moment(this.props.selectedTrip.endDate).format('MM/DD/YYYY')}
                                value={this.props.selectedTrip.endDate}
                                className={classes.textField}
                                onChange={(e: any) => this.props.handleEndDateChange(e)}
                            />
                        </form>
                    </MuiPickersUtilsProvider>
                </div>

                { /* Travel Advance Amount */}
                <div className={classes.pad}>
                    <FormControl>
                        <InputLabel shrink={true} style={{ 'width': '250px' }}>Travel Advance Applied (optional):</InputLabel>
                        <Input
                            inputProps={{ max: 30 }}
                            id='travelAdvanceAmount'
                            type='number'
                            value={this.props.selectedTrip.travelAdvanceAmount}
                            onChange={(e: any) => this.handleChange(e, 'travelAdvanceAmount')}
                            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                        />
                    </FormControl>
                </div>

                { /* Comments */}
                <div className={classes.pad}>
                    <TextField
                        fullWidth
                        id='comments'
                        label='Comments:'
                        value={this.props.selectedTrip.comments}
                        onChange={(e: any) => this.handleChange(e, 'comments')}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                { /* Cost Center */}
                <div className={classes.pad}
                    style={{ 'display': this.props.submitAsSurrogate ? 'none' : 'inherit' }}>
                    <TextField
                        id='costCenter'
                        label='Cost Center / Internal Order:'
                        required
                        error={!this.props.selectedTrip.costCenter}
                        value={this.props.selectedTrip.costCenter
                            ?
                            this.props.selectedTrip.costCenter : ''}
                        helperText='This is can either be your *Cost Center* or *Internal Order #*'
                        onChange={(e: any) => this.handleChange(e, 'costCenter')}
                        margin='normal'
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </div>

                { /* Save */}
                <TripFormButtons selectedTrip={this.props.selectedTrip} deleteTrip={this.deleteTrip} cancelUpdate={this.props.cancelUpdate}
                    saveTrip={this.validateSaveTrip} updateTrip={this.validateTrip}
                    isEditingTrip={this.props.isEditingTrip} />
            </div>
        );
    }
}
const styles = (theme: Theme) => createStyles({
    select: {
        width: '100%'
    },
    pad: {
        paddingTop: '50px'
    },
    textfield: {
      
        width: 200,
    },
    rightIcon: {
      
    },
    iconSmall: {
        fontSize: 20,
    },
});
export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(TripForm) as any;
