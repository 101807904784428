import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import {
    SummaryAction
} from './summaryActions';
import { Api } from '../../api/Api';
import { AppThunkAction } from '../../store/index';
import { ITrip, ICategorySummary, IDailySummary } from '../../components/index';

const api = new Api();

type KnownAction = SummaryAction;

export interface ISummaryState {
    selectedTripSummary: ICategorySummary[];
    selectedTripDailyTotalsSummary: IDailySummary[];
};

export const unloadedState: ISummaryState = {
    selectedTripDailyTotalsSummary: [{
        expensedDate: new Date(),
        total: 0,
        dailyAmounts: []
    }],
    selectedTripSummary: [{
        categoryName: '',
        materialIconName: '',
        expensedTotal: '',
        dailyAmounts: []
    }],
};

export const actionCreators = {
    
    getDailyTotals: (selectedTrip: ITrip): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getTripDailyTotalsSummary(selectedTrip.id).then((selectedTripDailyTotalsSummary: any) => {
            dispatch({ type: 'GET_DAILYTOTALS_SUCCESS', selectedTripDailyTotalsSummary: selectedTripDailyTotalsSummary });
        });

        addTask(fetchTask);
    },
    getDailyTotalsSuccess: () => <KnownAction>{ type: 'GET_DAILYTOTALS_SUCCESS' },
 
    getTripCategorySummary: (selectedTrip: ITrip): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = api.getTripCategorySummary(selectedTrip.id).then((selectedTripSummary: any) => {
            dispatch({ type: 'GET_TRIPCATEGORYSUMMARY_SUCCESS', selectedTripSummary: selectedTripSummary });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_TRIPCATEGORYSUMMARY' });
    },

    getTripCategorySummarySuccess: () => <KnownAction>{ type: 'GET_TRIPCATEGORYSUMMARY_SUCCESS' },   
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<ISummaryState> = (state: ISummaryState, action: KnownAction) => {

    switch (action.type) {        
        case 'GET_DAILYTOTALS_SUCCESS':
            return { ...state, selectedTripDailyTotalsSummary: action.selectedTripDailyTotalsSummary };
        case 'GET_TRIPCATEGORYSUMMARY':
            return { ...state, selectedTripSummary: state.selectedTripSummary };
        case 'GET_TRIPCATEGORYSUMMARY_SUCCESS':
            return { ...state, selectedTripSummary: action.selectedTripSummary };
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};

