import * as React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';

interface IScrollButtonProps {
    scrollStepInPx: number;
    delayInMs: number;
}
interface IScrollButtonState {
    intervalId: number;
}

export default class ScrollButton extends React.Component<IScrollButtonProps, IScrollButtonState> {
    constructor(props: IScrollButtonProps) {
        super(props);

        this.state = {
            intervalId: 0
        };

        this.scrollStep = this.scrollStep.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    scrollStep() {

        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scrollTo(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        window.scrollTo({
            top: 800,
            behavior: 'smooth'
        });    
    }

    render() {
        return <Button title='Scroll to Top' variant='contained' color='secondary' onClick={() => { this.scrollToTop(); }}>          
            <ExpandLessIcon />
        </Button>;
    }
}