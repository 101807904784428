﻿import * as CheckRequest from './checkRequests/checkRequestReducer';
import * as Profile from './profile/profileReducer';
import * as Status from './status/statusReducer';
import * as Trip from './trips/tripReducer';
import * as Auth from './auth/authReducer';
import * as Pdf from './pdfs/pdfReducer';
import * as Summary from './summary/summaryReducer';
import * as MileageRate from './mileageRate/mileageRateReducer';
import { ITripState } from './trips/tripState';

// import new reducer here.

// 1. Add new reducer here
// The top-level state object
export interface ApplicationState {
    checkRequest: CheckRequest.ICheckRequestState;
    profile: Profile.IProfileState;
    status: Status.IStatusTabState;
    trip: ITripState;
    auth: Auth.IAuthState;
    pdf: Pdf.IPdfState;
    summary: Summary.ISummaryState;
    mileageRate: MileageRate.IMileageRateState;

}


// 2. Add new reducer here
// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    checkRequest: CheckRequest.reducer,
    profile: Profile.reducer,
    status: Status.reducer,
    trip: Trip.reducer,
    auth: Auth.reducer,
    pdf: Pdf.reducer,
    summary: Summary.reducer,
    mileageRate: MileageRate.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
