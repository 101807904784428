import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { Api } from '../../api/Api';
import { ApplicationState } from '../../store/index';
import * as TripStore from '../../store/trips/tripReducer';
import * as ProfileStore from '../../store/profile/profileReducer';
import Help from '../Help/Help';
import { ITripState } from '../../store/trips/tripState';
import * as AuthStore from '../../store/auth/authReducer';
import UserAutocomplete from '../UserAutocomplete';
import { filterUsers } from '../../store/utils/userUtils';

interface IProps {
}

type ProfileProps = IProps & RouteComponentProps<{}>;

export type IProfileProps =
    ProfileStore.IProfileState
    & ITripState
    & AuthStore.IAuthState
    & ProfileProps
    & typeof TripStore.actionCreators
    & typeof AuthStore.actionCreators
    & typeof ProfileStore.actionCreators;

interface IProfileState {
    inputValue: any;
    selectedPrimary: any;
}
export class Profile extends React.Component<IProfileProps, IProfileState> {
    api: any = new Api();

    constructor(props?: any) {
        super(props);

        this.state = {
            inputValue: '',
            selectedPrimary: null
        };

        this.handleCompanyNumberChange = this.handleCompanyNumberChange.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
    }

    componentDidMount() {
        // hide the IOS keyboard.
        document.addEventListener('keyup', this.handleKeyUp);
        this.props.getCurrentUserForProfile();
    }
    componentWillUnmount() {
        // Remove the event listener when the component unmounts to avoid memory leaks
        document.removeEventListener('keyup', this.handleKeyUp);
    }
    handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            // Find the focused input element and blur it
            const focusedInput = document.activeElement;
            if (focusedInput instanceof HTMLInputElement) {
                focusedInput.blur();
            }
        }
    };
    handleCompanyNumberChange(e: any) {
        let companyNumber = e.target.value.toString();
        this.props.handleCompanyNumberChange(companyNumber);
    }

    async saveProfile() {
        await this.props.saveProfile();
    }

    render() {

        const { classes } = this.props as any;

        const loadUsers = (inputValue, callback) => {
            this.props.loadUserList(inputValue, null);
            callback(filterUsers(this.props.users, inputValue));
        };

        return <div>

            <Help />

            <Dialog fullScreen open={this.props.showProfileForm} onClose={this.props.handleCloseProfileForm}>
                <AppBar position='static'>
                    <Toolbar>
                        <IconButton onClick={this.props.handleCloseProfileForm} aria-label='Menu' color='inherit'>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' color='inherit'>
                            Profile
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Typography className={classes.slightPad} variant='subtitle1' align='center' color='textSecondary' paragraph>
                    Please update your profile information below:
                </Typography>

                <Grid container justifyContent='center'>
                    <Grid item xs={10} sm={4}>
                        <div className={classes.slightPad}>
                            <Typography variant='body1'>Manager:</Typography>
                            <br />
                            <Typography variant='body2'>{this.props.primaryManagerEmail || this.props.currentUser && this.props.currentUser.primaryManagerEmail}</Typography>
                            <UserAutocomplete loadUsers={loadUsers} changeUser={this.props.handlePrimaryChange} />
                        </div>

                        <div className={classes.pad}>
                            <FormControl className={classes.select}>
                                <Typography variant='body1'>Company Code:</Typography>
                                <RadioGroup
                                    name='companyCode'
                                    className={classes.group}
                                    value={this.props.companyNumber.toString()}
                                    onChange={this.handleCompanyNumberChange}
                                >
                                    <FormControlLabel value='67' control={<Radio />} label='67 – SCHOTT North America' />
                                    <FormControlLabel value='996' control={<Radio />} label='996 – SCHOTT MINIFAB' />
                                    <FormControlLabel value='995' control={<Radio />} label='995 – SCHOTT Pharma USA' />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className={classes.pad}>
                            {/* textbox for vendor number */}
                            <TextField
                                id='vendorNumber'
                                label='Vendor Number'
                                className={classes.textField}
                                defaultValue={this.props.vendorNumber}
                                onChange={this.props.handleVendorNumberChange}
                                margin='dense'
                                variant='outlined' />
                        </div>

                        <div className={classes.pad}>
                            {/* textbox for IT cost center */}
                            <TextField
                                id='itCostCenter'
                                label='IT Cost Center'
                                className={classes.textField}
                                defaultValue={this.props.itCostCenter}
                                onChange={this.props.handleITCostCenterChange}
                                margin='dense'
                                variant='outlined' />
                        </div>
                        {/* Update Buttons */}
                        <Grid item style={{ 'marginTop': '50px' }}>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                disabled={!this.props.companyNumber || this.props.primaryManagerEmail === ''}
                                onClick={this.saveProfile}>
                                Update
                            </Button>
                        </Grid>
                        <Grid item style={{ 'marginTop': '20px', 'marginBottom': '50px' }}>
                            <Button fullWidth variant='outlined' onClick={this.props.handleCloseProfileForm}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.props.showMessageProfile}
                    autoHideDuration={3000}
                    onClose={this.props.handleMessageProfileClose}
                >
                    <SnackbarContent
                        aria-describedby='client-snackbar'
                        message={
                            <span id='client-snackbar' className={classes.message}>
                                {this.props.messageProfile}
                            </span>
                        }
                    />
                </Snackbar>
            </Dialog>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    paper: {
        position: 'absolute',
        zIndex: 1,
        width: '50%'
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    select: {
        width: '100%'
    },
    group: {
        margin: `0 0`,
    },
    pad: {
        paddingTop: '50px'
    },
    slightPad: {
        paddingTop: '20px'
    },
    textfield: {
        width: 200,
    },
    rightIcon: {

    },
    iconSmall: {
        fontSize: 20,
    },
    success: {
        backgroundColor: green[600],
    }, error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
});

export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.profile,
    ProfileStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(Profile) as any;

