import { Typography } from '@material-ui/core';
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

const UserAutocomplete = ({ loadUsers, changeUser, modifier = null }) => {
    const handleInputChange = (inputValue, callback) => {
        loadUsers(inputValue, callback);
    };
    const handleUserSelect = (selectedOption) => {

        if (selectedOption) {
            //console.log(`Selected user: ${selectedOption.value}`);
            //console.log(modifier);
            if (modifier) {
                changeUser(selectedOption.value, modifier);
            } else {
                changeUser(selectedOption.value);
            }
        } else {
            console.log('No user selected');
        }
    };
    return (
        <div>
            <AsyncSelect
                cacheOptions
                noOptionsMessage={() => 'Please continue typing a name to search...'}
                loadOptions={handleInputChange}
                defaultOptions
                placeholder="Please start typing a name to search..."
                onChange={handleUserSelect}
            />
            <Typography variant='caption' color='textSecondary' paragraph>Tip: When searching for a user with a 'Dr.' title, begin your entry with 'Dr. ' for accurate results.</Typography>
        </div>
    );
};

export default UserAutocomplete;
