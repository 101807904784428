import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ICategory, IMiscExpense } from '../../../index';

interface IMiscExpenseFormProps extends WithStyles<typeof styles> {
    miscExpense?: IMiscExpense;
    isAuditor: boolean;
    handleMiscExpenseChange: any;
    showMiscExpense: boolean;
    miscExpenseCategories: ICategory[];
}
interface IMiscExpenseFormState {
    miscExpense: any;
}

class MiscExpenseForm extends React.Component<IMiscExpenseFormProps, IMiscExpenseFormState> {
    constructor(props?: (IMiscExpenseFormProps)) {
        super(props as any);

        this.state = {
            miscExpense: null
        };

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e: any, isCategoryChange: boolean) {

        let miscExpense = this.state.miscExpense;

        this.setState({
            miscExpense: miscExpense
        });
   
        this.props.handleMiscExpenseChange(e, isCategoryChange);
    };
    render() {

        const { classes } = this.props as any;

        return <div className={classes.pad} style={{ 'display': this.props.showMiscExpense ? 'inline-block' : 'none' }}>
            <Paper className={classes.padding} elevation={4}>

                <Typography variant='body1' align='center' color='textPrimary' gutterBottom>
                    * Please fill out the following information:
                </Typography>

                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='expenseItem'
                        onChange={(e: any) => this.handleChange(e, false)}
                        required
                        value={this.props.miscExpense && this.props.miscExpense.expenseItem || ''}
                        label='Expense Item:'
                    />
                </div>
                <div className={classes.pad}>
                    <TextField className={classes.textField}
                        fullWidth
                        type='string'
                        name='vendor'
                        required
                        onChange={(e: any) => this.handleChange(e, false)}
                        value={this.props.miscExpense && this.props.miscExpense.vendor || ''}
                        label='Vendor Name:'
                    />
                </div>
                <div className={classes.pad}>
                    <FormControl className={classes.fullWidth}>
                        <InputLabel>Misc. Category</InputLabel>
                        <Select fullWidth value={(this.props.miscExpense && this.props.miscExpense.categoryId) || ''} required
                            onChange={(e: any) => this.handleChange(e, true)}>

                            {this.props.miscExpenseCategories.map((c: any) =>
                                <MenuItem key={c.categoryId} value={c.categoryId}>{`${c.name}`}</MenuItem>
                            )}

                        </Select>
                    </FormControl>
                </div>

                <div className={classes.pad} style={{
                    'display':
                        this.props.isAuditor && this.props.miscExpense && this.props.miscExpense.name === 'Other Admin Expense' ? 'block' : 'none'
                }}>
                    <TextField className={classes.textField}
                        fullWidth
                        helperText='overridable'
                        type='string'
                        name='account'
                        onChange={(e: any) => this.handleChange(e, false)}
                        value={this.props.miscExpense && this.props.miscExpense.account || ''}
                        label='Account # '
                    />
                </div>
            </Paper>
        </div>;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `0 0`,
    },
    textfield: {
        
        width: 200,
    },
    padding: {
        padding: 20
    },
    pad: {
        width: '100%',
        paddingTop: '50px',
        paddingBottom: '50px'
    },
    fullWidth: {
        width: '100%'
    }
});

export default withStyles(styles)(MiscExpenseForm);