import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Trip from './Trip/TripIndex';

export class Home extends React.Component<RouteComponentProps<{}>, {}> {    
       
    constructor(props: any) {
        super(props as any);
    }
    public render() {
        return <div>
            <Trip />
        </div>;
    }
}
