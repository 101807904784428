import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {IMileageRate} from '../../index';

interface IMileageRateTextProps {
    mileageRate: IMileageRate;
}

const MileageRateText = (props: IMileageRateTextProps) => {

    const { amount, effectiveDate } = props.mileageRate;

    return <div>

        <Typography variant='subtitle1' align='center'>*Effective {effectiveDate}, the personal car reimbursement allowance
                   per mile is {amount}</Typography>

        <Typography variant='body1' align='center'>Meal allowances are now based on the IRS Per Diem Rates for travel in the United States and abroad. Please check the&nbsp;<a target='_blank' rel='noopener noreferrer' href='http://www.gsa.gov/perdiem'>GSA
                           website</a> at for guidance on these rates. These expenses are reimbursed in full if travel begins before or ends after normal
                       meal hours. There is no meal allowance if the meal is part of an entertainment expense.</Typography>

        <Typography variant='body2' align='center' style={{'marginTop':'20px'}}>
            ITEMIZED RECEIPTS MUST BE PROVIDED FOR ALL EXPENSES INCLUDING MEALS. Charges paid for with the P-Card
                       should not be included within this form. Obtain travel pre-authorization on this form before your departure. Submit your
                       expense report for reimbursement, with your itinerary, within one week after your return.</Typography>

    </div>;
}

export default MileageRateText;