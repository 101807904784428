import * as EXIF from 'exif-js';
import { Reducer } from 'redux';
import {
    PdfAction
} from './pdfActions';
import { Api } from '../../api/Api';
import { IReceiptMetadata } from '../../components/index';
import {
    dataURLtoFile
} from '../../constants/constants';
import { AppThunkAction } from '../../store/index';
import { deleteBlob, uploadBlob } from '../utils/blobHelper';
import * as tripActions from '../trips/tripReducer';

const api = new Api();

type KnownAction = PdfAction;

export interface IPdfState {
    selectedFilesToUpload: any[];
    uploadPreviewImages: any[];
    isThumbnailLoadComplete: boolean;
    loadingImageKey: string;
    blobNameUrl: string;
    isLoadingPdf: boolean;
}

export const unloadedState: IPdfState = {
    selectedFilesToUpload: [],
    uploadPreviewImages: [],
    isThumbnailLoadComplete: true,
    loadingImageKey: '',
    blobNameUrl: '',
    isLoadingPdf: false
}

export const actionCreators = {
    createPdfOnTabChange: (e: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        await api.getPdf(getState().trip.selectedTrip.id)
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: 'GET_BLOB_NAME_URL',
                    blobNameUrl: data
                });
            });
    },
    clearSelectedFiles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CLEAR_SELECTED_FILES',
            selectedFilesToUpload: []
        });
    },
    fileUploadHandler: (selectedFiles: FileList): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // store the actual files being uploaded.        
        let files = getState().pdf.selectedFilesToUpload;
        const currentUser = getState().auth.currentUser;

        // store the image previews.
        let imagePreviews: any = [];
        const albumName = `${currentUser.email}/${getState().trip.selectedTrip.name}_${getState().trip.selectedTrip.id}`;
        const key = `${albumName}/${Math.floor(Date.now() / 1000)}_${selectedFiles[0].name}`;

        // show spinner.
        dispatch({
            type: 'UPLOAD_FILES',
            isThumbnailLoadComplete: false,
            loadingImageKey: key
        });

        const reader = new FileReader();
        reader.onload = (e: any) => {

            // base64
            let tempImg: any = new Image();
            tempImg.src = reader.result;
            tempImg.name = selectedFiles[0].name;

            // get the orientation of the image.
            tempImg.onload = () => {

                EXIF.getData(tempImg, function (this: any) {

                    let orientation = EXIF.getTag(this, 'Orientation');
                    //console.log('Exif=', orientation);

                    // create a canvas element hold the Image.
                    // https://stackoverflow.com/questions/20600800/js-client-side-exif-orientation-rotate-and-mirror-jpeg-images
                    let canvas: any = document.createElement('canvas');
                    if (4 < orientation && orientation < 9) {
                        canvas.width = tempImg.height;
                        canvas.height = tempImg.width;
                    } else {
                        canvas.width = tempImg.width;
                        canvas.height = tempImg.height;
                    }

                    // create a context to draw the image.
                    let ctx: any = canvas.getContext('2d');

                    // save the unrotated context of the canvas so we can restore it later.
                    SetOrientation(orientation, ctx, tempImg);

                    // draw the image on a rotated canvas.
                    ctx.drawImage(tempImg, 0, 0);

                    // rotate the canvas back.
                    ctx.restore();

                    // add the rotated file.
                    const rotatedFile = dataURLtoFile(canvas.toDataURL(selectedFiles[0].type), tempImg.name);
                    files = [rotatedFile];
                    dispatch({ type: 'FILE_UPLOAD_HANDLER_SUCCESS', selectedFilesToUpload: files });

                    // add to preview.
                    imagePreviews.push({ src: tempImg.src });

                    // set the current metadata's image link to empty so it will refresh properly.
                    let existingMetadata = getState().trip.selectedReceiptMetadata;
                    existingMetadata.key = key;
                    existingMetadata.thumbnailLink = null;

                    let trip = getState().trip.selectedTrip;

                    let index = trip.receiptMetadata.findIndex(x => x.receiptMetadataId ===
                        existingMetadata.receiptMetadataId);

                    trip.receiptMetadata[index].key = key;
                    trip.receiptMetadata[index].link = '';
                    trip.receiptMetadata[index].thumbnailLink = '';

                    // save the file to azure storage blog container
                    let file = getState().pdf.selectedFilesToUpload[0];
                    getState().trip.selectedReceiptMetadata.key = key;

                    uploadBlob(key, file).then(() => {
                        dispatch({
                            type: 'UPLOAD_FILES_SUCCESS',
                            loadingImageKey: key,
                            isThumbnailLoadComplete: true
                        });

                        // update the trip on success.
                        return (tripActions.actionCreators.updateReceiptMetadata(trip.receiptMetadata[index]))(dispatch as any, getState);

                    }).catch((error) => {
                        //console.log('An error occurred:', error);
                    });
                    let inputEl: any = document.getElementById('fileInput');
                    inputEl.value = null;
                });
            }
        };

        reader.readAsDataURL(selectedFiles[0]);
    },

    removeImage: (receiptMetadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip;

        deleteBlob(receiptMetadata.key).then(() => {
            // Update PDF metadata after successful deletion
            let meta = {
                receiptMetadataId: receiptMetadata.receiptMetadataId,
                key: null
            };

            api.updateImage(meta).then(() => {
                (tripActions.actionCreators.getTrip(trip.selectedTrip.id))(dispatch as any, getState);
            });

        }).catch((error) => {
            //  console.log('An error occurred:', error);
        });

        dispatch({
            type: 'REMOVE_PDF_ATTACHMENT'
        });
    },
    removePdfAttachment: (event: any, receiptMetadata: IReceiptMetadata): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let trip = getState().trip;
        deleteBlob(receiptMetadata.pdfKey).then(() => {
            // Update PDF metadata after successful deletion
            let meta = {
                receiptMetadataId: receiptMetadata.receiptMetadataId,
                pdfKey: null
            };

            api.updatePdf(meta).then((data) => {
                (tripActions.actionCreators.getTrip(trip.selectedTrip.id))(dispatch as any, getState);
            });

        }).catch((error) => {
            //  console.log('An error occurred:', error);
        });

        dispatch({
            type: 'REMOVE_PDF_ATTACHMENT'
        });
    },
    uploadPdfAttachment: (selectedFiles: FileList): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedTrip = getState().trip.selectedTrip;
        let state = getState().trip;

        const albumName = `${getState().auth.currentUser.email}/${selectedTrip.name}_${selectedTrip.id}`;
        const file = selectedFiles[0];

        let fileName = `${new Date().getTime().toString()}_${file.name}`
            .replace(/#/g, '')
            .replace(/&/g, '');

        let key = `${albumName}/pdfs/${fileName}`;
      
        const reader = new FileReader();
        reader.onload = () => {

            // if it's not a PDF, return.
            if (file.type !== 'application/pdf') {
                return;
            }

            // save the file to azure storage blog container
            uploadBlob(key, file)
                .then(() => {
                    let pdf: any = {
                        receiptMetadataId: state.selectedReceiptMetadata.receiptMetadataId,
                        pdfKey: `${albumName}/pdfs/${fileName}`
                    };

                    api.updatePdf(pdf).then(() => {
                        dispatch({
                            type: 'UPLOAD_PDF_ATTACHMENT_SUCCESS',
                            selectedReceiptMetadata: state.selectedReceiptMetadata,
                            isLoadingPDF: false
                        });

                        let inputEl: any = document.getElementById('pdfFileInput');
                        inputEl.value = null;

                        (tripActions.actionCreators.getTrip(state.selectedTrip.id))(dispatch as any, getState);
                    });

                })
                .catch((error) => {
                    dispatch({
                        type: 'UPLOAD_PDF_ATTACHMENT_SUCCESS',
                        selectedReceiptMetadata: state.selectedReceiptMetadata,
                        isLoadingPDF: false
                    });
                    // console.log('An error occurred:', error);
                });
        };

        reader.readAsDataURL(selectedFiles[0]);

        dispatch({
            type: 'UPLOAD_PDF_ATTACHMENT',
            isLoadingPDF: true
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IPdfState> = (state: IPdfState, action: KnownAction) => {

    switch (action.type) {
        case 'GET_BLOB_NAME_URL': {
            return {
                ...state,
                blobNameUrl: action.blobNameUrl
            }
        }
        case 'CLEAR_SELECTED_FILES': {
            return {
                ...state,
                selectedFilesToUpload: action.selectedFilesToUpload
            }
        }
        case 'UPLOAD_PDF_ATTACHMENT': {
            return {
                ...state,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                isLoadingPdf: action.isLoadingPDF              
            }
        }
        case 'UPLOAD_PDF_ATTACHMENT_SUCCESS': {
            return {
                ...state,
                selectedReceiptMetadata: action.selectedReceiptMetadata,
                isLoadingPdf: action.isLoadingPDF,
                pdfDirty: true
            }
        }
        case 'FILE_UPLOAD_HANDLER_SUCCESS': {
            return {
                ...state,
                selectedFilesToUpload: action.selectedFilesToUpload,
                pdfDirty: true
            }
        }
        case 'FILE_UPLOAD_HANDLER': {
            return {
                ...state,
                selectedFilesToUpload: action.selectedFilesToUpload
            }
        }
        case 'UPLOAD_FILES': {
            return {
                ...state,
                existingMetadata: action.existingMetadata,
                loadingImageKey: action.loadingImageKey,
                isThumbnailLoadComplete: action.isThumbnailLoadComplete
            }
        }
        case 'UPLOAD_FILES_SUCCESS': {
            return {
                ...state,
                loadingImageKey: action.loadingImageKey,
                isThumbnailLoadComplete: action.isThumbnailLoadComplete,
                pdfDirty: true
            }
        }

        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};


function SetOrientation(orientation: any, ctx: any, tempImg: any) {
    switch (parseInt(orientation)) {
        case 2: ctx.transform(-1, 0, 0, 1, tempImg.width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, tempImg.width, tempImg.height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, tempImg.height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, tempImg.height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, tempImg.height, tempImg.width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, tempImg.width); break;
    }
}
