import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormGroup, FormControlLabel, Toolbar, Typography, Switch, Tab, Tabs, AppBar, Button, Grid, IconButton, Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListAltIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import WarningIcon from '@material-ui/icons/Warning';
import smoothscroll from 'smoothscroll-polyfill';
import { Api, hasValidSession } from '../../api/Api';
import { USD } from '../../constants/constants';
import { ApplicationState } from '../../store/index';
import * as TripStore from '../../store/trips/tripReducer';
import * as AuthStore from '../../store/auth/authReducer';
import * as PdfStore from '../../store/pdfs/pdfReducer';
import ExpenseForm from '../Expenses/Form/ExpenseForm';
import ExpenseIndex from '../Expenses/ExpenseIndex';
import StatusTab from '../Status/StatusTab';
import SummaryTab from '../Summary/SummaryTab';
import { IReceiptMetadata } from '../index';
import TripCard from './Card/TripCard';
import TripForm from './Form/TripForm';
import TripHeader from './Header/TripHeader';
import TripSelect from './Select/TripSelect';
import ScrollButton from './Scroll/ScrollButton';
import Carousel from './Carousel/Carousel';
import { ITripState } from '../../store/trips/tripState';
import { NavLink } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
    appBar: {
        backgroundColor: theme.palette.primary.main
    },
    root: {
        fontSize: 10,
        width: '100%',
    },
    tabs: {
        flexGrow: 1,
        background: theme.palette.primary.main,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white'
    },
    pad: {
        paddingTop: '50px'
    },
    rightToolbar: {
        float: 'right',
        color: '#fff',
        marginLeft: '50px'
    },
    success: {
        background: 'green'
    },
    media: {
        height: 0,
        minHeight: 200,
        paddingTop: '36.25%', // 16:9
    },
    noDecorationLink: {
        textDecoration: 'none',

    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer'
    },
});

function TabContainer(props: any) {
    return (
        <Typography component='div' style={{ padding: 10 }}>
            {props.children}
        </Typography>
    );
}

type ITripProps =
    AuthStore.IAuthState &
    PdfStore.IPdfState &
    ITripState
    & ITripLocalProps
    & typeof AuthStore.actionCreators
    & typeof PdfStore.actionCreators
    & typeof TripStore.actionCreators & RouteComponentProps<{}>;

interface ITripLocalProps {
    handleLogout: any;
}

class Trip extends React.Component<ITripProps, {}> {
    api: Api;

    constructor(props?: (ITripProps)) {
        super(props as any);

        this.api = new Api();
      
        this.editReceiptForm = this.editReceiptForm.bind(this);
        this.isCurrencyUsd = this.isCurrencyUsd.bind(this);    
        this.isTripSelected = this.isTripSelected.bind(this);
        this.updateReceiptMetadata = this.updateReceiptMetadata.bind(this);
        this.viewAsManager = this.viewAsManager.bind(this);
        this.viewAsEmployee = this.viewAsEmployee.bind(this);
        this.viewAsAuditor = this.viewAsAuditor.bind(this);
        this.handleCloseReceiptForm = this.handleCloseReceiptForm.bind(this);
        this.calculateForex = this.calculateForex.bind(this);
    }

    async componentDidMount() {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');

        // if url has manager param, then we are coming from an email link.      
        const isManagerApprovalFromEmail = url.searchParams.get('manager');

        if (hasValidSession() || window.localStorage.getItem('id_token')) {
            await this.props.initializeApp();
        }

        if (code) {
            try {
                await this.props.getAuthenticationToken(code);

                // clear the code from url
                window.history.replaceState({}, document.title, '/');

                // get the users info from database if valid session.
                if (hasValidSession()) {
                    await this.props.initializeApp();
                }

                // clear form so it's ready to go.
                this.props.clearMetadataForm();

            }
            catch (e) {
                // if the user is not authenticated, redirect to login page.
                sessionStorage.removeItem('sessionToken');

                // clear the code from url
                window.history.replaceState({}, document.title, '/');
            }
        }

        if (isManagerApprovalFromEmail) {
            const managerTripId = url.searchParams.get('tripId');
            if (managerTripId) {
                await this.viewAsManager();
                // if the tripId provided is assigned to the current user (manager), then select it.
                const trip = this.props.trips.find(x => x.id === parseInt(managerTripId));
                if (trip) {
                    this.props.getTrip(parseInt(managerTripId));
                }
            }
        }
    }

    async viewAsManager() {
        await this.props.getTripsForManager(this.props.currentUser?.email);
    }
    viewAsEmployee() {
        this.props.getTrips();
    }
    viewAsAuditor() {
        this.props.getTripsForAuditor();
    }

    // receipt form event handlers    
    editReceiptForm(metadata: IReceiptMetadata) {
        this.props.showEditReceiptForm();
        this.props.selectReceiptMetadata(metadata);
    }
    handleCloseReceiptForm() {
        this.props.getTrip(this.props.selectedTrip.id);
        this.props.handleCloseReceiptForm();
    }

    isTripSelected() {
        return this.props.selectedTrip.id > 0;
    }
    isCurrencyUsd(): boolean {
        return this.props.selectedReceiptMetadata.currencyName === USD;
    }
    calculateForex(metadata: IReceiptMetadata): string {
        if (!metadata.amountTotalForeignCurrency || !metadata.amountTotalForeignCurrency || !metadata.expensedTotal) {
            return 'N/A';
        }

        return (metadata.expensedTotal / metadata.amountTotalForeignCurrency).toFixed(4);
    }
    isValidExpense(metadata: IReceiptMetadata): boolean {
        return metadata.dateOfReceipt && metadata.categories.length > 0;
    }
    isUpdatingExistingMetadata = (metadata: any) => metadata.receiptMetadataId > 0;
  
    updateReceiptMetadata(metadata: IReceiptMetadata, possibleDuplicateIsOk: boolean = false) {

        if (this.isValidExpense(metadata)) {
            metadata.possibleDuplicateIsOk = possibleDuplicateIsOk;

            this.props.updateReceiptMetadata(metadata);

            setTimeout(() => {

                // scroll to the updated receipt
                if (metadata.receiptMetadataId > 0 && this.props.lastUpdatedMetadataId > 0) {
                    const i: number = this.props.selectedTrip.receiptMetadata.findIndex(x => x.receiptMetadataId === this.props.lastUpdatedMetadataId);
                    const node: any = this.props.selectedTrip.receiptMetadata[i];

                    setTimeout(() => {
                        smoothscroll.polyfill();
                        if (node) {
                            const element: any = document.getElementById(node.receiptMetadataId);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' }); // note: ios doesn't support { behavior: 'smooth' }
                            }
                        }
                    }, 500);
                }
            }, 1000);
        }
    }

    render() {

        const { classes, selectedTabValue } = this.props as any;
        const receiptMetadata = this.props.selectedTrip && this.props.selectedTrip.receiptMetadata;

        let { filteredReceiptMetadata } = this.props;

        if (!filteredReceiptMetadata) {
            filteredReceiptMetadata = receiptMetadata;
        }

        if (!this.props.currentUser) {
            return <AppBar position='static' className={classes.appBar}>
                <Toolbar>
                    <IconButton className={classes.menuButton} color='inherit' aria-label='Menu' >

                    </IconButton>
                    <Typography id='stage' variant='subtitle1' className={classes.flex} color='inherit' onClick={this.props.reload}>
                        SCHOTT Travel Expense Web App
                    </Typography>
                </Toolbar>
            </AppBar>;
        }

        return <main>
            <div>
                <TripHeader handleLogout={this.props.logout} />

                <main style={{ 'display': this.props.selectedTrip.id === 0 && this.props.currentUserHasProfile ? 'block' : 'none' }}>
                    <Typography style={{ 'display': (!this.props.isEmployeeView) ? 'none' : 'block' }} className={classes.pad} variant='h6' align='center' color='textSecondary' paragraph>
                        Please create a <br />
                        <Button size='small' variant='outlined' style={{ color: '#006fbb', marginRight: 10 }} className={classes.link} onClick={this.props.handleNewTrip}>new trip</Button>
                        or
                        <Button size='small' variant='outlined' style={{ color: '#006fbb', marginLeft: 10 }} className={classes.link}>
                            <NavLink to='/checkrequest' className={classes.noDecorationLink}>
                                check request
                            </NavLink>
                        </Button>
                        <br /> or select an existing trip below:
                    </Typography>
                </main>
             
                { /* Trip Selection */}
                <div style={{ display: this.props.currentUserHasProfile ? 'inherit' : 'none' }}>

                    <Grid container justifyContent='center'>
                        <Grid item xs={12} md={8}>

                            <TripSelect
                                isAuditorView={this.props.isAuditorView}
                                isManagerView={this.props.isManagerView}
                                selectedTrip={this.props.selectedTrip}
                                trips={this.props.trips}
                                getTrip={(e: any) => this.props.getTrip(e)} />

                            { /* Toggle Employee Role View */}
                            <div
                                style={{ 'textAlign': 'center', 'display': !this.props.isEmployeeView ? 'block' : 'none' }}>
                                <Button color='primary' onClick={this.viewAsEmployee}>
                                    <ArrowForwardIcon /> Switch To: My Own Trips
                                </Button>
                            </div>

                            { /* Toggle Manager Role View */}
                            <div
                                style={{ 'textAlign': 'center', 'display': !this.props.isManagerView ? 'block' : 'none' }}>
                                <Button color='secondary' onClick={this
                                    .viewAsManager}>
                                    <ArrowForwardIcon /> Switch To: Approvals Due ({this.props.managerTripsCount || '0'})
                                </Button>
                            </div>

                            { /* Toggle Auditor Role View */}
                            <div
                                style={{
                                    'textAlign': 'center',
                                    'display': this.props.currentUser.isAuditor && !this.props.isAuditorView ? 'block' : 'none'
                                }}>
                                <Button color='secondary' onClick={this.viewAsAuditor}>
                                    <ArrowForwardIcon /> Switch To: Approvals Due (Auditor)
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    { /* Carousel */}
                    <Carousel selectedTrip={this.props.selectedTrip} />

                    <Grid container justifyContent='center' style={{ 'display': this.props.selectedTrip.id === 0 ? 'none' : 'inline-flex' }}>
                        <Grid item xs={12} sm={6}>
                     
                            { /* Trip Card */}
                            <TripCard
                                submittedBy={this.props.selectedTrip.employeeName}
                                selectedTrip={this.props.selectedTrip}
                                handleEditTrip={this.props.handleEditTrip} />

                            { /* Trip Form */}
                            <Dialog fullScreen open={this.props.showTripForm} onClose={this.props.handleCloseTripForm}>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton color='inherit' onClick={this.props.handleCloseTripForm} aria-label='Close'>
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant='body1'>
                                            Trip Form
                                        </Typography>

                                        { /* Surrogate switch */}
                                        <section className={classes.rightToolbar}>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={this.props.submitAsSurrogate}
                                                            onChange={this.props.toggleSurrogate}
                                                            value='checkedA'
                                                        />
                                                    }
                                                    label={<Typography variant='body1' align='center' style={{ 'color': 'white' }}>Act as Surrogate</Typography>}
                                                />
                                            </FormGroup>
                                        </section>
                                    </Toolbar>
                                </AppBar>
                                <DialogContent>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12} md={5}>
                                            { /* Trip Form */}
                                            <TripForm
                                                deleteTrip={this.props.deleteTrip}
                                                saveNewTrip={this.props.saveTrip}
                                                updateCurrentTrip={this.props.updateTrip}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </Grid>
                </div>

                { /* Expense Form */}
                <div style={{ display: !this.isTripSelected() ? 'none' : 'inline-block' }}>
                    <ExpenseForm
                        handleTripCloseReceiptForm={this.handleCloseReceiptForm}
                        updateTripReceiptMetadata={this.updateReceiptMetadata}
                    />
                </div>

                <Grid container justifyContent='center' style={{ 'display': !this.isTripSelected() ? 'none' : 'inline-flex', padding: 10 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper elevation={4}>
                            <div className={classes.root}>
                                {/* Tabs */}
                                <Tabs className={classes.tabs} style={{ 'fontSize': '6em' }}
                                    indicatorColor='secondary'
                                    value={selectedTabValue}
                                    onChange={this.props.handleTabChangeAndPreparePDFReport} centered>
                                    <Tab style={{ 'marginRight': '12px' }} icon={<AttachMoneyIcon />} label='Expenses' id='expenses' />
                                    <Tab style={{ 'marginRight': '12px' }} icon={<ListAltIcon />} label='Summary' />
                                    <Tab icon={<EmailIcon />} label='Process' />
                                </Tabs>

                                {selectedTabValue === 0 &&
                                    <TabContainer style={{ 'minHeight': '400px' }}>
                                        {/* Expenses */}
                                        <ExpenseIndex
                                            editReceiptForm={this.editReceiptForm}
                                            handleCloseReceiptForm={this.handleCloseReceiptForm}                                           
                                            receiptMetadata={filteredReceiptMetadata}
                                        />

                                        {/* Scroll to Top */}
                                        <div style={{ 'visibility': this.props.selectedTrip.receiptMetadata && this.props.selectedTrip.receiptMetadata.length > 1 ? 'visible' : 'hidden' }}>
                                            <ScrollButton scrollStepInPx={50} delayInMs={5.22} />
                                        </div>
                                    </TabContainer>
                                }
                                {selectedTabValue === 1 &&
                                    <TabContainer>
                                        {/* Summary */}
                                        <SummaryTab
                                            calculateForex={this.calculateForex}
                                            receiptMetadata={receiptMetadata}
                                        />
                                    </TabContainer>}
                                {selectedTabValue === 2 &&
                                    <TabContainer>
                                        {/* Status */}
                                        <StatusTab
                                        />
                                    </TabContainer>}
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                {/* Messages */}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.props.showMessage || this.props.showValidationError}
                    autoHideDuration={2000}
                    onClose={this.props.handleMessageClose}
                >
                    <SnackbarContent
                        aria-describedby='client-snackbar'
                        message={
                            <p style={{ textAlign: 'center' }} id='client-snackbar' className={classes.message}>
                                {this.props.message}
                            </p>
                        }
                    />
                </Snackbar>

                {/* Duplication confirmation message */}
                <Dialog
                    open={this.props.showDuplicationWarning}
                    onClose={this.props.handleMessageClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>{'Possible duplicate warning'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            The <strong>amount</strong> and <strong>date</strong> of this expense exactly matches another expense in one of your trips.
                            <br />   <br />
                            If you'd still like to proceed, press OK.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleMessageClose} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateReceiptMetadata(this.props.selectedReceiptMetadata, true)} color='primary' autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </main>
    }
}

export default compose(connect(
    (state: ApplicationState) => state.auth,
    AuthStore.actionCreators
), connect(
    (state: ApplicationState) => state.pdf,
    PdfStore.actionCreators
), connect(
    (state: ApplicationState) => state.trip,
    TripStore.actionCreators
), withStyles(styles))(Trip) as any;

