import { IFilterSelect, IReceiptMetadata, ICurrency, ICategory, ICategorySummary, IDailySummary, IBusinessEntertainmentExpense, ITrip, IMiscExpense,IMileageRate,IPersonalCarMileage } from '../../components';
import { defaultBusinessEntertainmentExpense, defaultCategory, defaultMiscExpense, defaultPersonalCarMileage, defaultSelectedReceiptMetadata, defaultSelectedTrip } from '../../constants/TripDefaults';

export interface ITripState {
    offByFC: string;
    offBy: string;
    foreignCurrencyAmountIsValid: boolean;
    isTripRefreshing: boolean;
    pdfDirty: boolean;  
    viewUnapprovedOnly: boolean;
    submitAsSurrogate: boolean;
    isManagerView: boolean;
    isEmployeeView: boolean;
    fxRate: any;
    isAuditorView: boolean;
    managerTripsCount: number;
    submittedOnBehalfOfEmail: string;
    submittedOnBehalfOfName: string;
    submittedOnBehalfOf: IFilterSelect | any;
    showValidationError: boolean;
    showDuplicationWarning: boolean;
    message: string;
    showMessage: boolean;
    lastUpdatedMetadataId: any;
    showTravelAdvanceForm: boolean;
    currencies: ICurrency[];
    selectedTabValue: number;
    defaultCategories: ICategory[];
    specialCategories: ICategory[];  
    isSaving: boolean;
    isLoading: boolean;
    isEditingTrip: boolean;
    showMetadataForm: boolean;
    showTripForm: boolean;   
    selectedTrip: ITrip;
    filteredReceiptMetadata: IReceiptMetadata[] | null;
    trips: ITrip[];
    managerTrips: ITrip[];
    auditorTrips: ITrip[];    
    selectedReceiptMetadata: IReceiptMetadata;   
    existingReceipts: any[];
    categories: ICategory[];
    miscExpenseCategories: ICategory[]; 
    dateValue: IFilterSelect | null;
    expensedAmount: number;
    expensedAmountForeignCurrency: number;
    categoryValue: any;
    showOriginalCurrencyAmount: boolean;
    showBusinessExpense: boolean;
    showMiscExpense: boolean;
    showPersonalCarMileage: boolean;
    showAmountTotalForeignCurrency: boolean;
    selectedCategory: ICategory | any;
    businessEntertainmentExpense: IBusinessEntertainmentExpense;
    miscExpense: IMiscExpense;
    isMiscExpenseDirty: boolean;
    isBusinessExpenseDirty: boolean; 
}

export const unloadedState: ITripState = {
    offByFC: '',
    offBy: '',
    showOriginalCurrencyAmount: true,
    foreignCurrencyAmountIsValid: true,
    isTripRefreshing: false,
    pdfDirty: false,   
    viewUnapprovedOnly: false,
    submitAsSurrogate: false,
    isEmployeeView: true,
    isManagerView: false,
    isAuditorView: false,
    fxRate: '',
    managerTripsCount: 0,
    submittedOnBehalfOfEmail: '',
    submittedOnBehalfOfName: '',
    submittedOnBehalfOf: null,
    showValidationError: false,
    showDuplicationWarning: false,
    message: '',
    showMessage: false,
    lastUpdatedMetadataId: 0,
    showTravelAdvanceForm: false,
    showMetadataForm: false,
    selectedTabValue: 0,
    expensedAmount: 0,
    expensedAmountForeignCurrency: 0,
    filteredReceiptMetadata: null,
    isEditingTrip: false,
    isSaving: false,
    showTripForm: false,
    isMiscExpenseDirty: false,
    isBusinessExpenseDirty: false,   
    categoryValue: '',   
    businessEntertainmentExpense: { ...defaultBusinessEntertainmentExpense },
    miscExpense: { ...defaultMiscExpense },
    currencies: [],
    selectedCategory: { ...defaultCategory[0] },
    showMiscExpense: false,
    showBusinessExpense: false,
    showAmountTotalForeignCurrency: false,
    showPersonalCarMileage: false,
    dateValue: null,
    selectedTrip: { ...defaultSelectedTrip },
    selectedReceiptMetadata: { ...defaultSelectedReceiptMetadata },
    trips: [],
    managerTrips: [],
    auditorTrips: [],
    isLoading: true,  
    existingReceipts: [],
    defaultCategories: [],
    specialCategories: [],
    categories: [],
    miscExpenseCategories: [],
};