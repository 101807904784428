import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import {
    CheckIfController, RemovePdfAttachment, UploadPdfAttachment, UploadPdfAttachmentSuccess,
    HandleShowCheckRequestDialog, SetEditMode, HandleShowCheckRequestApprovalForm, GetApproverList,
    CreateCheckRequest, CreateCheckRequestError, CreateCheckRequestSuccess, UpdateCheckRequest, GetCheckRequests,
    GetCheckRequestApprovals, HandleCheckRequestChange
} from './checkRequestActions';
import { Api, getSessionInfo } from '../../api/Api';
import { ICheckRequestApprover, ICheckRequest } from '../../components';
import { AppThunkAction } from '../index';
import moment from 'moment';

const api = new Api();
const { BlobServiceClient } = require('@azure/storage-blob');

enum CheckRequestMode {
    create = 1,
    read,
    update
}

enum CheckRequestStatus {
    draft = 1,
    submitted,
    approved,
    processed
}

export const defaultCheckRequest: ICheckRequest = {
    checkRequestId: 0,
    checkRequestDate: moment().format(),
    companyNumber: 0,
    invoiceDisplay: '',
    amount: 0,
    currencyId: 1,
    vendorNumber: '0',
    achBlock: '0',
    approvalStatusId: 1,
    approvedByEmail: '',
    managerApprovedBy: '',
    currencyName: '',
    payableTo: '',
    address: '',
    purpose: '',
    costCenter: '',
    costElement: '',
    requestedBy: '',
    requestedByEmail: '',
    approvedBy: '',
    pdfKey: '',
    pdfFileName: '',
    denyComments: '',
    approvalStatusName: 'Draft',
    returnTheCheckTo: ''
};

export interface ICheckRequestState {
    mode: CheckRequestMode;
    status: CheckRequestStatus;
    selectedCheckRequest: ICheckRequest;
    checkRequestsOwnedByUser: ICheckRequest[];
    checkRequestsToApprove: ICheckRequest[];
    approverList: ICheckRequestApprover[];
    controllerList: ICheckRequestApprover[];
    isLoading: boolean;
    isEditing: boolean;
    isSavingCheckRequest: boolean;
    showValidationError: boolean;
    showMessage: boolean;
    message: string;
    showCheckRequestDialog: boolean;
    pdfTempFileName: string;
    denyComments: string;
    isController: boolean;
    selectedFiles: FileList;
}
export const unloadedState: ICheckRequestState = {
    mode: CheckRequestMode.create,
    status: CheckRequestStatus.draft,
    selectedCheckRequest: { ...defaultCheckRequest },
    checkRequestsOwnedByUser: [],
    checkRequestsToApprove: [],
    approverList: [],
    controllerList: [],
    isLoading: false,
    isEditing: false,
    isSavingCheckRequest: false,
    showValidationError: false,
    showMessage: false,
    message: '',
    showCheckRequestDialog: false,
    pdfTempFileName: '',
    denyComments: '',
    isController: false,
    selectedFiles: null
};

const accountName = 'expenseappreceipts';
const containerName = 'docs';

type KnownAction = CheckIfController | RemovePdfAttachment | UploadPdfAttachment | UploadPdfAttachmentSuccess | HandleShowCheckRequestDialog
    | SetEditMode | HandleShowCheckRequestApprovalForm | GetApproverList |
    CreateCheckRequest | CreateCheckRequestSuccess | GetCheckRequests | GetCheckRequestApprovals | UpdateCheckRequest | CreateCheckRequestError | HandleCheckRequestChange;

export async function getBlobSasToken(blobName) {
    if (!blobName) {
        return false;
    }
    return api.getBlobSasToken(blobName)
        .then((sasToken: any) => {
            return sasToken;
        });
}

async function uploadBlob(blobName, file) {

    getBlobSasToken(blobName)
        .then(async (sasToken: any) => {
            const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.uploadData(file);
        }).catch((error: any) => {
            // console.log(error);
        });
}
async function deleteBlob(blobName) {

    getBlobSasToken(blobName)
        .then(async (sasToken: any) => {
            const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${sasToken}`);
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            await blockBlobClient.delete();
        }).catch((error: any) => {
            // console.log(error);
        });
}
// function to verify valid check request.
function isValidCheckRequest(cr: ICheckRequest, selectedFiles: any) {

    let isValid = true;

    if (!cr.checkRequestDate) {
        isValid = false;
    }

    if (!cr.amount) {
        isValid = false;
    }

    if (!cr.currencyId) {
        isValid = false;
    }

    if (!cr.payableTo) {
        isValid = false;
    }

    if (!cr.address) {
        isValid = false;
    }

    if (!cr.purpose) {
        isValid = false;
    }

    if (!cr.requestedBy) {

        isValid = false;
    }
    if (!cr.requestedByEmail) {
        isValid = false;
    }

    return isValid;
}

export const actionCreators = {

    // EVENTS ------------------------------------------------------------------------------------------------------------------
    handleCreateCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // set to create mode, with the users name pre-selected as requested by.
        let currentUser = getSessionInfo();
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        if (currentUser && selectedCheckRequest) {
            selectedCheckRequest.requestedByEmail = currentUser.email;
            selectedCheckRequest.requestedBy = currentUser.name;
        }

        dispatch({
            type: 'HANDLE_SHOW_CHECKREQUEST_DIALOG',
            mode: CheckRequestMode.create,
            status: unloadedState.status,
            selectedCheckRequest: unloadedState.selectedCheckRequest
        });
    },

    handleViewCheckRequest: (selectedCheckRequest: ICheckRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // set current user as default.       
        let currentUser = getSessionInfo();

        if (currentUser) {
            selectedCheckRequest.requestedByEmail = currentUser.email;
            selectedCheckRequest.requestedBy = currentUser.name;
        }

        dispatch({
            type: 'HANDLE_SHOW_CHECKREQUEST_DIALOG',
            mode: selectedCheckRequest.invoiceDisplay
                && selectedCheckRequest.approvalStatusId === CheckRequestStatus.draft ? CheckRequestMode.create : CheckRequestMode.read,
            status: selectedCheckRequest.approvalStatusId,
            selectedCheckRequest: selectedCheckRequest
        });
    },

    handleOpenCheckRequestApprovalForm: (selectedCheckRequest: ICheckRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // event from Pending Your Approval list.         

        // if I'm a controller and it's been approved, set the approved by to my name.
        if (!getState().checkRequest.isController
            && selectedCheckRequest.approvalStatusId === CheckRequestStatus.submitted) {
            // they have to select a controller.
            selectedCheckRequest.approvedBy = '';
            selectedCheckRequest.approvedByEmail = '';
        }

        dispatch({
            type: 'HANDLE_SHOW_CHECKREQUEST_APPROVALFORM',
            mode: CheckRequestMode.update,
            status: selectedCheckRequest.approvalStatusId,
            selectedCheckRequest: selectedCheckRequest
        });
    },
    handleCloseCheckRequestApprovalForm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'HANDLE_SHOW_CHECKREQUEST_APPROVALFORM',
            mode: CheckRequestMode.create,
            status: unloadedState.status,
            selectedCheckRequest: unloadedState.selectedCheckRequest
        });

        (actionCreators.refresh())(dispatch, getState);
    },

    handleMessageClose: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CREATE_CHECKREQUEST_ERROR',
            isSavingCheckRequest: false,
            isLoading: false
        });
    },
    handleApprovedByEmailChange: (e: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        selectedCheckRequest.approvedByEmail = e.target.value;
        dispatch({
            type: 'HANDLE_CHECKREQUEST_CHANGE',
            selectedCheckRequest: { ...selectedCheckRequest }
        });
    },
    handleCheckRequestFormValueChange: (e: any, name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let value: any = null;
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        switch (name) {
            case 'checkRequestDate':
                value = moment(e).format('YYYY-MM-DD');
                break;
            case 'requestedBy':
                value = e;
                selectedCheckRequest.requestedByEmail = e;
                break;
            case 'approvedBy':
                value = e.label;
                selectedCheckRequest.requestedByEmail = e.value;
                break;
            case 'amount':
            case 'currencyId':
                value = parseFloat(e.target.value);
                break;

            default:
                value = e.target.value;
        }

        selectedCheckRequest[name] = value;

        dispatch({
            type: 'HANDLE_CHECKREQUEST_CHANGE',
            selectedCheckRequest: { ...selectedCheckRequest }
        });
    },

    // GET ------------------------------------------------------------------------------------------------------------------
    getCheckRequests: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let currentUser = getSessionInfo();

        let fetchTask = api.getCheckRequests(currentUser.email).then((checkRequestsOwnedByUser: any) => {
            dispatch({ type: 'GET_CHECKREQUESTS', checkRequestsOwnedByUser: checkRequestsOwnedByUser, isLoading: false });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_CHECKREQUESTS', checkRequestsOwnedByUser: [], isLoading: true });
    },
    getCheckRequestsToApprove: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let currentUser = getSessionInfo();

        // DEBUG: 
        /*
         * Uncomment this line to get check requests to approve for the provided email.
         * */
        //currentUser.email = 'danrbergman@gmail.com';

        let fetchTask = api.getCheckRequestsToApprove(currentUser.email).then((checkRequestsCurrentUser: any) => {
            dispatch({ type: 'GET_CHECKREQUEST_APPROVALS', checkRequestsToApprove: checkRequestsCurrentUser });
        });

        addTask(fetchTask);
        dispatch({ type: 'GET_CHECKREQUEST_APPROVALS', checkRequestsToApprove: [] });
    },
    getApproverList: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let currentUser = getSessionInfo();

        await api.getCheckRequestApproverList()
            .then((approverList: ICheckRequestApprover[]) => {

                // populate list of controllers only.
                let controllerList = approverList.filter(x => x.isController === true);

                // check if current user is controller.
                let controller = false;
                for (let i = 0; i < controllerList.length; i++) {
                    if (controllerList[i].value === currentUser.email) {
                        // if (controllerList[i].value == 'dan@lamparth2.com') // DEBUG: uncomment the line to impersonate a controller user. 
                        controller = true;
                    }
                }

                // TODO: clean up approver lists. Make sure no one can select themselves as the approver.
                //approverList = approverList.filter(x => x.value !== currentUser.email);
                //controllerList = controllerList.filter(x => x.value !== currentUser.email);

                //console.log(approverList);
                //console.log(controllerList);

                dispatch({ type: 'GET_APPROVER_LIST', approverList: approverList, controllerList: controllerList });
                dispatch({ type: 'CHECK_IF_CONTROLLER', isController: controller });

                return approverList;
            }).catch(error => {
                //console.error(error);                
            });
    },


    // SAVE & UPDATE  ------------------------------------------------------------------------------------------------------------------
    saveCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        if (!isValidCheckRequest(selectedCheckRequest, getState().checkRequest.selectedFiles)) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        }

        if (!getState().checkRequest.selectedFiles) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        }

        let companyNumber = getState().auth.currentUser.companyNumber;
        selectedCheckRequest.companyNumber = selectedCheckRequest.companyNumber > 0 ? selectedCheckRequest.companyNumber : companyNumber;

        // make sure we have a cost center when we save.
        if (!selectedCheckRequest.costCenter) {
            selectedCheckRequest.costCenter = getState().auth.currentUser.itCostCenter;
        }

        // save
        api.createCheckRequest(selectedCheckRequest)
            .then((checkRequestResult: any) => {

                if (checkRequestResult.message) {
                    dispatch({
                        type: 'CREATE_CHECKREQUEST_ERROR',
                        isLoading: false,
                        isSavingCheckRequest: false,
                        showValidationError: true,
                        showMessage: true,
                        message: checkRequestResult.message,
                        selectedCheckRequest: selectedCheckRequest
                    });

                } else {

                    // if we have a pdf that we haven't saved yet, upload it now.
                    if (getState().checkRequest.selectedFiles) {
                        (actionCreators.uploadPdfAttachment(getState().checkRequest.selectedFiles, checkRequestResult))(dispatch as any, getState);
                    }

                    (actionCreators.sendCheckRequestToApprover(checkRequestResult))(dispatch as any, getState);

                    dispatch({
                        type: 'CREATE_CHECKREQUEST_SUCCESS',
                        isLoading: false,
                        isSavingCheckRequest: false,
                        showValidationError: false,
                        showMessage: true,
                        isEditing: false,
                        message: 'Success! Check request has been saved.',
                        selectedFiles: unloadedState.selectedFiles,
                        selectedCheckRequest: unloadedState.selectedCheckRequest
                    });

                    (actionCreators.refresh())(dispatch, getState);
                }
            });



        dispatch({
            isSavingCheckRequest: true,
            type: 'CREATE_CHECKREQUEST',
            isLoading: true
        });
    },

    updateCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        if (!isValidCheckRequest(selectedCheckRequest, getState().checkRequest.selectedFiles)) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        }

        if (!getState().checkRequest.selectedFiles && !selectedCheckRequest.pdfKey) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        }
        (actionCreators.sendCheckRequestToApprover(selectedCheckRequest))(dispatch as any, getState);

        dispatch({
            type: 'UPDATE_CHECKREQUEST',
            isLoading: false,
            showValidationError: false,
            isSavingCheckRequest: true,
            isEditing: true,
            showMessage: false,
            message: '',
            selectedCheckRequest: selectedCheckRequest,
        });
    },

    deleteCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        let fetchTask = api.deleteCheckRequest(selectedCheckRequest.checkRequestId)
            .then((checkRequestResult: any) => {

                if (checkRequestResult.message) {
                    dispatch({
                        type: 'CREATE_CHECKREQUEST_ERROR',
                        isLoading: false,
                        isSavingCheckRequest: false,
                        showValidationError: true,
                        showMessage: true,
                        message: checkRequestResult.message,
                        selectedCheckRequest: selectedCheckRequest
                    });
                } else {

                    dispatch({
                        type: 'CREATE_CHECKREQUEST_SUCCESS',
                        isLoading: false,
                        isSavingCheckRequest: false,
                        showValidationError: false,
                        showMessage: true,
                        isEditing: false,
                        message: 'Success! Check request has been deleted.',
                        selectedFiles: unloadedState.selectedFiles,
                        selectedCheckRequest: unloadedState.selectedCheckRequest
                    });
                }

                (actionCreators.handleCreateCheckRequest())(dispatch, getState);
                (actionCreators.getCheckRequests())(dispatch, getState);
            });

        addTask(fetchTask);

        dispatch({
            isSavingCheckRequest: true,
            type: 'CREATE_CHECKREQUEST',
            isLoading: true
        });
    },

    sendCheckRequestToApprover: (selectedCheckRequest: ICheckRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = api.sendCheckRequestToApprover(selectedCheckRequest)
            .then(() => {

                dispatch({
                    type: 'UPDATE_CHECKREQUEST',
                    isLoading: false,
                    showValidationError: false,
                    isSavingCheckRequest: false,
                    isEditing: false,
                    showMessage: true,

                    message: 'Check Request has been submitted for approval!',
                    selectedCheckRequest: unloadedState.selectedCheckRequest,
                });

                (actionCreators.refresh())(dispatch, getState);
            });

        addTask(fetchTask);
    },

    sendCheckRequestToController: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        let fetchTask = api.sendCheckRequestToController(selectedCheckRequest)
            .then(() => {

                dispatch({
                    type: 'UPDATE_CHECKREQUEST',
                    isLoading: false,
                    showValidationError: false,
                    isSavingCheckRequest: false,
                    isEditing: false,
                    showMessage: true,
                    message: 'Check Request has been submitted for approval!',
                    selectedCheckRequest: unloadedState.selectedCheckRequest,
                });

                (actionCreators.refresh())(dispatch, getState);
            });

        addTask(fetchTask);
    },

    sendCheckRequestToPayables: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        let fetchTask = api.sendCheckRequestToPayables(selectedCheckRequest)
            .then(() => {

                dispatch({
                    type: 'UPDATE_CHECKREQUEST',
                    isLoading: false,
                    showValidationError: false,
                    isSavingCheckRequest: false,
                    isEditing: false,
                    showMessage: true,
                    message: 'Check Request has been submitted to Payables',
                    selectedCheckRequest: unloadedState.selectedCheckRequest,
                });

                (actionCreators.refresh())(dispatch, getState);
            });

        addTask(fetchTask);
    },

    approveCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // revalidate
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        if (!isValidCheckRequest(selectedCheckRequest, getState().checkRequest.selectedFiles)) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        }

        if (!getState().checkRequest.selectedFiles) {
            if (!selectedCheckRequest.pdfKey) {
                (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
                return;
            }
        }

        {/* Update the check request and send to controller. */ }
        (actionCreators.sendCheckRequestToController())(dispatch as any, getState);
        (actionCreators.handleCloseCheckRequestApprovalForm())(dispatch as any, getState);
    },

    approveCheckRequestController: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const state = getState();

        let selectedCheckRequest = state.checkRequest.selectedCheckRequest;
        let selectedFiles = state.checkRequest.selectedFiles;

        // controller should have all final fields filled in.
        if (!selectedCheckRequest.costCenter || !selectedCheckRequest.costElement || !selectedCheckRequest.vendorNumber
            || !isValidCheckRequest(selectedCheckRequest, selectedFiles)) {
            (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
            return;
        };

        // verify we have a pdf.
        if (!selectedFiles) {
            if (!selectedCheckRequest.pdfKey) {
                (actionCreators.dispatchCheckRequestErrors(selectedCheckRequest))(dispatch, getState);
                return;
            }
        }

        {/* Update the check request and send to payables. */ }
        (actionCreators.sendCheckRequestToPayables())(dispatch as any, getState);
        (actionCreators.handleCloseCheckRequestApprovalForm())(dispatch as any, getState);
    },

    denyCheckRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        {/* Deny the check request, send back to requestor. */ }

        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;

        dispatch({
            type: 'UPDATE_CHECKREQUEST',
            isLoading: false,
            showValidationError: false,
            isSavingCheckRequest: true,
            isEditing: true,
            showMessage: false,
            message: '',
            selectedCheckRequest: selectedCheckRequest,
        });
        let fetchTask = api.denyCheckRequest(selectedCheckRequest)
            .then(() => {

                dispatch({
                    type: 'UPDATE_CHECKREQUEST',
                    isLoading: false,
                    showValidationError: false,
                    isSavingCheckRequest: false,
                    isEditing: false,
                    showMessage: true,
                    selectedCheckRequest: unloadedState.selectedCheckRequest,
                    message: 'This check request has been denied. It will now go back to the original requestor.'
                });

                (actionCreators.handleCloseCheckRequestApprovalForm())(dispatch, getState);
            });

        addTask(fetchTask);
    },

    removeCheckRequestPdfAttachment: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        // revalidate
        let selectedCheckRequest = state.checkRequest.selectedCheckRequest;

        // in case we haven't saved a new check request yet, just remove the temp name and return.
        if (!selectedCheckRequest.checkRequestId) {

            dispatch({
                type: 'UPLOAD_PDF_ATTACHMENT_SUCCESS',
                pdfTempFileName: '',
                selectedCheckRequest: getState().checkRequest.selectedCheckRequest,
                selectedFiles: null
            });
            return;
        }
        deleteBlob(selectedCheckRequest.pdfKey).then(() => {
            // Update PDF metadata after successful deletion
            let meta: any = {
                checkRequestId: selectedCheckRequest.checkRequestId,
                pdfKey: null,
                pdfFileName: ''
            }

            api.updateCheckRequestPdf(meta).then(() => {
                selectedCheckRequest.pdfKey = '';
                selectedCheckRequest.pdfFileName = '';

                dispatch({
                    type: 'UPDATE_CHECKREQUEST',
                    isLoading: false,
                    showValidationError: false,
                    isSavingCheckRequest: false,
                    isEditing: getState().checkRequest.isEditing,
                    showMessage: true,
                    selectedCheckRequest: selectedCheckRequest,
                    message: 'PDF has been removed'
                });
                (actionCreators.refresh())(dispatch, getState);
            });
        });

        dispatch({
            type: 'REMOVE_PDF_ATTACHMENT',
            pdfTempFileName: '',
            isSavingCheckRequest: true
        });
    },

    uploadPdfAttachment: (selectedFiles: FileList, selectedCheckRequest: ICheckRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (selectedFiles && !selectedCheckRequest.checkRequestId) {

            dispatch({
                type: 'UPLOAD_PDF_ATTACHMENT_SUCCESS',
                pdfTempFileName: selectedFiles[0].name,
                selectedFiles: selectedFiles,
                selectedCheckRequest: selectedCheckRequest
            });

            return;
        }
        let currentUser = getSessionInfo();
        let albumName = `${currentUser.email}/${selectedCheckRequest.invoiceDisplay}`;

        const reader = new FileReader();
        reader.onload = () => {

            const file = selectedFiles[0];

            // if it's not a PDF, return.
            if (file.type !== 'application/pdf') {
                return;
            }
            let fileName = `${new Date().getTime().toString()}_${file.name}`
                .replace(/#/g, '')
                .replace(/&/g, '');   

            let key = `${albumName}/pdfs/${fileName}`;

            uploadBlob(key, file)
                .then(() => {
                    let meta: any = {
                        checkRequestId: selectedCheckRequest.checkRequestId,
                        pdfKey: `${albumName}/pdfs/${fileName}`,
                        pdfFileName: fileName
                    };
                    api.updateCheckRequestPdf(meta).then((result: any) => {

                        selectedCheckRequest.pdfKey = meta.pdfKey;

                        dispatch({
                            type: 'UPLOAD_PDF_ATTACHMENT_SUCCESS',
                            pdfTempFileName: '',
                            selectedCheckRequest: selectedCheckRequest,
                            selectedFiles: null
                        });

                        let inputEl: any = document.getElementById('pdfFileInput');
                        inputEl.value = null;

                        (actionCreators.handleCloseCheckRequestApprovalForm())(dispatch, getState);
                    });
                });
        };

        reader.readAsDataURL(selectedFiles[0]);

        dispatch({
            type: 'UPLOAD_PDF_ATTACHMENT'
        });
    },


    // VALIDATION --------------------------------------------------------------------------------------------------------------
    dispatchCheckRequestErrors: (selectedCheckRequest: ICheckRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: 'CREATE_CHECKREQUEST_ERROR',
            isLoading: false,
            showValidationError: true,
            isSavingCheckRequest: false,
            showMessage: true,
            message: 'Please make sure all fields are filled out and a PDF is attached!',
            selectedCheckRequest: selectedCheckRequest
        });
    },

    isCheckRequestValid: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        let selectedCheckRequest = state.checkRequest.selectedCheckRequest;
        return isValidCheckRequest(selectedCheckRequest, state.checkRequest.selectedFiles);
    },
    isCheckRequestDraft: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;
        return selectedCheckRequest.approvalStatusName === 'Draft';
    },
    isCheckRequestSubmitted: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;
        return selectedCheckRequest.approvalStatusName === 'Submitted';
    },
    isCheckRequestApproved: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let selectedCheckRequest = getState().checkRequest.selectedCheckRequest;
        return selectedCheckRequest.approvalStatusName === 'Approved';
    },
    refresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        (actionCreators.getCheckRequests())(dispatch, getState);
        (actionCreators.getCheckRequestsToApprove())(dispatch, getState);
    }
};

export const reducer: Reducer<ICheckRequestState> = (state: ICheckRequestState | undefined, action: KnownAction): ICheckRequestState => {

    switch (action.type) {
        case 'HANDLE_SHOW_CHECKREQUEST_DIALOG':
            return {
                ...state,
                mode: action.mode,
                status: action.status,
                selectedCheckRequest: action.selectedCheckRequest
            };
        case 'SET_EDITMODE':
            return { ...state, isEditing: action.isEditing };
        case 'CHECK_IF_CONTROLLER':
            return { ...state, isController: action.isController };
        case 'GET_CHECKREQUESTS':
            return { ...state, checkRequestsOwnedByUser: action.checkRequestsOwnedByUser, isLoading: action.isLoading };
        case 'GET_APPROVER_LIST':
            return { ...state, approverList: action.approverList, controllerList: action.controllerList };
        case 'GET_CHECKREQUEST_APPROVALS':
            return { ...state, checkRequestsToApprove: action.checkRequestsToApprove };
        case 'HANDLE_SHOW_CHECKREQUEST_APPROVALFORM':
            return {
                ...state,
                mode: action.mode,
                status: action.status,
                selectedCheckRequest: action.selectedCheckRequest
            };
        case 'HANDLE_CHECKREQUEST_CHANGE':
            return { ...state, selectedCheckRequest: action.selectedCheckRequest };
        case 'CREATE_CHECKREQUEST':
            return { ...state, isLoading: action.isLoading, isSavingCheckRequest: action.isSavingCheckRequest };
        case 'CREATE_CHECKREQUEST_ERROR':
            return {
                ...state,
                isLoading: action.isLoading,
                showValidationError: action.showValidationError || unloadedState.showValidationError,
                message: action.message || unloadedState.message,
                showMessage: action.showMessage || unloadedState.showMessage,
                isSavingCheckRequest: action.isSavingCheckRequest,
                selectedCheckRequest: action.selectedCheckRequest || state.selectedCheckRequest
            };
        case 'CREATE_CHECKREQUEST_SUCCESS':
            return {
                ...state,
                isLoading: action.isLoading,
                showValidationError: action.showValidationError,
                message: action.message,
                showMessage: action.showMessage,
                isSavingCheckRequest: action.isSavingCheckRequest,
                selectedCheckRequest: action.selectedCheckRequest
            };
        case 'UPDATE_CHECKREQUEST':
            return {
                ...state,
                isLoading: action.isLoading,
                showValidationError: action.showValidationError,
                isSavingCheckRequest: action.isSavingCheckRequest,
                message: action.message,
                showMessage: action.showMessage,
                isEditing: false,
                selectedCheckRequest: action.selectedCheckRequest || state.selectedCheckRequest
            };
        case 'UPLOAD_PDF_ATTACHMENT_SUCCESS': {
            return {
                ...state,
                pdfTempFileName: action.pdfTempFileName,
                selectedCheckRequest: action.selectedCheckRequest,
                selectedFiles: action.selectedFiles
            }
        }
        case 'REMOVE_PDF_ATTACHMENT': {
            return {
                ...state,
                pdfTempFileName: action.pdfTempFileName,
                isSavingCheckRequest: action.isSavingCheckRequest,
            }
        }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
}