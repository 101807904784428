import * as React from 'react';
import { default as NumberFormat } from 'react-number-format';
import {IReceiptMetadata} from '../../index';

interface IReceiptTotalProps {
    metadata: IReceiptMetadata;
}

const ReceiptTotal = (props: IReceiptTotalProps) => {
    return <span className='text-success heavy' style={{ 'marginLeft': '20px', 'color': 'green', 'fontWeight': 700 }}>
        <NumberFormat value={props.metadata.expensedTotal !== undefined ? props.metadata.expensedTotal.toFixed(2) : '0.00'} displayType={'text'} thousandSeparator={true} prefix={'$'} />
    </span>;
}

export default ReceiptTotal;